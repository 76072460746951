import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

export class PhoneValidator {
  static validator(): ValidatorFn {
    return (control: AbstractControl) => {
      const number = +control.value;

      if (!number) {
        return;
      }

      try {
        const phoneNumber = PhoneNumberUtil.getInstance().parse('+' + number.toString());

        const valid =  PhoneNumberUtil.getInstance().isValidNumber(phoneNumber);
        if (!valid) {
          return {invalidPhoneNumber: true};
        }
      } catch (e) {
        return {invalidPhoneNumber: true};
      }
    };
  }
}
