export interface AlertConfig {
  header?: string;
  message?: string;
  buttons?: AlertButton[];
}

export interface AlertButton {
  text: string;
  handler?: () => void;
  color?: string;
  role?: string;
  cssClass?: string;
}
