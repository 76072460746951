import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { EnvService } from './env.service';
import { BasicSystemInfo } from '@frontend/interfaces';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {
  private instanceData$: Observable<BasicSystemInfo>;

  constructor(
    private http: HttpService,
    private envService: EnvService
  ) {}

  instance(): Observable<BasicSystemInfo> {
    if (!this.instanceData$) {
      this.instanceData$ = this.http
        .get<BasicSystemInfo>(this.envService.getApiUrl('/public/system-info'))
        .pipe(shareReplay(1));
    }

    return this.instanceData$;
  }
}
