import { Injectable } from '@angular/core';
import { ToastControllerInterface } from "../interface/toast-controller.interface";
import { ToastConfig } from "../interface/toast-config";

@Injectable({
  providedIn: 'root'
})
export class ToastController {
  constructor(private toastController: ToastControllerInterface) {}

  create(toastConfig: ToastConfig) {
    return this.toastController.create(toastConfig);
  }
}
