import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from './alert/alert.component';
import {AlertButton, AlertConfig, AlertControllerInterface} from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class AlertController implements AlertControllerInterface{
  constructor(public dialog: MatDialog) {}

  async create(config: AlertConfig) {
    return {
      present: async () => {
        const dialogRef = this.dialog.open(AlertComponent, {
          width: '250px',
          data: config
        });

        dialogRef.afterClosed().subscribe((button: AlertButton) => {
          if (button && button.handler) {
            button.handler();
          }
        });
      }
    };
  }
}
