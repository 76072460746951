import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import {
  getInitialRedirectLink,
  GuardService,
  Link,
  LinkService,
  Mode,
  ModeService,
  UserService,
} from "@frontend/core";
import { LinkList } from "../../link/link-list/link-list";
import { UserMenu } from "../../link/menu/menu-config/user-menu";

@Component({
  selector: 'app-desktop-redirect',
  templateUrl: './desktop-redirect.component.html',
  styleUrls: ['./desktop-redirect.component.scss']
})
export class DesktopRedirectComponent implements OnInit {
  error = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private modeService: ModeService,
    private guardService: GuardService,
    private linkService: LinkService
  ) {}

  ngOnInit(): void {
    this.getDirection().then((link: Link) => {
      if (!!link) {
        this.router.navigate([this.linkService.getUrl(link)]).then();
      } else {
        this.error = true;
      }
    });
  }

  async getDirection(): Promise<Link | null> {
    if (!this.modeService.isLoggedIn) {
      await this.router.navigate([this.guardService.getLoginPageLink()]);
    }

    if (this.modeService.mode === Mode.participant) {
      return await this.userService.getTopMenuMode()
        ? LinkList.participant.topMenu
        : LinkList.participant.meetingList;
    } else if (this.modeService.mode === Mode.user) {
      return getInitialRedirectLink(UserMenu);
    }
  }
}
