<h1 mat-dialog-title>{{'messages.uploading' | translate}}</h1>
<mat-dialog-content>
  <div class="mdc-content-col-50">
    <mat-progress-spinner
      class="progress-spinner"
      mode="determinate"
      value="{{percentDone}}"
    >
    </mat-progress-spinner>
  </div>
  <div class="mdc-content-col-50">
    <div class="mdc-content-row-50">
      <span>{{percentDone}}% {{'messages.uploadingPercentageOf' | translate }} {{total}}</span>
    </div>
    <div class="mdc-content-row-50">
      <span>{{'messages.uploadingTime' | translate}}: {{eta}}</span>
    </div>
  </div>
</mat-dialog-content>
<div class="mat-dialog-actions">
  <a>
    <button mat-stroked-button class="icon mat-red" (click)="close()">
      <mat-icon>cancel</mat-icon>
      {{'messages.cancel' | translate}}
    </button>
  </a>
</div>
