<div>
  <div class="field-wrapper">
    <ng-select
      [items]="filter.data.choices"
      [multiple]="filter.data.multiple"
      (change)="catchChange(filter.name, $event)"
      (clear)="catchChange(filter.name, {})"
      placeholder="{{ 'messages.' + filter.name | translate }}"
      bindLabel="label"
      bindValue="selectedValue"
      appendTo="body"
      loadingText="{{ 'messages.selectLoading' | translate }}"
      notFoundText="{{ 'messages.selectNotFoundText' | translate }}"
      clearAllText="{{ 'messages.selectClearAll' | translate }}">
    </ng-select>
  </div>
</div>
