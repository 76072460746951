import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { EnvService, ToastController, UserService } from "@frontend/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-autologin',
  templateUrl: './autologin.component.html',
  styleUrls: ['./autologin.component.scss']
})
export class AutologinComponent implements OnInit {
  constructor(
    protected translate: TranslateService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastController: ToastController,
    private envService: EnvService,
  ) {}

  ngOnInit(): void {
    if (!this.envService.isServerSide()) {
      const token = this.activateRoute.snapshot.paramMap.get('token');
      this.userService.adminAutoLogin({token: token})
        .subscribe({
          next: resp => resp.redirect
            ? this.router.navigate([resp.redirect]).then()
            : this.router.navigate(['/']).then(),
          error: async (result) => {
            const toast = await this.toastController.create({message: result.error.message});
            await toast.present();
            await this.router.navigate(['/']);
          }
        });
    }
  }
}
