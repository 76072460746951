import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '@frontend/core';
import { WebsitesCachingListService } from '../service/websites-caching-list.service';
import { WebsitesCache } from '../interface/websites-cache';
import { InjectableInMenuList } from '../../../layout/module/menu/interface/injectable-in-menu-list';
import { PathService } from '../../../layout/service/path.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-menu-list-websites',
  templateUrl: './menu-list-websites.component.html',
  styleUrls: ['./menu-list-websites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuListWebsitesComponent implements OnInit, InjectableInMenuList, OnDestroy {
  websitesListCached: WebsitesCache[] = new Array<WebsitesCache>();
  private subscribeChangeWebsitesList: Subscription;
  private navigationEndSubscription: Subscription;
  private subscribeChangeCompany: Subscription;

  constructor(
    private websitesCachingListService: WebsitesCachingListService,
    private pathService: PathService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.navigationEndSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.checkActive();
      setTimeout(() => this.cd.markForCheck());
    });

    this.subscribeChangeWebsitesList = this.websitesCachingListService.changeWebsitesList.subscribe(() => this.refresh());

    this.subscribeChangeCompany = this.userService.changeCompanySubject.subscribe(() => this.websitesListCached.length = 0);

    this.refresh();
  }

  ngOnDestroy(): void {
    if (this.subscribeChangeWebsitesList) {
      this.subscribeChangeWebsitesList.unsubscribe();
    }

    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
    }

    if (this.subscribeChangeCompany) {
      this.subscribeChangeCompany.unsubscribe();
    }
  }

  checkActive(): void {
    this.websitesListCached.forEach(websitesCache => {
      const links = [];
      if (websitesCache.menuListItems) {
        websitesCache.menuListItems.forEach(value => {
          links.push(value.link);
          if (value.activatingPaths) {
            value.activatingPaths.forEach(value1 => {
              links.push(value1);
            });
          }
        });
      }
      websitesCache.active = this.pathService.isCurrentLink(links, {'websiteId': websitesCache.id});
      websitesCache.expanded = websitesCache.active;
    });
  }

  private refresh(): void {
    this.websitesListCached = this.websitesCachingListService.list;
    this.checkActive();
    this.cd.markForCheck();
  }
}
