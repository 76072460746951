import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillHelperService } from './service/quill-helper.service';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [],
  providers: [QuillHelperService],
  imports: [CommonModule, QuillModule]
})
export class QuillHelperModuleModule {}
