import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguagePipe } from '../pipe/language.pipe';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class TopMenuGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private languagePipe: LanguagePipe
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getData().then(data => {
      if (data.instance.topMenu) {
        return true;
      } else {
        return this.router.parseUrl(
          this.languagePipe.transform('/participant/meeting/list')
        );
      }
    })
  }
}
