import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '@frontend/services';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvService } from '@frontend/services';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-profiler-toolbar',
  templateUrl: './profiler-toolbar.component.html',
  styleUrls: ['./profiler-toolbar.component.scss']
})
export class ProfilerToolbarComponent implements OnInit {
  @ViewChild('htmlContainer', {static: true}) container;
  toolbar;

  constructor(
    private http: HttpService,
    private sanitizer: DomSanitizer,
    private envService: EnvService
  ) {}

  ngOnInit(): void {
    if (this.envService.get('production') === true) {
      return;
    }
    let header = new HttpHeaders();
    header = header.set('Accept', 'text/html');

    this.http
      .get(this.envService.getBaseUrl('_profiler-toolbar'), {
        responseType: 'text',
        headers: header
      })
      .subscribe(response => {
        this.toolbar = this.sanitizer.bypassSecurityTrustHtml(response);
        setTimeout(() => {
          this.reinsertScripts();
        }, 100);
      });
  }

  reinsertScripts(): void {
    const scripts = <HTMLScriptElement[]>(
      this.container.nativeElement.getElementsByTagName('script')
    );
    const scriptsInitialLength = scripts.length;
    for (let i = 0; i < scriptsInitialLength; i++) {
      const script = scripts[i];
      const scriptCopy = <HTMLScriptElement>document.createElement('script');
      scriptCopy.type = script.type ? script.type : 'text/javascript';
      if (script.innerHTML) {
        scriptCopy.innerHTML = script.innerHTML;
      } else if (script.src) {
        scriptCopy.src = script.src;
      }
      scriptCopy.async = false;
      script.parentNode.replaceChild(scriptCopy, script);
    }
  }
}
