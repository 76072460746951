<div *ngIf="isVisible" class="business-hours pb-2">
  <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

  <ng-container *ngFor="let singleDay of formSchema.children">
    <app-input-business-hours-single-day
      [formSchema]="singleDay"
      [globalForm]="globalForm"
    ></app-input-business-hours-single-day>
  </ng-container>
</div>
