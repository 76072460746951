<ng-container *ngIf="formSchema?.formGroup">
  <app-timer
    *ngIf="formSchema.time"
    [time]="formSchema.time"
    (timeEnd)="timeEnd()"
    class="time-box"
  ></app-timer>
  <form
    [formGroup]="formSchema.formGroup"
    [ngClass]="{'form-from-entity': formSchema.isFormFromEntity}"
    (ngSubmit)="submitSubject.next()"
  >
    <mat-list
      [ngClass]="{'is-language-select' : formSchema.availableLanguages?.formControl}"
      role="list"
    >
      <mat-list-item
        *ngIf="formSchema.availableLanguages?.formControl && formSchema.availableLanguages.choices.length > 1"
        class="languages-select"
        role="listitem"
      >
        <app-language-select [form]="formSchema"></app-language-select>
      </mat-list-item>
      <ng-container *ngIf="withContainers">
        <div *ngFor="let container of formSchema.children" class="d-flex w-100">
          <div
            *ngFor="let field of container.children"
            [ngClass]="[field.attr?.width ? 'col-' + round(field.attr.width * 12 / 100) + ' px-2' : '']"
          >
            <app-group
              [globalForm]="formSchema"
              [group]="field"
              [ngClass]="[field.attr?.class ?? '', field.attr?.hidden ? 'hide' : '']"
            ></app-group>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!withContainers">
        <app-group
          *ngFor="let field of formSchema.children"
          [globalForm]="formSchema"
          [group]="field"
          [ngClass]="[
              field.attr?.class ?? '',
              field.attr?.hidden ? 'hide' : '',
              field.attr?.width ? 'col-' + round(field.attr.width * 12 / 100) + ' px-2' : '',
            ]"
        ></app-group>
      </ng-container>
    </mat-list>
    <app-response-message [form]="formSchema.formGroup"></app-response-message>
  </form>
</ng-container>
