import { Component, Input, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AbstractInputDateComponent, MY_FORMATS } from '../abstract-input-date/abstract-input-date.component';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  host: {'class': 'form-input width-auto'},
})
export class InputDateComponent extends AbstractInputDateComponent implements OnInit {
  @Input() addClass = '';

  ngOnInit(): void {
    super.ngOnInit();

    this.initEndDateAutofill();
  }
}
