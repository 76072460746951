<mat-accordion displayMode="flat" class="menu" *ngFor="let website of websitesListCached">
  <mat-expansion-panel [(expanded)]="website.expanded" *ngIf="website.name !== null">
    <mat-expansion-panel-header>
      <mat-panel-description>
        <span [ngClass]="{'title': true, 'active': website.active}">{{ website.name | textEllipsis: {length: 50} }}</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-menu-list [menuListItems]="[{menuListItems: website.menuListItems}]"></app-menu-list>
  </mat-expansion-panel>
</mat-accordion>
