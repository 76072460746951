import { Injectable, NgZone } from '@angular/core';
import { LocalNotificationActionPerformed, Plugins } from '@capacitor/core';
import { LocalNotificationType } from './const/local-notification.type';
import { Router } from '@angular/router';
import { LanguagePipe, ModeService } from '@frontend/core';
import { ChatService } from '../../chat/service/chat.service';

const { LocalNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {
  constructor(
    private router: Router,
    private languagePipe: LanguagePipe,
    private chatService: ChatService,
    private modeService: ModeService,
    private zone: NgZone
  ) {
    if (this.modeService.isMobile) {
      this.initListener();
    }
  }

  async add({ title, body, date, extra }) {
    if (!this.modeService.isMobile) {
      return;
    }
    await LocalNotifications.schedule({
      notifications: [
        {
          title: title,
          body: body,
          id: Math.round(Math.random() * 100000),
          schedule: { at: date || new Date(Date.now() + 1000) },
          sound: null,
          attachments: null,
          actionTypeId: '',
          extra: extra
        }
      ]
    });
  }

  private initListener() {
    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (notificationAction: LocalNotificationActionPerformed) => {
        this.zone.run(() => {
          if (
            notificationAction.notification.extra.type ===
            LocalNotificationType.chatMessage
          ) {
            const chatId = notificationAction.notification.extra.chatId;
            this.chatService.init();
            setTimeout(() =>
              this.router.navigate([
                this.languagePipe.transform('/chat/' + chatId)
              ])
            );
          }
        });
      }
    );
  }
}
