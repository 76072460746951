import { Injectable } from '@angular/core';
import { AgendaDay, Session } from '..';

@Injectable({
  providedIn: 'root'
})
export class AgendaParseService {
  map(data: Session[], filterFavorite = false): AgendaDay[] {
    const hashMap = {};

    if (filterFavorite) {
      data = data.filter(this.checkIfSessionIsFavorite);
    }

    data.forEach(value => {
      if (!hashMap[value.day]) {
        hashMap[value.day] = <AgendaDay>{
          day: value.day,
          sessions: []
        };
      }
      const numberOfSessions = hashMap[value.day].sessions.length;
      if (numberOfSessions > 0) {
        const last: Session = hashMap[value.day].sessions[numberOfSessions - 1];
        if (last.fromHour === value.fromHour && last.toHour === value.toHour) {
          if (last.positionInParallelSessions === undefined) {
            last.positionInParallelSessions = 0;
          }
          last.isLastOfParallel = false;

          value.positionInParallelSessions =
            last.positionInParallelSessions + 1;
          value.isLastOfParallel = true;
        }
      }

      hashMap[value.day].sessions.push(value);
    });

    const result: AgendaDay[] = [];

    for (const d of Object.keys(hashMap)) {
      result.push(hashMap[d]);
    }

    return result;
  }

  private checkIfSessionIsFavorite(session: Session) {
    return session.favorite;
  }
}
