import { Speaker } from "./speaker";

export interface Session {
  id: number;
  meetingId: number;
  fromHour: string;
  toHour: string;
  day: string;
  name: string;
  speakers: Speaker[];
  location: string;
  description?: string;
  present?: boolean;
  numberOfPresent?: number;
  rating?: {
    avg?: number;
    selected?: number;
  };
  groups: string[];
  positionInParallelSessions?: number;
  isLastOfParallel?: boolean;
  favorite: boolean;
  alarm: number;
  sessionType?:string;
}
