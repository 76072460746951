<ng-container #body></ng-container>

<ng-container *ngIf="group.children?.length > 0 && !group.options?.isCustomBuilt">
  <ng-container *ngIf="withContainers">
    <div *ngFor="let container of group.children" class="d-flex w-100">
      <div
        *ngFor="let field of container.children"
        [ngClass]="[field.attr?.width ? 'col-' + round(field.attr.width * 12 / 100) + ' px-2' : '']"
      >
        <app-group
          [group]="field"
          [globalForm]="globalForm"
          [ngClass]="[field.attr?.class ?? '', field.attr?.hidden ? 'hide' : '']"
        ></app-group>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!withContainers">
    <div
      *ngFor="let group of group.children | controlFilter: false"
      [ngClass]="[
        group.attr?.width ? 'col-' + round(group.attr.width * 12 / 100) + ' px-2' : '',
        group.attr?.wrapperClass ?? '',
      ]"
    >
      <app-group
        [group]="group"
        [globalForm]="globalForm"
        [ngClass]="[group.attr?.class ?? '', group.attr?.hidden ? 'hide' : '']"
      ></app-group>
    </div>
  </ng-container>
</ng-container>
