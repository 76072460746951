<div
  class="loader-container"
  [ngClass]="{ stay: !refreshing && !pan, refreshing: refreshing }"
  #loaderSpace
>
  <div #loader>
    <ng-content $select=".loader"> </ng-content>
  </div>

  <div *ngIf="loader.children.length === 0" class="default-loader">
    <mat-spinner *ngIf="refreshing" [diameter]="loaderSize"></mat-spinner>
    <mat-progress-spinner
      *ngIf="!refreshing"
      [mode]="'determinate'"
      [diameter]="loaderSize"
      [value]="value"
    ></mat-progress-spinner>
  </div>
</div>

<div #content>
  <ng-content select=".refresh-content"> </ng-content>
</div>
