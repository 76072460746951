import { CustomOptions, FormSchema } from '@frontend/form';

export class DisplayWhen extends CustomOptions {
  protected init(): void {
    for (const fieldSymbol of Object.keys(this.payload)) {
      this.validatedField.attr.hidden = true;

      const controlField = this.findControlField(fieldSymbol);

      if (!controlField) {
        console.error(`Field '${fieldSymbol}' not found`);
        continue;
      }

      const controlFieldType = this.getFieldType(controlField);

      setTimeout(() => {
        this.update(controlField.formControl.value, controlFieldType, this.payload[fieldSymbol]);

        controlField.formControl.valueChanges.subscribe(values => {
          this.update(values, controlFieldType, this.payload[fieldSymbol]);
        });
      });
    }
  }

  private getFieldType(field: FormSchema): string {
    let controlFieldType = field.config?.type;

    if (controlFieldType === 'meetingServices') {
      controlFieldType = field.multiple ? 'checkboxes' : 'radio';
    }

    return controlFieldType;
  }

  private update(values: any, controlFieldType: string, payload: Array<any>): void {
    const initialHiddenState = this.validatedField.attr.hidden;

    if (['choice', 'dataList', 'meetingServices', 'radio', 'select', 'enum'].includes(controlFieldType)) {
      this.validatedField.attr.hidden = !payload.includes(+values) && !payload.includes(values);
    } else if (['checkbox', 'checkboxes'].includes(controlFieldType)) {
      if (values === null || typeof values === 'number' || typeof values === 'string') {
        values = [+values];
      }

      this.validatedField.attr.hidden = !values.filter(value => payload.includes(+value)).length;
    }

    if (
      this.validatedField.attr.hidden && !this.validatedField.formControl.disabled
      || this.globalSchema.isSubmissionPreview
    ) {
      this.validatedField.disabled = true;
      this.validatedField.formControl.disable({emitEvent: false});
    } else if (
      !this.validatedField.attr.hidden
      && this.validatedField.formControl.disabled
      && this.validatedField.config.type !== 'label'
    ) {
      this.validatedField.disabled = false;
      this.validatedField.formControl.enable({emitEvent: false});
    }

    if (this.validatedField.attr.hidden && !initialHiddenState) {
      if (this.validatedField.options?.allow_delete === true && !this.validatedField.attr.keepChildren) {
        this.validatedField.children = [];
      } else  {
        this.validatedField.formControl.reset();
      }
    }


    if (this.validatedField.attr.hidden !== initialHiddenState) {
      this.globalSchema.visibilityChanged$.emit();
    }
  }
}
