import { OnInit, Directive, ElementRef, HostBinding, Input } from "@angular/core";

@Directive({
  selector: 'img[appLazyLoad]'
})
export class LazyLoadDirective implements OnInit {
  @HostBinding('attr.src') srcAttr = null;
  @Input() src: string;

  constructor(private el: ElementRef) {}

  static canLazyLoad(): boolean {
    return window && 'IntersectionObserver' in window;
  }

  ngOnInit(): void {
    LazyLoadDirective.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
  }

  private lazyLoadImage(): void {
    const obs = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.loadImage();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  private loadImage(): void {
    this.srcAttr = this.src;
  }
}
