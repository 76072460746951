import { Component, Input } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'lib-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {
  @Input() text;
  @Input() showCloseButton;

  constructor(private _ref: MatSnackBarRef<ToastComponent>) {}

  close() {
    this._ref.dismiss();
  }
}
