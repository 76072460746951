import { Injectable } from "@angular/core";
import { ControlBuilder, FormSchema, FormSubmitService } from "@frontend/form";
import { SelectChoice } from "../interface/select-choice";
import { CdkDragDrop } from "@angular/cdk/drag-drop";

@Injectable({providedIn: 'root'})
export class FormDependentFieldListService {
  constructor(
    private controlBuilder: ControlBuilder,
    private formSubmitService: FormSubmitService,
  ) {}

  updateFieldVisibility(globalForm: FormSchema, currentForm: FormSchema, changedFormField: FormSchema): void {
    const updatedChoice: SelectChoice = {
      attr: {hidden: !changedFormField.map.get('isDependent').formControl.value},
      value: changedFormField.map.get('id').formControl.value,
    };

    this.getFieldPrototypeChoices(globalForm).forEach(
      (choice: SelectChoice) => {
        if (choice.value === updatedChoice.value) {
          choice.attr.hidden = updatedChoice.attr.hidden;
        }
      }
    );

    const forms: [FormSchema] = [globalForm];
    if (globalForm !== currentForm) {
      forms.push(currentForm);
    }

    forms.forEach(form => {
      this.iterateLists(form, dependentFormField => dependentFormField.choices.forEach(
        (choice: SelectChoice) => {
          if (choice.value === updatedChoice.value) {
            choice.attr.hidden = updatedChoice.attr.hidden;

            if (dependentFormField.formControl.value === updatedChoice.value) {
              dependentFormField.formControl.setValue(null);
            }
          }
        })
      );
    });
  }

  deleteFieldFromLists(globalForm: FormSchema, currentForm: FormSchema, deletedFormField: FormSchema): void {
    const deletedChoice: SelectChoice = {
      value: deletedFormField.map.get('id').formControl.value
    };
    const fieldPrototypeChoices = this.getFieldPrototypeChoices(globalForm);

    fieldPrototypeChoices.forEach(
      (choice: SelectChoice, index: number) => {
        if (choice.value === deletedChoice.value) {
          fieldPrototypeChoices.splice(index, 1);
        }
      }
    );

    const forms: [FormSchema] = [globalForm];
    if (globalForm !== currentForm) {
      forms.push(currentForm);
    }

    forms.forEach(form => {
      this.iterateLists(form, dependentFormField => dependentFormField.choices.forEach(
        (choice: SelectChoice, index: number) => {
          if (choice.value === deletedChoice.value) {
            dependentFormField.choices.splice(index, 1);

            if (dependentFormField.formControl.value === deletedChoice.value) {
              dependentFormField.formControl.setValue(null);
            }
          }
        }
      ));
    });
  }

  addFieldToLists(globalForm: FormSchema, currentForm: FormSchema, choice: SelectChoice): void {
    this.getFieldPrototypeChoices(globalForm).push(choice);

    const forms: [FormSchema] = [globalForm];
    if (globalForm !== currentForm) {
      forms.push(currentForm);
    }

    forms.forEach(form => {
      this.iterateLists(form, dependentFormField => dependentFormField.choices.push(choice));
    });
  }

  updateFieldNameInLists(globalForm: FormSchema, currentForm: FormSchema, fieldId: string, name: string): void {
    this.getFieldPrototypeChoices(globalForm).find((choice: SelectChoice) => (choice.value === fieldId)).label = name;

    const forms: [FormSchema] = [globalForm];
    if (globalForm !== currentForm) {
      forms.push(currentForm);
    }

    forms.forEach(form => {
      this.iterateLists(form, dependentFormField => {
        dependentFormField.choices.find((choice: SelectChoice) => choice.value === fieldId).label = name;
      });
    });
  }

  addDependentFieldToOption(newChild: FormSchema[], form: FormSchema, option: FormSchema): void {
    const formSchema = <FormSchema>{
      children: newChild
    };

    this.controlBuilder.create([formSchema], option.map.get('dependentFormFields'), form);

    this.formSubmitService.index(option.map.get('dependentFormFields'));
  }

  removeDependentFieldFromOption(option: FormSchema, field: FormSchema): void {
    this.controlBuilder.remove(option.map.get('dependentFormFields'), field);
  }

  dropAfterDragging(option: FormSchema, $event: CdkDragDrop<any, any>) {
    this.move(
      option.map.get('dependentFormFields').children,
      $event.previousIndex,
      $event.currentIndex
    );

    this.formSubmitService.index(option.map.get('dependentFormFields'));
  }

  private move(array, fromIndex, toIndex): void {
    if (fromIndex == toIndex) {
      return;
    }
    array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
  }

  private iterateLists(form: FormSchema, callback: (formSchema: FormSchema) => void): void {
    form.map.get('formFieldContainers').children.forEach(container => {
      container.map.get('formFields').children?.forEach(formField => {
        formField.map.get('formFieldOptions')?.children?.forEach(formFieldOption => {
          formFieldOption.map.get('dependentFormFields').children?.forEach(dependentFormField => {
            callback(dependentFormField.map.get('formField'));
          });
        });
      });
    });
  }

  private getFieldPrototypeChoices(form: FormSchema): Array<SelectChoice> {
    return form.formFieldPrototype
      .find(element => element.key === 'formFieldOptions').formPrototype
      .find(element => element.key === 'dependentFormFields').formPrototype
      .find(element => element.key === 'formField').choices;
  }
}
