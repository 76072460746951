import { DateTime } from './date-time';

export interface CompanyFile {
  actions: {
    delete?: string;
    download?: string;
    edit?: string;
  };
  addedBy?: string;
  category?: string;
  createdAt?: DateTime;
  creatorName?: string;
  downloadLink?: string;
  fileId?: number;
  fileName?: string;
  id: number;
  imagePreview?: {
    image?: string;
    mime?: string;
    thumbnail?: string;
  };
  meetings: [];
  newTabLink?: string;
  type?: string;
}
