import { Component, EventEmitter, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormSchema } from '../../interface/form-schema';
import { InputTypeList } from '../../service/input-type-list';
import { InputAbstract } from '../../interface/input-abstract';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  @Input() group: FormSchema;
  @Input() globalForm: FormSchema;
  @Input() submitEvent: EventEmitter<any>;

  component: InputAbstract;
  withContainers = false;

  @ViewChild('body', {read: ViewContainerRef, static: true}) private viewContainerRef: ViewContainerRef;

  constructor(
    protected inputTypeList: InputTypeList,
  ) {}

  ngOnInit(): void {
    this.load(this.group.type);
  }

  isValid(): boolean {
    return !this.component || this.component.isValid();
  }

  round(number: number): number {
    return Math.round(number);
  }

  private load(type: string): void {
    const componentDefinition = type !== undefined ? this.inputTypeList.findComponent(type) : null;
    if (!componentDefinition) {
      return;
    }

    this.withContainers = componentDefinition.hasContainers || false;

    if (componentDefinition.hasDynamicChildren && this.group.children) {
      for (const child of this.group.children) {
        child.isDynamic = true;
      }
    }

    if (componentDefinition.component) {
      this.component = this.insertComponent(componentDefinition.component);
    }
  }

  private insertComponent(component): InputAbstract {
    const componentRef = this.viewContainerRef.createComponent(component);
    const instance = <any>componentRef.instance;
    instance.formSchema = this.group;
    instance.globalForm = this.globalForm;
    instance.submitEvent = this.submitEvent;

    return instance;
  }
}
