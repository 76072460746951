<ng-container *ngIf="isVisible">
  <mat-form-field>
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <input
      matInput
      [ngClass]="addClass"
      [matDatepicker]="picker"
      [min]="startDate"
      [placeholder]="formSchema.label"
      [formControl]="$any(formSchema.formControl)"
    >
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
