<ng-container *ngIf="isVisible">
  <div class="d-flex">
    <ng-container *ngIf="isFromEntity">
      <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>
    </ng-container>

    <div class="d-flex">
      <mat-form-field class="date">
        <mat-label *ngIf="!isFromEntity">{{ formSchema.label }}</mat-label>

        <input
          matInput
          [matDatepicker]="picker"
          [min]="startDate"
          [placeholder]="formSchema.label"
          [formControl]="$any(formSchema.formControl)"
        >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="time">
        <input matInput [formControl]="time" [ngxMatTimepicker]="timePicker" [format]="24">
        <ngx-mat-timepicker-toggle matSuffix></ngx-mat-timepicker-toggle>
      </mat-form-field>

      <ngx-mat-timepicker #timePicker></ngx-mat-timepicker>
    </div>
  </div>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
