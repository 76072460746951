import { EventEmitter, Injectable } from '@angular/core';
import { Link } from '../interfaces/link';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserStorageService } from './user-storage.service';
import { LanguagePipe } from '../pipe/language.pipe';
import { filter } from 'rxjs/operators';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  params = {};
  url = new EventEmitter();
  private links: Map<string, Link> = new Map<string, Link>();
  private activatedRoute: ActivatedRoute;

  constructor(
    private router: Router,
    private userStorageService: UserStorageService,
    private languagePipe: LanguagePipe,
    private envService: EnvService,
  ) {}

  setActivatedRoute(activatedRoute: ActivatedRoute): void {
    this.activatedRoute = activatedRoute;
    this.setParamListener();
  }

  setParamListener(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.params = this.getAllParams(this.activatedRoute);
        this.url.emit(event.url);
      });
  }

  addLink(key: string, link: Link): void {
    const map = new Map<string, Link>();
    map.set(key, link);
    this.addLinks(map);
  }

  addLinks(links: Map<string, Link>): void {
    links.forEach((link: Link, key) => this.links.set(key, link));
  }

  get(key: string): Link {
    return this.links.get(key);
  }

  isCurrentLink(paths: Link[], overrideParams: any = {}): boolean {
    let result = false;
    const params = Object.assign({}, this.params, overrideParams);

    if (Array.isArray(paths)) {
      paths.forEach(
        (link: Link) => result = result || this.matchLink(link, params)
      );
    }

    return result;
  }

  checkUserHasMeetingTypeAccess(meetingTypes: number[]): boolean {
    let hasAccess = false;

    meetingTypes.forEach(
      meetingType => (hasAccess = hasAccess || this.userStorageService.hasMeetingType(meetingType))
    );

    return hasAccess;
  }

  checkUserHasPrivileges(actions: string[]): boolean {
    let hasAccess = false;
    actions.forEach(
      action => (hasAccess = hasAccess || this.userStorageService.hasAccess(action))
    );

    return hasAccess;
  }

  checkUserHasAllPrivileges(actions: string[]): boolean {
    let hasAccess = true;
    actions.forEach(
      action => (hasAccess = hasAccess && this.userStorageService.hasAccess(action))
    );

    return hasAccess;
  }

  getUrl(link: Link, params?: any): string|null {
    const allParams = {...this.params, ...params};

    if (link.actions && link.actions.length > 0) {
      let hasAccess = false;
      link.actions.forEach(action => {

        if (this.userStorageService.hasAccess(action)) {
          hasAccess = true;
        }

      });

      if (!hasAccess) {
        return null;
      }
    }

    return this.languagePipe.transform(this.bindParameter(link.path, allParams));
  }

  getApiUrl(link: Link, params?: any): string {
    return this.envService.getApiUrl(this.getUrl(link, params));
  }

  getEndpointUrl(link: Link, params?: any): string {
    return this.bindParameter(link.backendPath, {...this.params, ...params})
  }

  getEndpointApiUrl(link: Link, params?: any): string {
    return this.envService.getApiUrl(this.getEndpointUrl(link, params));
  }

  getFrontendUrl(forPath?: string): string {
    return this.envService.getFrontendUrl(forPath);
  }

  private bindParameter(path: string, params: any): string {
    let localPath = path;
    for (const key of Object.keys(params)) {
      localPath = localPath.replace(`:${key}`, params[key]);
    }

    return localPath;
  }

  private getAllParams(element: ActivatedRoute): any {
    let params = {};
    for (const child of element.children) {
      params = {...params, ...this.getAllParams(child)};
    }

    params = {...params, ...element.snapshot.params};

    return params;
  }

  private matchLink(link: Link, params): boolean {
    const url = this.router.url.split('?')[0];

    return url === this.languagePipe.transform(this.bindParameter(link.path, params))
      || (
        link.partialActivatingPath
        && this.router.url.includes(this.languagePipe.transform(this.bindParameter(link.partialActivatingPath, params)))
      );
  }
}
