<mat-list-item *ngIf="hasRights === true" [class]="isActive ? 'active' : null">
  <mat-icon
    matListItemIcon
    [fontSet]="menuListItem.link.fontSet ?? null"
    class="nav-leading-icon"
  >{{ menuListItem.link.icon }}</mat-icon>
  <a
    [routerLink]="linkService.getUrl(menuListItem.link, menuListItem.params)"
    matListItemTitle
    matBadgeSize="small"
    [matBadge]="menuListItem.badge"
    [matBadgeHidden]="!!menuListItem.badge"
    matBadgeOverlap="false"
  >{{ menuListItem.link.name | translate }}</a>
</mat-list-item>

<div class="sub-menu">
  <div *ngIf="menuListItem.component" #componentsIncluded></div>
</div>
