import { ControlBuilder } from '../control-builder';
import { FormBuilder } from '@angular/forms';
import { FormSchema } from '../../interface/form-schema';
import { AbstractBuilder } from '../../interface/abstract-builder';

export class RadioBuilder extends AbstractBuilder {
  static build(
    cb: ControlBuilder,
    formBuilder: FormBuilder,
    input: FormSchema,
    globalForm: FormSchema
  ) {}
}
