import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserStorageService } from './user-storage.service';
import { HttpService } from './http.service';
import { EnvService } from './env.service';
import * as moment from 'moment';
import { AvailableLanguagesService } from './available-languages.service';
import { firstValueFrom } from "rxjs";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  languageIconMap = {
    en: 'gb'
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected translate: TranslateService,
    protected storage: UserStorageService,
    protected httpService: HttpService,
    protected envService: EnvService,
    protected availableLanguageService: AvailableLanguagesService,
    protected userService: UserService,
  ) {
    const lang = this.storage.language;
    this.translate.setDefaultLang(lang);
    this.translate.addLangs(this.availableLanguageService.availableLangs);
  }

  async setLangFromPath(lang: string): Promise<void> {
    if (!this.availableLanguageService.availableLangs.includes(lang)) {
      this.changeLanguage(this.envService.get('defaultLanguage'));
    } else {
      if (this.storage.isLoggedIn && lang !== this.storage.user.language) {
        this.updateDb(lang);
      }
      await this.storage.setLanguage(lang);
      moment.locale(lang);

      await firstValueFrom(this.translate.use(lang));
    }
  }

  mapIcon(lang: string): string {
    return this.languageIconMap[lang] || lang;
  }

  changeLanguage(lang: string): void {
    const tree = this.router.createUrlTree([this.router.url]);
    tree.root.children.primary.segments[0].path = lang;
    window.location = <any>this.router.serializeUrl(tree);
  }

  updateDb(lang): void {
    this.httpService
      .post(this.envService.getApiUrl('/user/account/change-language'), {language: lang})
      .subscribe(async (token) => await this.userService.setUser(token, false));
  }
}
