import { Injectable, OnDestroy } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { MessageType } from '../message/message-type';
import { HttpService, UserStorageService } from '@frontend/core';
import {
  ConfirmReceiveChatMessage,
  CreateNewChatMessage,
  LoadChatMessages,
  Message,
  SendNewChatMessage
} from '../message/message';
import { ChatStorageService } from './chat-storage.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService implements OnDestroy {
  public reconnect: Subject<void> = new Subject<void>();
  private subject: WebSocketSubject<any>;
  private userChange$: Subscription;

  constructor(
    private userStorageService: UserStorageService,
    private chatStorageService: ChatStorageService,
    private httpService: HttpService
  ) {}

  readMessage(message: SendNewChatMessage) {
    this.chatStorageService.readMessage(message);
    this.subject.next({
      type: MessageType.confirmReceiveChatMessage,
      data: <ConfirmReceiveChatMessage>{
        id: message.id,
        chatId: message.chatId
      }
    });
  }

  createPrivateChat(userId: number) {
    this.subject.next({
      type: MessageType.createPrivateChat,
      data: { id: userId }
    });
  }

  createNewMessage(chatId: string, text: string) {
    this.subject.next({
      type: MessageType.createNewChatMessage,
      data: <CreateNewChatMessage>{
        chatId: chatId,
        text: text
      }
    });
  }

  public loadChats() {
    this.subject.next({
      type: MessageType.loadChats
    });
  }

  public loadChatMessages(id: string) {
    const limit = 20;
    const page = this.chatStorageService.getChat(id).currentLoadedPage++;
    this.subject.next({
      type: MessageType.loadChatMessages,
      data: <LoadChatMessages>{
        id: id,
        limit: limit,
        page: page
      }
    });
  }

  init() {
    if (environment.websocket) {
      this.start();
      if (this.userChange$) {
        this.userChange$.unsubscribe();
      }
      this.userChange$ = this.userStorageService.userChange.subscribe(() => this.start());
    }
  }

  joinMeetingChat(meetingId: number) {
    this.subject.next({
      type: MessageType.joinMeetingChat,
      data: { id: meetingId }
    });
  }

  joinSessionChat(sessionId: number) {
    this.subject.next({
      type: MessageType.joinSessionChat,
      data: { id: sessionId }
    });
  }

  ngOnDestroy(): void {
    if (this.userChange$) {
      this.userChange$.unsubscribe();
    }
    if (this.subject) {
      this.subject.unsubscribe();
    }
  }

  private start() {
    if (this.userStorageService.isLoggedIn) {
      if (this.subject) {
        this.subject.unsubscribe();
      }

      this.subject = webSocket({
        url: environment.websocket
      });

      this.chatStorageService.restart();
      this.subscribeSocket();

      this.register();
      this.loadChats();
      this.reconnect.next();
    } else if (this.subject) {
      this.subject.unsubscribe();
      this.chatStorageService.restart();
    }
  }

  private subscribeSocket() {
    this.subject
      .subscribe({
        next: msg => {
          this.receiveMessage(msg);
        },
        error: err => {
          this.tryReconnect();
        }
      });
  }

  private receiveMessage(message: Message) {
    switch (message.type) {
      case MessageType.sendNewChatMessage:
        const result = this.chatStorageService.addNewMessage(message.data);
        if (!result) {
          this.loadChats();
        }
        break;
      case MessageType.chats:
        this.chatStorageService.setChats(message.data);
        break;
      case MessageType.chatMessages:
        this.chatStorageService.addMessagesToChat(message.data);
        break;
    }
  }

  private register() {
    const headers = <HttpHeaders>this.httpService.prepareHeader({}).headers;
    const objHeaders = {};
    headers.keys().forEach(value => (objHeaders[value] = headers.get(value)));
    this.subject.next({
      type: MessageType.start,
      data: {
        token: this.userStorageService.user.token,
        deviceId: this.userStorageService.user.deviceId,
        headers: objHeaders
      }
    });
  }

  private tryReconnect() {
    setTimeout(() => this.start(), 5000);
  }
}
