<ng-container *ngIf="isFromEntity || forceDisplay">
  <label><span *ngIf="formSchema.label" [innerHTML]="formSchema.label | safeHtml"></span><button
    *ngIf="formSchema.attr.readMoreText"
    type="button"
    class="read-on-button"
    (click)="toggleReadMore()"
  >{{ readMoreIsOn ? formSchema.attr.readMoreOffText : formSchema.attr.readMoreOnText }}</button></label>
  <div
    *ngIf="formSchema.attr.readMoreText && readMoreIsOn"
    class="read-on-text"
    [innerHTML]="formSchema.attr.readMoreText | safeHtml"
  ></div>
</ng-container>
