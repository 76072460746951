import { Component, OnInit } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-input-date-time-text',
  templateUrl: './input-date-time-text.component.html',
  host: {'class': 'form-input'},
})
export class InputDateTimeTextComponent extends InputAbstract implements OnInit {
  dateFormat = 'YYYY-MM-DD HH:mm:ss';

  ngOnInit() {
    super.ngOnInit();

    if (!this.formSchema.formControl.value) {
      return;
    }

    const momentDate = moment.utc(this.formSchema.formControl.value.date);
    const formattedDate = momentDate.isValid()
      ? momentDate.local().format(this.formSchema.attr?.dateFormat || this.dateFormat)
      : null;

    this.formSchema.formControl.setValue(formattedDate);
  }
}
