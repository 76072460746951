import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-filter-chart',
  templateUrl: './select-filter-chart.component.html',
  host: {'class': 'form-input'},
})
export class SelectFilterChart {
  @Input() filter: any;
  @Output() selectedValuePasser = new EventEmitter<any>();
  selectedValue: any;

  catchChange(type, event): void {
    this.selectedValue = event;
    this.selectedValuePasser.emit({
      type: type,
      selectedValue: event
    });
  }
}
