<div *ngIf="isVisible">
  <ng-container *ngIf="formSchema.map.get('meetingHotels').children; else noRoomsAvailable">
    <app-input-select [formSchema]="hotels" [globalForm]="globalForm"></app-input-select>
    <app-input-select [formSchema]="rooms" [globalForm]="globalForm"></app-input-select>

    <div class="d-flex spaced-items">
      <div *ngFor="let night of nights.children">
        <div
          class="text-color-grey"
        >{{ night.map.get('date').formControl.value.date | dateFormat: dateFormatSettings.hotelNight }}</div>
        <div class="d-flex align-items-center">
          <app-input-checkbox
            [formSchema]="night.map.get('id')"
            [globalForm]="globalForm"
            class="width-auto"
          ></app-input-checkbox>
          <div>{{ night.map.get('price').formControl.value }} zł</div>
        </div>
      </div>
    </div>

    <app-error-messages
      [control]="formSchema.formControl"
      [errorMessage]="formSchema.attr?.errorMessage ?? null"
    ></app-error-messages>

    <app-input-textarea
      [formSchema]="formSchema.map.get('accommodationNote')"
      [globalForm]="globalForm"
    ></app-input-textarea>
  </ng-container>

  <ng-template #noRoomsAvailable>
    <div class="no-rooms-message">{{ 'messages.noRoomsAvailable' | translate }}</div>
  </ng-template>
</div>
