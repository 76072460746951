import { Component, HostListener, Input, NgZone, OnInit } from '@angular/core';
import { interval, Subscription } from "rxjs";
import { TimeoutModalComponent } from "./timeout-modal/timeout-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { ModalController, ToastController } from "@frontend/gui";
import { EnvService, UserService, UserStorageService } from "@frontend/services";
import { User, SessionTime } from "@frontend/interfaces";

@Component({selector: 'app-session-keeper', template: ''})
export class SessionKeeperComponent implements OnInit {
  @Input() toast = false;
  private lastUserAction = new Date();
  private tokenLoop: Subscription;
  private timerLoop: Subscription;
  private timeoutWarning = false;

  constructor(
    private userService: UserService,
    private userStorage: UserStorageService,
    private modalController: ModalController,
    private toastController: ToastController,
    private translateService: TranslateService,
    private envService: EnvService,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    if (!this.envService.isServerSide()) {
      this.init();
    }
  }

  @HostListener('document:keypress')
  @HostListener('window:click')
  public handleUserEvent() {
    this.lastUserAction = new Date();
    this.refreshTimer();
  }

  private init(): void {
    // try to refresh token after possible logout request/exception
    this.ngZone.runOutsideAngular(() => {
      this.tokenLoop = interval(1000).subscribe(() => this.refreshToken());
      this.timerLoop = interval(1000).subscribe(() => this.refreshTimer());
    });
  }

  private refreshToken(): void {
    if (this.userStorage.user && this.lastUserAction >= new Date(this.userStorage.user.iat * 1000)) {
      this.tokenLoop.unsubscribe();
      this.userService.refreshToken(this.lastUserAction).subscribe(() => {
        this.tokenLoop = interval(this.getRefreshTokenInterval(this.userStorage.user))
          .subscribe(() => this.refreshToken());
      });
    }
  }

  private refreshTimer(): void {
    if (this.userStorage.user) {
      const fakeStartSession = this.lastUserAction.getTime() / 1000;
      const fakeEndSession = fakeStartSession + this.userStorage.user.sessionLength;
      const fakeSessionLength = fakeEndSession - (new Date().getTime() / 1000);

      this.userService.sessionTimeEventEmitter.emit(
        this.formatTimer(fakeSessionLength)
      );

      if (fakeSessionLength <= 10 * 60) {
        if (!this.timeoutWarning) {
          this.timeoutWarning = true;
          this.openTimeoutMessage('messages.sessionExpireSoonTitle', 'messages.sessionExpireSoonMessage').then();
        }
      } else {
        this.timeoutWarning = false;
      }
    } else {
      this.userService.sessionTimeEventEmitter.emit(null);
    }
  }

  private formatTimer(seconds: number): SessionTime {
    const sessionTime = <SessionTime>{};
    const secondsIn = {y: 31536000, m: 2592000, d: 86400, hour: 3600, min: 60, sec: 1};

    Object.keys(secondsIn).forEach(key => {
      sessionTime[key] = Math.floor(seconds / secondsIn[key]);
      seconds -= sessionTime[key] * secondsIn[key];
    });

    sessionTime['asString'] = () => this.asString(sessionTime);

    return sessionTime;
  }

  private getRefreshTokenInterval(user?: User): number {
    return user
      ? ((user.sessionLength * 1000) / 3) - 1
      : 10000;
  }

  private async openTimeoutMessage(title: string, message: string): Promise<void> {
    if (this.toast) {
      this.toastController.create({message: this.translateService.instant(message)}).then(toast => toast.present());
    } else {
      const modal = await this.modalController.create({
        component: TimeoutModalComponent,
        componentProps: {title, message},
        sizeConfig: {fullOnMobile: false, sizeOnDesktop: 'verySmall'}
      });
      await modal.present();
    }
  }

  private asString(sessionTime: SessionTime): string {
    if (sessionTime) {
      const
        y = `${sessionTime.y}y `,
        m = `${sessionTime.m}m `,
        d = `${sessionTime.d}d `,
        time = '' +
        `${sessionTime.hour.toString().padStart(2, '0')}:` +
        `${sessionTime.min.toString().padStart(2, '0')}:` +
        `${sessionTime.sec.toString().padStart(2, '0')}`;

      if (sessionTime.y > 0) {
        return y + m + d + time;
      } else if (sessionTime.m > 0) {
        return m + d + time;
      } else if (sessionTime.d > 0) {
        return d + time;
      } else {
        return time;
      }

    } else {
      return '';
    }
  }
}
