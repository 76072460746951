import { Component } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  host: {'class': 'form-input'},
})
export class PaginatorComponent extends InputAbstract {
  back(): void {
    this.globalForm.currentPage--;
    this.globalForm.pageChanged$.emit();
  }

  next(): void {
    const invalidControls = [];

    this.globalForm.children.forEach(container => {
      container.children?.forEach(field => {
        if (field.page === this.globalForm.currentPage) {
          // Following check for null value is required to support refresh-on-change fields
          if (!field.formControl.value) {
            field.formControl.markAsTouched();
            field.formControl.updateValueAndValidity();

            if (!field.formControl.valid && !field.formControl.disabled) {
              invalidControls.push(field.formControl);
            }
          }
        }
      });
    });

    if (!invalidControls.length) {
      this.globalForm.currentPage++;
      this.globalForm.pageChanged$.emit();
    }
  }
}
