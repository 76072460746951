import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';
import { QuillEditorComponent } from 'ngx-quill';
import * as QuillNamespace from 'quill';
import { QuillHelperService } from '../../../quill-helper-module/service/quill-helper.service';

const Quill: any = QuillNamespace;

@Component({
  selector: 'app-input-html',
  templateUrl: './input-html.component.html',
  styleUrls: ['./input-html.component.scss'],
  host: {'class': 'form-input'},
})
export class InputHtmlComponent extends InputAbstract {
  @ViewChild(QuillEditorComponent) quill;
  @ViewChild('htmlButton') htmlButton: ElementRef;

  config;
  loaded = false;

  constructor(
    private quillHelperService: QuillHelperService,
    private renderer2: Renderer2,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.initQuill().then();
  }

  created() {
    setTimeout(() => {
      const native = (<any>this.quill).elementRef.nativeElement;
      const htmlButton = native.querySelector('.ql-html');
      this.renderer2.appendChild(htmlButton, this.htmlButton.nativeElement);
    });
  }

  changeHtml() {
    this.quillHelperService.switchHtmlToText(this.quill);
  }

  private async initQuill() {
    this.config = this.quillHelperService.getDefaultConfig();
    await this.quillHelperService.initQuill();
    this.loaded = true;
    this.cd.markForCheck();
  }
}
