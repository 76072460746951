import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { LanguagePipe } from '../pipe/language.pipe';

@Injectable()
export class RedirectService implements CanActivate {
  constructor(public router: Router, private languagePipe: LanguagePipe) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<UrlTree> {
    return this.router.parseUrl(this.languagePipe.transform(''));
  }
}
