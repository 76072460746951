<ng-container *ngIf="form.invalid && form.touched && form.errors">
  <span *ngFor="let k of Object.keys(form.errors)" class="form-error">
    <ng-container *ngIf="!isType(form.errors[k], 'string')">
      {{ 'INPUT_ERROR_' + k.toUpperCase() | translate }}
    </ng-container>
    <ng-container *ngIf="isType(form.errors[k], 'string')">
      {{ form.errors[k] | translate }}
    </ng-container>
  </span>
</ng-container>
