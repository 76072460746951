import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from "rxjs";

@Injectable()
export class AppConfigService {
  constructor(private http: HttpClient) {}

  loadAppConfig(environment: any): Promise<void> {
    return lastValueFrom(this.http.get('/assets/data/appConfig.json'))
      .then((data: any) => {
        for (const [key, value] of Object.entries(data)) {
          environment[key] = value;
        }
      })
      .catch(() => {
        //
      });
  }
}
