import {Injectable, Injector} from '@angular/core';
import {MenuMethod} from '@frontend/core';
import {serviceMap} from "../service-map";

@Injectable({
  providedIn: 'root'
})
export class MenuFunctionService {

  constructor(private injector: Injector) {
  }

  execute(method: MenuMethod) {
    if (method.serviceName && serviceMap.hasOwnProperty(method.serviceName)) {
      const service = this.injector.get<any>(serviceMap[method.serviceName]);
      service[method.methodName]();
    }
  }
}
