import {Component, Input} from '@angular/core';
import {MenuListSection} from '../interface/menu-list-item';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.css']
})
export class MenuListComponent {
  @Input() menuListItems: MenuListSection[];

  itemHasRightOutput(event: boolean, section: MenuListSection): void {
    if (event && !section.displayTitle) {
      setTimeout(() => section.displayTitle = true);
    }
  }
}
