import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DateFormatSettings, TranslationDomain } from '@frontend/core';
import { FormSchema } from '@frontend/form';
import { InputAbstract } from '../../../interface/input-abstract';
import { SelectChoice } from '../../../interface/select-choice';

@Component({
  selector: 'app-input-accommodation-choice',
  templateUrl: './input-participant-accommodation-choice.component.html',
  styleUrls: ['./input-participant-accommodation-choice.component.scss'],
  host: {'class': 'form-input'},
})
export class InputParticipantAccommodationChoiceComponent extends InputAbstract implements OnInit, OnDestroy {
  hotels: FormSchema;
  rooms: FormSchema;
  nights: FormSchema;

  dateFormatSettings = DateFormatSettings;
  subscriptions: Subscription[] = [];
  hotelNightsChangeSubscriptions: Subscription[] = [];
  page: number;

  constructor(
    private translator: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.page = this.formSchema.page;

    this.subscriptions.push(
      this.formSchema.formControl.valueChanges.subscribe(() => this.cd.detectChanges())
    );

    if (this.formSchema.map.get('meetingHotels').children) {
      this.initFields();

      this.subscriptions.push(
        this.hotels.formControl.valueChanges.subscribe(value => this.handleHotelChange(value))
      );

      this.subscriptions.push(
        this.rooms.formControl.valueChanges.subscribe(value => this.handleRoomChange(value, false))
      );
    }

    // @ts-ignore
    this.globalForm.formGroup.controls[this.formSchema.containerId].controls[this.formSchema.key]
      .removeControl('meetingHotels');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.hotelNightsChangeSubscriptions.forEach(s => s.unsubscribe());
  }

  public isValid(): boolean {
    if (this.formSchema.attr?.hidden || !this.hotels) {
      return true;
    }

    if (!this.hotels.formControl.valid) {
      this.hotels.formControl.setErrors({required: true});
      this.hotels.formControl.markAsTouched();
      this.cd.detectChanges();

      return false;
    }

    if (!this.rooms.formControl.valid) {
      this.rooms.formControl.setErrors({required: true});
      this.rooms.formControl.markAsTouched();
      this.cd.detectChanges();

      return false;
    }

    const valid = this.nights.formControl?.value.some(value => !!value);

    if (!valid) {
      this.formSchema.formControl.setErrors({atLeastOneNightRequired: true});
      this.formSchema.formControl.markAsTouched();
      this.cd.detectChanges();
    }

    return valid;
  }

  private initFields(): void {
    this.hotels = {
      attr: {},
      choices: this.transformChildrenToChoices(this.formSchema.map.get('meetingHotels').children),
      formControl: new FormControl(null, Validators.required),
      label: this.translator.instant(TranslationDomain + 'chooseHotel'),
      page: this.page
    };

    this.rooms = {
      additionalData: {hotelRooms: null},
      attr: {},
      choices: [],
      formControl: new FormControl({value: null, disabled: true}, Validators.required),
      label: this.translator.instant(TranslationDomain + 'chooseRoom'),
      page: this.page
    };

    this.nights = {
      children: [],
      formControl: null,
      page: this.page
    };

    if (this.globalForm.isSubmissionPreview) {
      const firstHotel = this.formSchema.map.get('meetingHotels').children[0];

      const hotelId = firstHotel.map.get('id').value;
      this.hotels.formControl.setValue(hotelId);
      this.hotels.formControl.disable();
      this.handleHotelChange(hotelId);

      const roomId = firstHotel.map.get('hotelRooms').children[0].map.get('id').value;
      this.rooms.formControl.setValue(roomId);
      this.rooms.formControl.disable();
      this.handleRoomChange(roomId, true);
    }
  }

  private handleHotelChange(value: number|null): void {
    this.rooms.formControl.reset();
    this.rooms.formControl.disable();
    this.rooms.choices = [];

    const hotelRooms = this.formSchema.map.get('meetingHotels').children
      .filter(element => element.map.get('id').value == value)
      .pop().map.get('hotelRooms').children;

    this.rooms.choices = this.transformChildrenToChoices(hotelRooms);
    this.rooms.additionalData.hotelRooms = hotelRooms;
    this.rooms.formControl.enable();
  }

  private handleRoomChange(value: number|null, selectCheckboxes: boolean): void {
    this.nights.children = [];
    this.nights.formControl = null;
    this.hotelNightsChangeSubscriptions.forEach(subscription => subscription.unsubscribe());

    // @ts-ignore
    this.globalForm.formGroup.controls[this.formSchema.containerId].controls[this.formSchema.key]
      .removeControl('hotelNights');

    if (value === null) {
      return;
    }

    this.nights.children = this.rooms.additionalData.hotelRooms
      .filter(element => element.map.get('id').value == value)
      .pop().map.get('hotelNights').children;

    this.nights.children.forEach((child: FormSchema) => child.map.get('id').page = this.page);

    this.nights.formControl = new FormArray(this.nights.children.map(formSchema => {
      formSchema.map.get('id').options.value = formSchema.map.get('id').value;

      if (!formSchema.map.get('selected').value && !selectCheckboxes) {
        formSchema.map.get('id').formControl.setValue(null);
      }

      return formSchema.map.get('id').formControl;
    }));

    this.nights.formControl.enable({emitEvent: false});

    //@ts-ignore
    this.globalForm.formGroup.controls[this.formSchema.containerId].controls[this.formSchema.key]
      .setControl('hotelNights', this.nights.formControl);

    this.hotelNightsChangeSubscriptions.push(
      this.nights.formControl.valueChanges.subscribe(() => this.isValid())
    );
  }

  private transformChildrenToChoices(children: Array<FormSchema>): Array<SelectChoice> {
    return children.map(child => ({
      label: child.map.get('name').value,
      value: child.map.get('id').value,
    }));
  }
}
