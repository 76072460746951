import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {FileUploadProgressControllerInterface}  from '@frontend/core';
import {FileUploadProgressComponent} from "./file-upload-progress/file-upload-progress.component";
import {Overlay} from "@angular/cdk/overlay";

@Injectable({
  providedIn: 'root'
})
export class FileUploadProgressController implements FileUploadProgressControllerInterface{
  fileUploadProgressController: MatDialogRef<any>[] = [];

  constructor(
    public dialog: MatDialog,
    public overlay: Overlay
  ) {}

  async create(data: any = {}) {
    let progress: MatDialogRef<FileUploadProgressComponent> = null;
    return {
      present: async () => {
        progress = this.dialog.open(FileUploadProgressComponent, {
          panelClass: 'modal-progress',
          scrollStrategy: this.overlay.scrollStrategies.noop()
        });
        this.fileUploadProgressController.push(progress);
      },
      dismiss: async () => {
        if (progress) {
          progress.close();
        }
      }
    };
  }

  async dismiss() {
    this.fileUploadProgressController = this.fileUploadProgressController.filter(value => {
      value.close();
      return false;
    });
  }
}
