import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserStorageService } from '@frontend/services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private userStorageService: UserStorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token: string;
    if (this.userStorageService.user && this.userStorageService.user.token) {
      token = 'Bearer ' + this.userStorageService.user.token;
    }

    let newHeaders = request.headers;
    if (token && !request.headers.has('Authorization')) {
      newHeaders = newHeaders.append('Authorization', token);
    }
    const authReq = request.clone({headers: newHeaders});

    return next.handle(authReq);
  }
}
