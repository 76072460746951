import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "@frontend/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit {
  error = false;

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    protected translate: TranslateService
  ) {}

  ngOnInit(): void {
    const token = this.activateRoute.snapshot.paramMap.get("token");
    this.userService.completeRegistration({token: token})
      .subscribe({
        next: resp => this.router.navigate([resp.redirect]).then(),
        error: () => this.error = true
      });
  }
}
