export interface Speaker {
  avatar: string;
  name: string;
  description: string;
  country: {
    id: number;
    name: string;
  };
  email: string;
  id: number;
  phone: string;
  showEmail: number;
  showPhone: number;
  firstName: string;
  lastName: string;
}
