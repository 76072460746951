<div *ngIf="isVisible && formSchema.formControl">
  <div
    *ngIf="formSchema.formControl.valid !== null"
    [ngClass]="{
      'ng-invalid': formSchema.formControl.invalid,
      'ng-touched': formSchema.formControl.touched,
      'ng-dirty': formSchema.formControl.dirty,
      'ng-valid': formSchema.formControl.valid,
      'field-wrapper': true
    }"
  >
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <iframe
      #iframe
      [id]="id"
      (load)="onIframeLoad(iframe)"
      width="100%"
      class="summernote-iframe"
    ></iframe>
  </div>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</div>
