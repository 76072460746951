import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AvailableLanguagesService {
  availableLangs = ['pl', 'en', 'de', 'it'];
  websiteLanguageFromAddress = null;

  init(languages: string[] = []) {
    if (languages.length > 0) {
      this.availableLangs = languages;
    }
  }

  setSelectedLanguage(defaultLanguage: string) {
    this.websiteLanguageFromAddress = defaultLanguage;
  }
}
