import { Component, Input } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  host: {'class': 'form-input'},
})
export class InputTimeComponent extends InputAbstract {
  @Input() placeholder = null;
  @Input() addClass = '';
  @Input() displayIcon: boolean;

  checkTime(time: string) {
    if (time === '24:00') {
      this.formSchema.formControl.setValue(null);
    }
  }
}
