import { LinkList } from '../../link-list/link-list';
import { MenuListItem } from '../../../module/menu/interface/menu-list-item';

export const CampaignMenu: MenuListItem[] = [
  {
    link: LinkList.meeting.dashboard,
  },
  {
    link: LinkList.meeting.participant.list,
    activatingPaths: [
      LinkList.meeting.participant.addProfile,
      LinkList.meeting.participant.personalData,
      LinkList.meeting.participant.accommodation,
      LinkList.meeting.participant.file.create,
      LinkList.meeting.participant.file.edit,
      LinkList.meeting.participant.file.list,
      LinkList.meeting.participant.serviceSummary,
      LinkList.meeting.participant.contract.list,
      LinkList.meeting.participant.meetingServices,
      LinkList.meeting.participant.formSubmissions,
      LinkList.meeting.participant.stageHistory,
      LinkList.meeting.participant.messageHistory,
    ]
  },
  {
    link: LinkList.meeting.edit,
  },
  {
    link: LinkList.meeting.form,
  },
  {
    link: LinkList.meeting.curriculum,
  },
  {
    link: LinkList.meeting.group,
  },
  {
    link: LinkList.meeting.message.list,
    activatingPaths: [
      LinkList.meeting.message.history,
      LinkList.meeting.message.create,
      LinkList.meeting.message.createForParticipant,
      LinkList.meeting.message.edit,
      LinkList.meeting.message.editForParticipant,
    ]
  }
];
