import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { UserStorageService } from '@frontend/core';
import { InputAbstract } from '../../../interface/input-abstract';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({template: ''})
export class AbstractInputDateComponent extends InputAbstract implements OnInit {
  startDate?: any;

  private mapLocale = {
    pl: 'pl-PL',
    de: 'de',
    en: 'en-gb',
    it: 'it',
  };

  constructor(
    private dateAdapter: DateAdapter<any>,
    private userStorageService: UserStorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.dateAdapter.setLocale(
      this.mapLocale[this.userStorageService.language]
    );

    this.dateAdapter.getFirstDayOfWeek = () => 1;
  }

  protected initEndDateAutofill(): void {
    this.formSchema.parent.children.forEach(child => {
      if (
        child.formControl !== this.formSchema.formControl
        && (child.description?.includes('start_date') || child.attr.startDate)
        && (
          this.formSchema.description?.includes('end_date') || this.formSchema.attr.endDate
          || this.formSchema.description?.includes('deadline_date') || this.formSchema.attr.deadlineDate
        )
      ) {
        if (this.formSchema.description?.includes('end_date') || this.formSchema.attr.endDate) {
          this.startDate = child.formControl.value;
        }

        child.formControl.valueChanges.subscribe(date => {
          this.formSchema.formControl.setValue(date);
          if (this.formSchema.description?.includes('end_date') || this.formSchema.attr.endDate) {
            this.startDate = date;
          }
        });
      }
    });
  }
}
