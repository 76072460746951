import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { PlatformModule } from '@angular/cdk/platform';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../apps/vinci/src/environments/environment';
import {
  AgendaParseService,
  AppConfigService,
  AvailableLanguagesService,
  ENV,
  ErrorHandlerService,
  EnvService,
  GuardService,
  HttpService,
  LanguageService,
  LinkService,
  ModeService,
  UserService,
  UserStorageService,
  RedirectService,
  ThemeService,
  InstanceService,
} from '@frontend/services';
import {
  AlertController,
  FileUploadProgressController,
  LoadingController,
  ModalController,
  ToastController
} from '@frontend/gui';
import { InitialsAvatarGeneratorComponent } from './components/initials-avatar-generator/initials-avatar-generator.component';
import { ProfilerToolbarComponent } from './components/profiler-toolbar/profiler-toolbar.component';
import { DateFormatPipe } from './pipe/date-format.pipe';
import { HasAccessPipe } from './pipe/has-access.pipe';
import { LanguagePipe } from './pipe/language.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { LazyLoadDirective } from './directive/lazy-load.directive';
import { Link } from './interfaces/link';
import { SessionKeeperComponent } from './components/session-keeper/session-keeper.component';
import { TimeoutModalComponent } from './components/session-keeper/timeout-modal/timeout-modal.component';

const initConfig = (appConfig: AppConfigService) => () => appConfig.loadAppConfig(environment);

export function init(config: UserStorageService) {
  return () => config.init();
}

export function initMode(config: ModeService) {
  return () => config.init();
}

export function initLanguage(availableLanguagesService: AvailableLanguagesService) {
  return () => availableLanguagesService.init();
}

let injector: Injector;
let userStorage: UserStorageService;

export function getInitialTabLink(linkListElement, tabLinks: string[]): string {
  return tabLinks.find(tabLink => {
    const actions = linkListElement[tabLink]?.list?.actions || [];

    return actions.some(action => userStorage.hasAccess(action));
  });
}

export function getInitialRedirectLink(UserMenu): Link {
  for (const userMenuSection of UserMenu) {
    for (const userMenu of userMenuSection.menuListItems) {
      const link = userMenu.link;
      if (link.actions?.filter(action => userStorage.hasAccess(action)).length > 0) {
        return link;
      }
    }
  }

  return { path: '/' }
}

@NgModule({
  declarations: [
    ProfilerToolbarComponent,
    LanguagePipe,
    InitialsAvatarGeneratorComponent,
    SafeHtmlPipe,
    HasAccessPipe,
    DateFormatPipe,
    SafeUrlPipe,
    LazyLoadDirective,
    SessionKeeperComponent,
    TimeoutModalComponent,
    LazyLoadDirective,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    PlatformModule,
    TranslateModule.forChild(),
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
  ],
  providers: [
    AlertController,
    ModalController,
    LoadingController,
    FileUploadProgressController,
    ToastController,
    AgendaParseService,
    HttpService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initLanguage,
      deps: [AvailableLanguagesService],
      multi: true
    },
    LanguageService,
  ],
  exports: [
    ProfilerToolbarComponent,
    LanguagePipe,
    InitialsAvatarGeneratorComponent,
    SafeHtmlPipe,
    HasAccessPipe,
    DateFormatPipe,
    SafeUrlPipe,
    LazyLoadDirective,
    SessionKeeperComponent,
  ]
})
export class CoreModule {
  constructor(
    private appConfigService: AppConfigService,
    private injectorService: Injector,
    private userStorageService: UserStorageService
  ) {
    injector = this.injectorService;
    userStorage = this.userStorageService;
  }

  static forRoot(environmentConfig: any): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: ENV, useValue: environmentConfig },
        EnvService,
        ErrorHandlerService,
        GuardService,
        RedirectService,
        UserService,
        AppConfigService,
        {
          provide: APP_INITIALIZER,
          useFactory: initConfig,
          multi: true,
          deps: [AppConfigService]
        },
        {
          provide: APP_INITIALIZER,
          useFactory: init,
          deps: [UserStorageService],
          multi: true
        },
        LanguagePipe,
        ThemeService,
        InstanceService,
        LinkService,
        {
          provide: APP_INITIALIZER,
          useFactory: initMode,
          deps: [ModeService],
          multi: true
        }
      ]
    };
  }
}
