<ng-container *ngIf="isVisible">
  <mat-form-field>
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <input matInput [formControl]="$any(formSchema.formControl)" [class.hide]="!formSchema.value">
  </mat-form-field>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
