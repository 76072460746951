import { FormBuilder } from '@angular/forms';
import { FormSchema } from './form-schema';
import { ControlBuilder } from '@frontend/form';

export abstract class AbstractBuilder {
  build(
    cb: ControlBuilder,
    formBuilder: FormBuilder,
    input: FormSchema,
    globalForm: FormSchema
  ) {}
}
