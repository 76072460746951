import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@frontend/services';

@Pipe({
  name: 'language',
  pure: false
})
export class LanguagePipe implements PipeTransform {
  value = '';

  constructor(
    private translate: TranslateService,
  ) {}

  transform(value: any, args?: any): any {
    const lang = this.translate.currentLang || this.translate.defaultLang;

    this.value = `/${lang}${value}`;
    this.translate.onLangChange.subscribe(() => this.value = `/${lang}${value}`);

    return this.value;
  }
}
