import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import { BlobDownloaderService, Link, LinkService, ToastController } from '@frontend/core';
import { FormSchema } from '../../interface/form-schema';
import { FormSubmitService } from '../../service/form-submit.service';
import { GroupComponent } from '../group/group.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent implements OnInit, OnDestroy {
  @Input() formSchema: FormSchema;
  @Input() showErrorToast: boolean;
  @Input() showFileUploadProgress: boolean;
  @Input() modalSubmissionResponse = false;
  @Input() withContainers = false;
  @Input() redirectLink: Link;
  @Input() goBack = false;
  @Output() inputChange: EventEmitter<FormSchema> = new EventEmitter<FormSchema>();
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() successEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorLoadEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() timeEndEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() progressUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() endLoad: EventEmitter<FormSchema> = new EventEmitter<FormSchema>();
  @ViewChildren(GroupComponent) fields: QueryList<GroupComponent>;

  subscriptions: Subscription[] = [];
  submitSubject: Subject<void> = new Subject<void>();

  private showLoading = true;
  private window: Window;

  constructor(
    protected formSubmitService: FormSubmitService,
    private cd: ChangeDetectorRef,
    private toastController: ToastController,
    private blobDownloaderService: BlobDownloaderService,
    private router: Router,
    private linkService: LinkService,
    private location: Location,
    @Inject(DOCUMENT) document: Document
  ) {
    this.window = document.defaultView;
  }

  ngOnInit(): void {
    this.load().then(() => {
      this.formSchema.currentPage = 1;
      setTimeout(() => this.cd.detectChanges());
    });

    this.cd.markForCheck();
    this.subscriptions.push(
      this.formSubmitService.changeDetectorRefresh.subscribe(() => this.cd.markForCheck())
    );

    this.formSchema.formGroup?.statusChanges.pipe(debounceTime(1000)).subscribe(() => {
      if (this.formSchema.formGroup.valid) {
        this.formSubmitService.formError.next(false);
      }
    });

    this.formSubmitService.checkFormValidity.subscribe(() => {
      if (this.formSchema.formGroup.valid) {
        this.formSubmitService.formError.next(false);
      }
    });

    this.submitSubject
      .pipe(debounceTime(500))
      .subscribe(() => this.submit().then());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadSchema(): Promise<void> {
    await this.formSubmitService.loadSchema(this.formSchema);
  }

  async submit(): Promise<void> {
    const displayErrorToast = this.showErrorToast !== null ? this.showErrorToast : true;

    const isValid = !this.fields.some(field => !field.isValid());
    if (!isValid) {
      return;
    }

    try {
      const result = await this.formSubmitService.sendData(
        this.formSchema,
        this.showLoading,
        displayErrorToast,
        this.showFileUploadProgress
      );

      if (this.formSchema.format?.asPdf) {
        this.blobDownloaderService.downloadBlobFromResp(result, 'pdf');
      } else if (this.formSchema.format?.asXlsx) {
        this.blobDownloaderService.downloadBlobFromResp(result, 'xlsx');
      }

      this.progressUpload.emit(result);

      if (result.message && this.modalSubmissionResponse === false) {
        this.toastController.create({message: result.message}).then(toast => toast.present());
      }

      this.successEvent.emit(result);

      if (this.redirectLink) {
        await this.router.navigateByUrl(this.linkService.getUrl(this.redirectLink));
      }

      if (this.goBack) {
        this.location.back();
      }
    } catch (e) {
      this.formSubmitService.formError.next(true);
      if (!e.toastAlreadyDisplayed) {
        if (e.message && displayErrorToast) {
          this.toastController.create({message: e.message}).then(toast => toast.present());
        } else {
          if (e.error?.message && displayErrorToast) {
            this.toastController.create({message: e.error.message}).then(toast => toast.present());
          }
        }
      }
      this.errorEvent.emit(e);
    }

    this.cd.markForCheck();
  }

  async timeEnd(): Promise<void> {
    if (this.timeEndEvent.observed) {
      await this.submit();
    } else {
      this.timeEndEvent.emit();
    }
  }

  round(number: number): number {
    return Math.round(number);
  }

  private async load(): Promise<void> {
    try {
      await this.loadSchema();

      this.cd.markForCheck();
      this.inputChange.emit(this.formSchema);
      this.subscriptions.push(this.formSchema.parentEventEmitter$.subscribe(() => this.cd.markForCheck()));
      this.endLoad.emit(this.formSchema);
    } catch (e) {
      console.log(e);
      this.errorLoadEvent.emit(e);
    }
  }
}
