export const summernoteHtml = `
<head>
  <meta charset="UTF-8">
  <title>Summernote with Bootstrap 4</title>
  <script src="https://code.jquery.com/jquery-3.5.1.min.js" crossorigin="anonymous"></script>
  <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>

  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>

  <link href="https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-bs4.min.css" rel="stylesheet">
  <script src="https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-bs4.min.js"></script>
</head>
<body>
<div id="summernote"></div>
<script>
(() => {
  window.addEventListener('message', (message) => {
    initSummernote(message.data.config);
    $('#summernote').summernote('code', message.data.code);

    if (message.data.tag) {
        insertTag(message.data.tag, message.data.position);
    }
  });

  function initSummernote(summernoteConfig) {
    $('#summernote').summernote({...summernoteConfig, ...{
      callbacks: {
        onChange: function () {
          window.parent.postMessage($('#summernote').summernote('code'),"*");
        },
        onChangeCodeview: function () {
          window.parent.postMessage($('#summernote').summernote('code'),"*");
        }
    }
    }});
  }

  function insertTag(tag, position = null) {
    let $summernote = $('#summernote');
    $summernote.summernote('editor.restoreRange');
    $summernote.summernote('editor.focus');
    const newCursorPosition = $summernote.summernote('editor.getLastRange');

    $summernote.summernote('editor.insertText', tag);

    if (position) {
      newCursorPosition.so += position;
      newCursorPosition.eo += position;
    } else {
      newCursorPosition.so += tag.length;
      newCursorPosition.eo += tag.length;
    }

    $summernote.summernote('editor.setLastRange', newCursorPosition);
    $summernote.summernote('editor.restoreRange');
  }
})();
</script>
</body>
</html>`
