import { Injectable } from '@angular/core';
import { ModalConfig } from '../interface/modal-config';
import { ModalSizeConfig } from '../interface/modal-size-config';
import { ModalControllerInterface } from '../interface/modal-controller.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalController {
  modalController: ModalControllerInterface;

  async create(config: ModalConfig): Promise<{ present: () => Promise<any> }> {
    return this.modalController.create(config);
  }

  changeModalSizeConfig(sizeConfig: ModalSizeConfig, dialogRef: any) {
    return this.modalController.changeModalSizeConfig(sizeConfig, dialogRef);
  }
}
