<div *ngIf="isVisible">
  <mat-form-field>
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <div style="display: flex;">
      <ng-select
        [(ngModel)]="country"
        [searchFn]="customSearchFn"
        [clearable]="false"
        (change)="setCountry()"
        [readonly]="formSchema.attr?.readonly || formSchema.disabled || false"
        [disabled]="formSchema.attr?.readonly || formSchema.disabled || false"
        bindLabel="name"
        bindValue="country"
      >
        <ng-option *ngFor="let item of countries || []" [value]="item">
          <span class="flag-icon flag-icon-{{ item.country.toLowerCase() }}"></span> {{item.name}} (+{{item.code}})
        </ng-option>
      </ng-select>

      <input
        [formControl]="$any(formSchema.formControl)"
        [value]="parsedNumber?.number?.toString()"
        [ngClass]="addClass"
        (input)="change($event.target.value)"
        placeholder="{{ formSchema.label }}"
        type="tel"
        id="phoneNumber"
        matInput
      >
    </div>
  </mat-form-field>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</div>
