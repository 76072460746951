import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserStorageService } from "@frontend/core";

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(
    public userStorage: UserStorageService,
    private route: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.userStorage.logout();
    await this.route.navigateByUrl('/');
  }
}
