import { Injectable } from '@angular/core';
import { UserStorageService, UserService, ModeService, LanguagePipe } from '@frontend/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ConfigService } from "../../../../libs/template-utils/src/lib/service/config.service"; // do not shorten!

@Injectable({
  providedIn: 'root'
})
export class LoginPopupGuard implements CanActivate {
  private readonly article = 'a';
  private readonly course = 'c';

  constructor(
    private configService: ConfigService,
    private userService: UserService,
    private user: UserStorageService,
    private router: Router,
    private modeService: ModeService,
    private languagePipe: LanguagePipe,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const prefix = next.url[0]?.path;

    if (![this.article, this.course].includes(prefix)) {
      return true;
    }

    const code = state.url.split(',').pop();

    if (this.isArticleProtected(prefix, code) || this.isCourseProtected(prefix, code)) {
      if (this.router.getCurrentNavigation().id === 1) {
        const url = this.languagePipe.transform('/' + this.configService.config.accessDeniedPath);

        return this.router.parseUrl(url);
      } else {
        this.user.loginRequired.next();

        return false;
      }
    } else {
      return true;
    }
  }

  private isArticleProtected(prefix: string, code: string): boolean {
    return prefix === this.article
      && !this.user.isLoggedIn
      && this.configService.config.guard.loggedIn.article.includes(code);
  }

  private isCourseProtected(prefix: string, code: string): boolean {
    return prefix === this.course
      && !this.user.isLoggedIn;
  }
}
