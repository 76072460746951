import { AbstractControl } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";

export function bindError(form: AbstractControl, errors, translateService: TranslateService,init = true) {
  if (!form || (init && errors.global === undefined)) {
    return;
  }

  if (init && errors.global.length > 0) {
    const errorMessages = {};
    errors.global.forEach(
      function (val, index) {
        errorMessages[index] = val;
      }
    );
    form.setErrors(errorMessages)
  }

  for (const key in errors) {
    if (errors[key] instanceof Object) {
      bindError(<AbstractControl>form.get(key), errors[key], translateService, false);
    } else if (key !== 'global') {
      const error = {};
      error[key] = translateService.instant(errors[key]);
      form.setErrors(error);
      const val = form.value;
      const validator = control => {
        if (val == control.value) {
          console.log('change add error');
          control.setErrors(error);
        }
      };
    }
  }
}
