<mat-sidenav-container>
  <mat-sidenav-content>
    <mat-toolbar role="heading" class="top-menu">
      <mat-toolbar-row>
        <img *ngIf="logo" class="logo" [src]="logo" alt="" />
        <span class="example-spacer"></span>
      </mat-toolbar-row>
    </mat-toolbar>

    <div class="content">
      <div class="absolute-center">
        <h2 class="text-color-red">
          {{ 'messages.resourceNotFound' | translate }}
        </h2>
        <button mat-stroked-button class="border mat-positive" (click)="navigate()">
          {{ 'messages.returnToMenu' | translate }}
        </button>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
