import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';
import { summernoteHtml } from "./summernote";
import { Observable, Subscription } from "rxjs";
import { SummernoteConfigInterface } from "./interface/summernote-config.interface";

@Component({
  selector: 'app-input-html-summernote',
  templateUrl: './input-html-summernote.component.html',
  styleUrls: ['./input-html-summernote.component.scss'],
  host: {'class': 'form-input'},
})
export class InputHtmlSummernoteComponent extends InputAbstract implements OnInit, AfterViewInit, OnDestroy {
  summernoteHtml = summernoteHtml;
  @Input() id: string;
  @Input() height = 400;
  @ViewChild('summer') summer: ElementRef;
  @Input() isCustomButton = false;
  @Input() insertTag: Observable<{ tag: string, position: number }>
  iframe: HTMLIFrameElement;
  subscriptions: Array<Subscription> = [];
  iframeContent: string;
  config: SummernoteConfigInterface;

  ngOnInit(): void {
    super.ngOnInit();

    this.height = this.formSchema.attr?.height || this.height;

    if (!this.id) {
      this.id = 'contentWindow' + Math.round(Math.random() * 10000);
    }

    this.config = {
      lang: 'pl-PL',
      placeholder: '',
      tabsize: 2,
      height: this.height,
      uploadImagePath: '',
      dialogsInBody: true,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'video']],
        ['misc', ['codeview']],
      ],
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    };

    this.subscriptions.push(this.formSchema.formControl.valueChanges.subscribe(
      value => this.updateIframeContent(this.iframe, value))
    );
  }

  ngAfterViewInit(): void {
    if (this.insertTag) {
      this.subscriptions.push(
        this.insertTag.subscribe((data) => {
          this.iframe.contentWindow.postMessage({
            tag: data.tag,
            position: data.position
          }, '*');
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  @HostListener('window:message', ['$event']) onIframeChange(event: MessageEvent): void {
    // @ts-ignore
    if (event.source.frameElement?.id === this.id) {
      this.bindValueFromHTMLEditor(event);
    }
  }

  bindValueFromHTMLEditor(event: MessageEvent): void {
    const value = ['<p><br></p>', '<br>'].includes(event.data) ? '' : event.data;
    this.iframeContent = value;
    this.formSchema.formControl.setValue(value, {emitEvent: false});
  }

  onIframeLoad(iframe: HTMLIFrameElement): void {
    this.iframe = iframe;
    this.iframe.height = (this.height + 60).toString();
    iframe.contentDocument.open();
    iframe.contentDocument.write(this.summernoteHtml);
    const content = this.iframeContent || this.formSchema.formControl.value;

    setTimeout(() => this.updateIframeContent(iframe, content), 500);
  }

  private updateIframeContent(iframe: HTMLIFrameElement, content) {
    iframe.contentWindow.postMessage({
      code: content,
      config: this.config,
      isCustomButton: this.isCustomButton
    }, '*');
  }
}
