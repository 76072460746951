import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-messages',
  templateUrl: './error-messages.component.html',
  host: {'class': 'd-block'},
})
export class ErrorMessagesComponent {
  @Input() control: AbstractControl;
  @Input() errorMessage: string;
  Object = Object;

  translationMap = {
    email: 'invalidEmail',
    domain: 'invalidDomain',
    file: 'The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.',
  };

  constructor(
    private translatorService: TranslateService,
  ) {}

  isType(val: any, type: string): boolean {
    return typeof val === type;
  }

  getTranslationKey(errorName: string, params?: any): string {
    if (errorName.includes('_')) {
      const splitError = errorName.split('_');

      return this.translatorService.instant('validators.' + splitError[0], {value: splitError[1]});
    }

    if (errorName === 'required' && this.errorMessage) {
      const translated = this.translatorService.instant(`validators.${this.errorMessage}`);

      return translated === `validators.${this.errorMessage}` ? this.errorMessage : translated;
    }

    return this.translatorService.instant('validators.' + (this.translationMap[errorName] || errorName), params);
  }
}
