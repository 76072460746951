import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PeselValidator {
  static validator(): ValidatorFn {
    return (control: AbstractControl) => {
      const pesel = control.value;

      if (!pesel) {
        return;
      }

      if (pesel < 10000000000) {
        return {invalidPesel: true};
      }

      const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
      let weightedSum = 0;
      for (let index = 0; index < 10; index++) {
        weightedSum += weights[index] * pesel[index];
      }

      const result = 10 - weightedSum % 10;
      const checkDigit = result === 10 ? 0 : result;

      if (+pesel[10] !== checkDigit) {
        return {invalidPesel: true};
      }
    };
  }
}
