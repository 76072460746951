import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Link, LinkService, MenuMethod, UserStorageService } from '@frontend/core';
import { MenuFunctionService } from '../../../service/menu-function.service';
import { MenuListItem } from '../interface/menu-list-item';
import { PathService } from '../../../service/path.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-menu-item',
  templateUrl: './menu-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuListItemComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() firstElement = false;
  @Input() menuListItem: MenuListItem;
  @Output() hasRightEventEmitter = new EventEmitter<boolean>()
  @ViewChild('componentsIncluded', { read: ViewContainerRef }) viewContainerRef;

  isActive: boolean;
  hasRights: boolean;
  links: Link[];

  private subscriptions: Subscription[] = [];

  constructor(
    public linkService: LinkService,
    private router: Router,
    private pathService: PathService,
    private menuFunctionService: MenuFunctionService,
    private userStorageService: UserStorageService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => this.refresh())
    );

    this.subscriptions.push(
      this.userStorageService.userActionsChange.subscribe(() => this.refresh())
    );

    this.refresh();
  }

  ngAfterViewInit(): void {
    if (this.menuListItem.component) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createComponent(this.menuListItem.component).changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  refresh(): void {
    let links = [];

    if (this.menuListItem.activatingPaths) {
      links = Object.assign([], this.menuListItem.activatingPaths);
    }

    if (!links.includes(this.menuListItem.link)) {
      links.push(this.menuListItem.link);
    }

    this.isActive = this.pathService.isCurrentLink(links, (this.menuListItem.params ?? {}));
    this.hasRights = this.pathService.checkUserHasAccess(this.menuListItem);
    this.hasRightEventEmitter.emit(this.hasRights);
  }

  // potentially to be removed with MenuFunctionService
  clickMethod(method: MenuMethod): void {
    this.menuFunctionService.execute(method);
  }
}
