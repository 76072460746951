import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioVideoStatisticsService {
  private readonly WORD = 8;
  private readonly UPDATE_INTERVAL = 100;
  private media: HTMLAudioElement | HTMLVideoElement;
  private bin: Uint8Array;
  private loop: NodeJS.Timeout;
  private initialized = false;
  private currentTime: number;

  /* eslint-disable no-bitwise */

  public init(media: HTMLAudioElement | HTMLVideoElement): void {
    this.media = media;
    const length = Math.ceil(this.media.duration / this.WORD);
    this.bin = new Uint8Array(length);
    this.media.addEventListener('play', () => this.start());
    this.media.addEventListener('pause', () => this.stop());
    this.initialized = true;
  }

  public getPercent(): number {
    if (!this.initialized) return 0;
    this.update();

    return Math.floor((
      (this.count() - 1) / Math.ceil(this.media.duration)
    ) * 100);
  }

  public each(callback: (second: number, watched: boolean) => void): void {
    if (typeof callback !== "function" || !this.initialized) return;

    for (let i = 0; i <= Math.floor(this.media.duration); i++) {
      const div = Math.floor(i / this.WORD);
      const mod = i % this.WORD;
      callback(i, (this.bin[div] >> mod & 1) === 1);
    }
  }

  public getDuration(): number {
    return this.media.duration;
  }

  public getCurrentTime(): number {
    return this.initialized ? this.currentTime : null;
  }

  public toJson(): Uint8Array {
    return this.initialized ? this.bin : null;
  }

  public toString(): string {
    if (!this.initialized) return "0%";
    let tmp = "";
    this.each((second, watched) => tmp = tmp.concat(watched ? "∙" : "∘"));

    return this.getPercent().toString().concat("% ").concat(tmp);
  }

  private count(): number {
    return this.bin.reduce((total: number, n: number) => {
      let i = 0;
      do if (n & 1) ++i; while (n >>= 1);

      return total + i;
    }, 0);
  }

  private update(): void {
    const time = Math.ceil(this.media.currentTime);
    const div = Math.floor(time / this.WORD);
    const mod = time % this.WORD;
    this.bin[div] = 1 << mod | this.bin[div];
    this.currentTime = Math.floor(this.media.currentTime);
  }

  private start(): void {
    this.update();
    this.loop = setInterval(() => this.update(), this.UPDATE_INTERVAL);
  }

  private stop(): void {
    this.update();
    clearInterval(this.loop);
  }
}
