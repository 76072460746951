import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnvService, HttpService, LanguageService } from '@frontend/core';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {
  success: boolean = undefined;

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private envService: EnvService,
  ) {
    this.route.params.subscribe(async val => {
      await this.languageService.setLangFromPath(val.lang);
    });
  }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token');
    this.httpService
      .get(this.envService.getApiUrl('public/unsubscribe/' + token))
      .subscribe({
        next: () => this.success = true,
        error: () => this.success = false
      });
  }
}
