<ng-container *ngIf="isVisible">
  <ng-container *ngIf="!formSchema.expanded">
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <ng-container *ngIf="!formSchema.options?.empty_label || (formSchema.children?.length > 0); else emptyLabel">
      <ng-select
        [multiple]="formSchema.multiple"
        [readonly]="disabled"
        [placeholder]="isFromEntity ? '' : formSchema.label"
        bindLabel="label"
        bindValue="value"
        appendTo="body"
        loadingText="{{ 'messages.selectLoading' | translate }}"
        notFoundText="{{ 'messages.selectNotFoundText' | translate }}"
        clearAllText="{{ 'messages.selectClearAll' | translate }}"
        [formControl]="$any(formSchema.formControl)"
        (change)="valueChangedTrigger.emit($event)"
      >
        <ng-container *ngFor="let choice of formSchema.choices">
          <ng-option *ngIf="!choice.attr?.hidden" [value]="choice.value">
            <mat-icon *ngIf="choice.attr?.icon">{{ choice.attr.icon }}</mat-icon>
            {{ choice.label }}
          </ng-option>
        </ng-container>
      </ng-select>
    </ng-container>

    <ng-template #emptyLabel>
      <p class="empty-label">{{ formSchema.options.empty_label }}</p>
    </ng-template>

    <app-error-messages
      [control]="formSchema.formControl"
      [errorMessage]="formSchema.attr?.errorMessage ?? null"
    ></app-error-messages>
  </ng-container>

  <ng-container *ngIf="formSchema.expanded && formSchema.multiple">
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm" [forceDisplay]="true"></app-custom-label>

    <div [class.one-line-options]="formSchema.attr.displayOptionsInOneLine">
      <ng-container
        *ngIf="!formSchema.options?.empty_label || formSchema.children?.length > 0; else emptyLabel"
      >
        <app-group
          *ngFor="let group of formSchema.children | controlFilter: true"
          [group]="group"
          [globalForm]="globalForm"
          [ngClass]="[group.attr?.class ?? '', (group.attr?.hidden) ? 'hide' : '']"
        ></app-group>
      </ng-container>

      <ng-template #emptyLabel>
        <p class="empty-label">{{ formSchema.options.empty_label }}</p>
      </ng-template>

      <app-error-messages
        [control]="formSchema.formControl"
        [errorMessage]="formSchema.attr?.errorMessage ?? null"
      ></app-error-messages>
    </div>
  </ng-container>

  <ng-container *ngIf="formSchema.expanded && !formSchema.multiple">
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm" [forceDisplay]="true"></app-custom-label>

    <mat-radio-group
      [formControl]="$any(formSchema.formControl)"
      [class.one-line-options]="formSchema.attr.displayOptionsInOneLine"
    >
      <ng-container *ngIf="!formSchema.options?.empty_label || formSchema.children?.length > 0; else emptyLabel">
        <app-group
          *ngFor="let group of formSchema.children | controlFilter: true"
          [group]="group"
          [globalForm]="globalForm"
          [ngClass]="[
            group.attr?.class ?? '',
            (group.attr?.hidden) ? 'hide' : '',
            (group.attr?.disabled) ? 'disabled' : ''
          ]"
        ></app-group>

        <app-error-messages
          [control]="formSchema.formControl"
          [errorMessage]="formSchema.attr?.errorMessage ?? null"
        ></app-error-messages>
      </ng-container>

      <ng-template #emptyLabel>
        <p class="empty-label">{{ formSchema.options.empty_label }}</p>
      </ng-template>
    </mat-radio-group>
  </ng-container>
</ng-container>
