import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  _loading = false;

  set loading(value: boolean) {
    setTimeout(() => (this._loading = value));
  }

  get loading(): boolean {
    return this._loading;
  }
}
