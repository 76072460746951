import { AbstractControl, ValidatorFn } from '@angular/forms';

export class MinMaxValidator {
  static validator(payload: {min?: number, max?: number, minMessage?: string, maxMessage?: string}): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;

      if (value < +payload.min) {
        const minMsg = (payload.minMessage || 'min') + '_' + payload.min;

        return {[minMsg]: true};
      }

      if (value > +payload.max) {
        const maxMsg = (payload.maxMessage || 'max') + '_' + payload.max;

        return {[maxMsg]: true};
      }
    };
  }
}
