import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, ErrorHandlerService, ToastController, Error} from '@frontend/core';
import {debounceTime} from 'rxjs/operators';
import {BackService} from '../link/menu/service/back.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorPresenterService {
  constructor(
    private errorHandlerService: ErrorHandlerService,
    private alertController: AlertController,
    private toastController: ToastController,
    private translateService: TranslateService,
    private backService: BackService
  ) {}

  public init() {
    this.errorHandlerService.errorsBus
      .pipe(debounceTime(300))
      .subscribe((error: Error) => this.handle(error));
  }

  async showToast(text: string) {
    this.toastController.create({message: text}).then(toast => toast.present());
  }

  async alert(text: string) {
    const alert = await this.alertController.create({
      header: this.translateService.instant('ALERT'),
      message: text,
      buttons: [
        {
          text: 'messages.ok'
        }
      ]
    });

    await alert.present();
  }

  private handle(error: any) {
    if (error.popover) {
      let message;
      if (Array.isArray(error.message)) {
        message = this.translateService.instant(error.message[0], error.message[1]);
      } else {
        message = this.translateService.instant(error.message);
      }

      this.showToast(message).then();
    }
    if (
      error.name === ErrorHandlerService.ACCESS_DENIED ||
      error.name === ErrorHandlerService.NOT_FOUND_HTTP_EXCEPTION
    ) {
      this.backService.goBack();
    }
  }
}
