import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  DisplayWhen,
  DynamicDateRange,
  FileValidator,
  GlobalLanguageSelector,
  LoadChoicesWhen,
  MultiLanguage,
  NpwzDoctorValidator,
  PeselValidator,
  PhoneValidator,
  MinMaxValidator,
  PostcodeValidator,
} from '@frontend/form';
import { ValidatorDefinition } from '../interface/validator-definition';

@Injectable({ providedIn: 'root' })
export class InputValidatorList {
  private list = <ValidatorDefinition[]>[
    {
      type: 'Symfony\\Component\\Validator\\Constraints\\NotNull',
      validator: Validators.required
    },
    {
      type: 'Symfony\\Component\\Validator\\Constraints\\NotBlank',
      validator: Validators.required
    },
    {
      type: 'App\\Common\\Validator\\Constraints\\NotNull',
      validator: Validators.required
    },
    {
      type: 'Symfony\\Component\\Validator\\Constraints\\Length',
      validator: Validators.minLength
    },
    {
      type: 'Symfony\\Component\\Validator\\Constraints\\Email',
      validator: Validators.email
    },
    {
      type: 'App\\Common\\Validator\\Constraints\\Phone',
      validator: PhoneValidator.validator
    },
    {
      type: 'App\\Form\\FormValidator\\DisplayWhen',
      validator: DisplayWhen
    },
    {
      type: 'App\\Form\\FormValidator\\MultiLanguage',
      validator: MultiLanguage
    },
    {
      type: 'App\\Form\\FormValidator\\GlobalLanguageSelector',
      validator: GlobalLanguageSelector
    },
    {
      type: 'App\\Form\\FormValidator\\LoadChoicesWhen',
      validator: LoadChoicesWhen
    },
    {
      type: 'Symfony\\Component\\Validator\\Constraints\\File',
      validator: FileValidator.validator
    },
    {
      type: 'App\\Form\\FormValidator\\DynamicDateRange',
      validator: DynamicDateRange
    },
    {
      type: 'App\\Common\\Validator\\Constraints\\Pesel',
      validator: PeselValidator.validator
    },
    {
      type: 'App\\Common\\Validator\\Constraints\\NpwzDoctor',
      validator: NpwzDoctorValidator.validator
    },
    {
      type: 'App\\Common\\Validator\\Constraints\\Number',
      validator: MinMaxValidator.validator
    },
    {
      type: 'App\\Common\\Validator\\Constraints\\Postcode',
      validator: PostcodeValidator.validator
    },
  ];

  public findValidator(type: string): any {
    const element = this.list.filter(e => type === e.type)[0];

    return element ? element.validator : null;
  }
}
