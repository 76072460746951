import { LinkList } from '../../link-list/link-list';
import { MenuListItem } from '../../../module/menu/interface/menu-list-item';

export const MeetingMenu: MenuListItem[] = [
  {
    link: LinkList.meeting.dashboard,
  },
  {
    link: LinkList.meeting.edit,
  },
  {
    link: LinkList.meeting.participant.list,
    activatingPaths: [
      LinkList.meeting.participant.addProfile,
      LinkList.meeting.participant.personalData,
      LinkList.meeting.participant.note.list,
      LinkList.meeting.participant.stageHistory,
      LinkList.meeting.participant.messageHistory,
      LinkList.meeting.participant.meetingServices,
      LinkList.meeting.participant.formSubmissions,
      LinkList.meeting.participant.file.create,
      LinkList.meeting.participant.file.edit,
      LinkList.meeting.participant.file.list,
      LinkList.meeting.participant.accommodation,
      LinkList.meeting.participant.serviceSummary,
      LinkList.meeting.participant.contract.list,
    ]
  },
  {
    link: LinkList.meeting.agenda,
  },
  {
    link: LinkList.meeting.form,
  },
  {
    link: LinkList.meeting.curriculum,
  },
  {
    link: LinkList.meeting.group,
  },
  {
    link: LinkList.meeting.location.list,
    activatingPaths: [
      LinkList.meeting.location.create,
      LinkList.meeting.location.edit,
    ]
  },
  {
    link: LinkList.meeting.message.list,
    activatingPaths: [
      LinkList.meeting.message.history,
      LinkList.meeting.message.create,
      LinkList.meeting.message.createForParticipant,
      LinkList.meeting.message.edit,
      LinkList.meeting.message.editForParticipant,
    ]
  },
  {
    link: LinkList.meeting.service.option.list,
    activatingPaths: [
      LinkList.meeting.service.category.config,
      LinkList.meeting.service.option.create,
      LinkList.meeting.service.option.edit,
      LinkList.meeting.service.form.config,
    ]
  },
  {
    link: LinkList.meeting.hotel.list,
    activatingPaths: [
      LinkList.meeting.hotel.edit
    ]
  }
];
