import { Pipe, PipeTransform } from '@angular/core';
import { UserStorageService } from '@frontend/services';

@Pipe({
  name: 'hasAccess'
})
export class HasAccessPipe implements PipeTransform {
  constructor(private userStorage: UserStorageService) {}

  transform(value: any, ...args: any[]): any {
    return this.userStorage.hasAccess(value);
  }
}
