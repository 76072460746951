<ng-container *ngIf="isVisible">
  <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

  <ng-select
    [readonly]="formSchema.attr.readonly"
    [formControl]="$any(formSchema.formControl)"
    [items]="items"
    [loading]="loading"
    [typeahead]="input$"
    [minTermLength]="3"
    [multiple]="formSchema.multiple"
    [placeholder]="formSchema.label"
    appendTo="body"
    bindLabel="label"
    bindValue="value"
    loadingText="{{ 'messages.selectLoading' | translate }}"
    notFoundText="{{ 'messages.selectNotFoundText' | translate }}"
    clearAllText="{{ 'messages.selectClearAll' | translate }}"
  ></ng-select>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
