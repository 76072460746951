import { Pipe, PipeTransform } from '@angular/core';
import { FormSchema } from '@frontend/form';

@Pipe({
  name: 'controlFilter'
})
export class ControlFilterPipe implements PipeTransform {
  transform(value: FormSchema[], args?: boolean): FormSchema[] {
    const onlyDynamic = args;

    return value
      ? value.filter(elem => (elem.isDynamic ?? false) === onlyDynamic)
      : [];
  }
}
