<ng-container *ngIf="isVisible">
  <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

  <ng-select
    #ngSelect
    [items]="[]"
    [addTag]="add"
    [multiple]="true"
    [isOpen]="false"
    [placeholder]="formSchema.label"
    appendTo="body"
    loadingText="{{ 'messages.selectLoading' | translate }}"
    notFoundText="{{ 'messages.selectNotFoundText' | translate }}"
    clearAllText="{{ 'messages.selectClearAll' | translate }}"
    [(ngModel)]="list"
    (change)="onChange()"
    (add)="onAdd($event)"
    (focusout)="onFocusOut($event)"
  >
    <ng-template ng-multi-label-tmp let-items="items">
      <ng-container *ngFor="let item of items">
        <div [ngClass]="{ red: !item.valid, 'ng-value': true }">
          <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
          <span class="ng-value-label">{{ item.value }}</span>
        </div>
      </ng-container>
    </ng-template>
  </ng-select>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
