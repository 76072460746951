import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Error} from '../interfaces/error';
import {UserStorageService} from './user-storage.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

//TODO jakiś refaktor tu potrzebny
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  public static BASE_EXCEPTION = 'Exception';
  public static UNSUPPORTED_VERSION = 'UnsupportedVersionException';
  public static ACCESS_DENIED = 'Symfony\\Component\\HttpKernel\\Exception\\AccessDeniedHttpException';
  public static CONNECTION_EXCEPTION = 'Doctrine\\DBAL\\Exception\\ConnectionException';
  public static JWT_NOT_FOUND = 'JWTNotFound';
  public static UNAUTHORIZED = 'UnauthorizedException';
  public static DISABLE_ACCOUNT = 'DisableAccountException';
  public static HTTP_ERROR_RESPONSE = 'HttpErrorResponse';
  public static NOT_FOUND_HTTP_EXCEPTION = 'Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException';
  public static INVALID_ACCESS_TOKEN_EXCEPTION = 'InvalidAccessTokenException';
  public static LANGUAGE_FOR_INSTANCE_NOT_FOUND_EXCEPTION = 'App\\Common\\Exception\\LanguageForInstanceNotFoundException';

  public errorsBus = new Subject<Error>();

  constructor(private userStorage: UserStorageService, private router: Router) { }

  public async handle(err: HttpErrorResponse): Promise<void> {
    if (!err || !err.error) {
      return;
    }

    const error = <Error>{
      name: '',
      message: '',
      popover: true,
      data: []
    };

    error.name = err.error.name;

    if (err.name === ErrorHandlerService.HTTP_ERROR_RESPONSE && err.status == 0) {
      error.message = 'messages.connectionProblem';

    } else if (err.status === 500) {
      error.message = 'messages.server500Error';

    } else if (err.status === 401) {
      error.message = 'messages.unauthorized';
      await this.logout();
    } else {
      switch (err.error.name) {
        case ErrorHandlerService.UNSUPPORTED_VERSION:
          error.message = [
            'messages.unsupportedVersion',
            {version: err.error.data.lastSupportedVersion}
          ];
          break;

        case ErrorHandlerService.ACCESS_DENIED:
          error.message = 'messages.accessDenied';
          break;

        case ErrorHandlerService.CONNECTION_EXCEPTION:
          error.message = 'messages.connectionError';
          break;

        case ErrorHandlerService.JWT_NOT_FOUND:
          error.message = 'messages.jwtNotFound';
          break;

        case ErrorHandlerService.UNAUTHORIZED:
          error.message = 'messages.unauthorized';
          break;

        case ErrorHandlerService.DISABLE_ACCOUNT:
          error.message = 'messages.accountDisabledFailedLogin';
          break;

        case ErrorHandlerService.INVALID_ACCESS_TOKEN_EXCEPTION:
          error.message = 'messages.unauthorized';
          await this.logout();
          break;

        case ErrorHandlerService.NOT_FOUND_HTTP_EXCEPTION:
          error.message = 'messages.notfound';
          break;

        case ErrorHandlerService.BASE_EXCEPTION:
          error.message = this.convertToFlat(error.data);
          error.popover = false;
          break;

        case ErrorHandlerService.LANGUAGE_FOR_INSTANCE_NOT_FOUND_EXCEPTION:
          error.message = 'messages.languageForInstanceNotFound';
          break;

        default:
          error.popover = false;
      }
    }

    this.errorsBus.next(error);
  }

  private async logout() {
    await this.userStorage.logout();
    await this.router.navigateByUrl('/');
  }

  //TODO
  private convertToFlat(errors): string {
    if (errors instanceof Object) {
      errors = Object.values(errors);
    }

    if (errors instanceof Array) {
      return errors.join(', ');
    }

    return '';
  }
}
