import { ControlBuilder } from '../control-builder';
import { FormBuilder } from '@angular/forms';
import { FormSchema } from '../../interface/form-schema';
import { AbstractBuilder } from '../../interface/abstract-builder';

export class CheckboxBuilder extends AbstractBuilder {
  static build(
    cb: ControlBuilder,
    formBuilder: FormBuilder,
    input: FormSchema,
    globalForm: FormSchema
  ) {
    if (input.value === null) {
      input.formControl = formBuilder.control('');
    } else {
      const int = +input.value;
      input.formControl = formBuilder.control(isNaN(int) ? input.value : int);
    }
  }
}
