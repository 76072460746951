import { Injectable } from '@angular/core';
import { Mode } from '../enum/mode';
import { UserStorageService } from './user-storage.service';
import { Platform } from '@angular/cdk/platform';
import { Actions } from '../const/actions';
import { EnvService } from "./env.service";

@Injectable({
  providedIn: 'root'
})
export class ModeService {
  private _mode: Mode;
  private readonly PARTICIPANT_URL = ["mobile", "participant"];

  constructor(
    private platform: Platform,
    private userStorageService: UserStorageService,
    private envService: EnvService
  ) {}

  get mode(): Mode {
    return this._mode;
  }

  async setMode(mode: Mode) {
    this._mode = mode;
    await this.userStorageService.setUserMode(mode);
  }

  async init() {
    this._mode = await this.userStorageService.getMode();

    if (this._mode === undefined || this._mode === null) {
      if (this.envService.get('startLoginMode')) {
        this._mode = this.envService.get('startLoginMode');
      } else {
        this._mode = this.isMobile ? Mode.participant : Mode.user;
      }
    }

    const modeFromUrl: Mode = this.PARTICIPANT_URL.includes(window?.location?.pathname.split("/")[2])
      ? Mode.participant
      : this._mode;

    if (this._mode !== modeFromUrl) {
      await this.setMode(modeFromUrl);
    }
  }

  get isMobile(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }

  get isLoggedIn(): boolean {
    return this.userStorageService.isLoggedIn;
  }

  canUseParticipantMode() {
    return (
      this.userStorageService.user === null || (
        this.userStorageService.hasAccess(Actions.CanListMeetingsInParticipantMode) &&
        this.userStorageService.hasAccess(Actions.CanLoginAsParticipant)
      )
    );
  }

  canUseUserMode() {
    return (
      this.userStorageService.user === null || (
        this.userStorageService.hasAccess(Actions.CanLoginAsUser) &&
        this.userStorageService.hasAccess(Actions.CanListMeetings) &&
        !this.userStorageService.user.substituteUser
      )
    );
  }
}
