import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlobDownloaderService {
  public downloadBlobFromResp(resp, type) {
    this.downloadFile(
      this.getFilename(resp.headers.get('Content-Disposition'), type),
      resp.body
    );
  }

  private downloadFile(fileName: string, result: Blob | MediaSource) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(result);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private getFilename(name: string, type: string) {
    let filename = 'null';
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

    const matches = regex.exec(name);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }

    return filename + '.' + type;
  }
}
