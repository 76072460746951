<div *ngIf="isVisible">
  <button
    *ngIf="globalForm.currentPage > 1"
    mat-flat-button
    class="mat-blue"
    style="margin-right: 10px;"
    [type]="'button'"
    (click)="back()"
  >{{ 'messages.back'| translate }}</button>
  <button
    *ngIf="globalForm.currentPage < globalForm.pageCount"
    mat-flat-button
    class="mat-blue"
    style="margin-right: 10px;"
    [type]="'button'"
    (click)="next()"
  >{{ 'messages.continue'| translate }}</button>
  <button
    *ngIf="globalForm.currentPage === globalForm.pageCount && !globalForm.isSubmissionPreview"
    mat-flat-button
    class="mat-blue"
  >{{ 'messages.send'| translate }}</button>
</div>
