import { Actions, Link } from '@frontend/core';
import { MeetingTypeId } from '../../../main-options/meeting/const/meeting-type-id';

function extractActions(obj: any): string[] {
  let actions: string[] = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (Array.isArray(value) && key === 'actions') {
        actions = actions.concat(value);
      } else if (typeof value === 'object') {
        actions = actions.concat(extractActions(value));
      }
    }
  }

  return Array.from(new Set(actions));
}

const LinkList = {
  mainOptions: <Link>{
    icon: null,
    name: 'messages.mainOptions',
    path: null
  },
  meetingContainer: <Link>{
    icon: null,
    name: 'messages.meeting',
    path: null
  },
  accountVerificationRequest: <Link>{
    icon: '',
    name: 'messages.accountVerificationRequest',
    path: '/verify'
  },
  login: <Link>{
    name: 'messages.login',
    path: '/login'
  },
  passwordReset: <Link>{
    path: '/password-reset/:token',
    backendPath: '/public/reset-password',
  },
  passwordResetRequest: <Link>{
    name: 'messages.passwordReset',
    path: '/password-reset',
    backendPath: '/public/reset-password-request',
  },
  dashboard: <Link>{
    symbol: 'dashboard',
    icon: 'apps',
    name: 'messages.dashboard',
    path: '/dashboard',
    actions: [Actions.CanViewSystemDashboard]
  },
  profileEdit: <Link>{
    path: '/participant/profile/edit'
  },
  meeting: {
    list: <Link>{
      backendPath: '/meeting/list/:meetingTypeId'
    },
    events: <Link>{
      icon: 'settings_voice',
      name: 'messages.events',
      path: '/meeting-list-events',
      meetingTypes: [MeetingTypeId.EVENT],
      partialActivatingPath: '/meeting/:meetingId/1',
      actions: [Actions.CanListMeetings],
    },
    eventList: <Link>{
      icon: 'settings_voice',
      name: 'messages.eventList',
      path: '/meeting-list-events/base',
      meetingTypes: [MeetingTypeId.EVENT],
      actions: [Actions.CanListMeetings],
    },
    eventCalendar: <Link>{
      icon: 'settings_voice',
      name: 'messages.eventCalendar',
      path: '/meeting-list-events/calendar',
      meetingTypes: [MeetingTypeId.EVENT],
      actions: [Actions.CanListMeetings],
    },
    trainings: <Link>{
      icon: 'square_foot',
      name: 'messages.trainings',
      path: '/meeting-list-trainings',
      meetingTypes: [MeetingTypeId.TRAINING],
      partialActivatingPath: '/meeting/:meetingId/2',
      actions: [Actions.CanListMeetings],
    },
    trainingList: <Link>{
      icon: 'square_foot',
      name: 'messages.trainingList',
      path: '/meeting-list-trainings/base',
      meetingTypes: [MeetingTypeId.TRAINING],
      actions: [Actions.CanListMeetings],
    },
    trainingCalendar: <Link>{
      icon: 'square_foot',
      name: 'messages.trainingCalendar',
      path: '/meeting-list-trainings/calendar',
      meetingTypes: [MeetingTypeId.TRAINING],
      actions: [Actions.CanListMeetings],
    },
    campaigns: <Link>{
      icon: 'how_to_vote',
      name: 'messages.campaigns',
      path: '/meeting-list-campaigns',
      meetingTypes: [MeetingTypeId.CAMPAIGN],
      partialActivatingPath: '/meeting/:meetingId/3',
      actions: [Actions.CanListMeetings],
    },
    campaignList: <Link>{
      icon: 'square_foot',
      name: 'messages.campaignList',
      path: '/meeting-list-campaigns/base',
      meetingTypes: [MeetingTypeId.CAMPAIGN],
      actions: [Actions.CanListMeetings],
    },
    campaignCalendar: <Link>{
      icon: 'square_foot',
      name: 'messages.campaignCalendar',
      path: '/meeting-list-campaigns/calendar',
      meetingTypes: [MeetingTypeId.CAMPAIGN],
      actions: [Actions.CanListMeetings],
    },
    eLearningCampaigns: {
      event: <Link>{
        icon: 'square_foot',
        name: 'messages.eLearningCampaigns',
        path: '/meeting-list-campaigns/e-learning-campaigns/event',
        meetingTypes: [MeetingTypeId.CAMPAIGN],
        actions: [Actions.CanListEditElearningCampaigns],
      },
      trigger: <Link>{
        icon: 'square_foot',
        name: 'messages.eLearningCampaignsTrigger',
        path: '/meeting-list-campaigns/e-learning-campaigns/trigger',
        meetingTypes: [MeetingTypeId.CAMPAIGN],
        actions: [Actions.CanListEditElearningCampaigns],
      },
    },
    create: <Link>{
      icon: '',
      name: 'messages.menuCreateEvent',
      path: '/meeting/:meetingTypeId/create',
      actions: [Actions.CanEditMeetings],
    },
    clone: <Link>{
      icon: '',
      name: 'messages.menuCloneEvent',
      path: '/meeting/:cloneId/clone',
      actions: [Actions.CanEditMeetings],
    },
    dashboard: <Link>{
      icon: 'calendar_view_day',
      name: 'messages.meetingDashboard',
      path: '/meeting/:meetingId/:meetingTypeId/dashboard',
      actions: [Actions.CanViewBasicMeetingDashboard, Actions.CanViewFullMeetingDashboard]
    },
    edit: <Link>{
      icon: 'edit',
      name: 'messages.edit',
      path: '/meeting/:meetingId/:meetingTypeId/edit',
      actions: [Actions.CanEditMeetings]
    },
    participant: {
      list: <Link>{
        icon: 'people',
        name: 'messages.menuParticipants',
        path: '/meeting/:meetingId/:meetingTypeId/participant/list',
        backendPath: '/meeting/:meetingId/participant/list',
        actions: [Actions.CanListParticipants],
      },
      addProfile: <Link>{
        icon: 'people',
        name: 'messages.addParticipant',
        path: '/meeting/:meetingId/:meetingTypeId/participant/add-profile',
        backendPath: '/meeting/:meetingId/profile/list',
        actions: [Actions.CanAddParticipants],
      },
      accommodation: <Link>{
        name: 'messages.hotelOptions',
        path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/accommodation',
        backendPath: '/meeting/participant/:participantId/accommodation',
        actions: [Actions.CanViewParticipantAccommodation],
      },
      contract: {
        list: <Link>{
          name: 'messages.contract',
          path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/contract',
          backendPath: '/meeting/participant/:participantId/contract/list',
          actions: [Actions.CanListParticipantContracts],
        },
        create: <Link>{
          name: 'messages.createContract',
          backendPath: '/meeting/participant/:participantId/personal-data-for-contract',
          actions: [Actions.CanCreateContracts],
        },
        delete: <Link>{
          name: 'messages.delete',
          backendPath: '/meeting/participant/:participantId/contract/:contractId',
          actions: [Actions.CanDeleteContracts],
        },
      },
      file: {
        list: <Link>{
          name: 'messages.participantFiles',
          path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/file/list',
          backendPath: '/meeting/participant/:participantId/files',
          actions: [Actions.CanListParticipantFiles],
        },
        create: <Link>{
          name: 'messages.addFile',
          path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/file/create',
          backendPath: '/meeting/participant/:participantId/file/create',
          actions: [Actions.CanCreateParticipantFiles],
        },
        edit: <Link>{
          name: 'messages.edit',
          path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/file/:fileId/edit',
          backendPath: '/meeting/participant/:participantId/file/:fileId/edit',
          actions: [Actions.CanEditParticipantFiles],
        },
        delete: <Link>{
          name: 'messages.delete',
          backendPath: '/meeting/participant/:participantId/file/:fileId/delete',
          actions: [Actions.CanDeleteParticipantFiles],
        },
      },
      formSubmissions: <Link>{
        name: 'messages.participantQuestionnaires',
        path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/form-submissions',
        backendPath: '/meeting/participant/:participantId/form-submissions',
        actions: [Actions.CanListParticipantFormSubmissions],
      },
      meetingServices: <Link>{
        name: 'messages.participantMeetingServices',
        path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/meeting-services',
        backendPath: '/meeting/participant/:participantId/meeting-services',
        actions: [Actions.CanViewParticipantMeetingServices],
      },
      messageHistory: <Link>{
        name: 'messages.messages',
        path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/messages',
        backendPath: '/meeting/participant/:participantId/messages',
        actions: [Actions.CanViewParticipantMessageHistory],
      },
      personalData: <Link>{
        name: 'messages.participantData',
        path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/personal-data',
        backendPath: '/meeting/participant/:participantId/personal-data',
        actions: [Actions.CanListParticipants],
      },
      stageHistory: <Link>{
        name: 'messages.participantStagesHistory',
        path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/stages',
        backendPath: '/meeting/participant/:participantId/stages',
        actions: [Actions.CanListParticipants],
      },
      serviceSummary: <Link>{
        name: 'messages.optionSummary',
        path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/service-summary',
        backendPath: '/meeting/participant/:participantId/service-summary',
        actions: [Actions.CanListParticipants],
      },
      note: {
        list: <Link>{
          name: 'messages.participantNotes',
          path: '/meeting/:meetingId/:meetingTypeId/participant/:participantId/preview/note/list',
          backendPath: '/meeting/participant/:participantId/note/list',
          actions: [Actions.CanListParticipantNotes],
        },
        create: <Link>{
          name: 'messages.send',
          backendPath: '/meeting/participant/:participantId/note/create',
          actions: [Actions.CanCreateParticipantNotes],
        },
        delete: <Link>{
          backendPath: '/meeting/participant/note/:noteId/delete',
          actions: [Actions.CanDeleteParticipantNotes],
        },
      },
      websiteAutoLoginUrl: <Link>{
        name: 'messages.generateWebsiteAutoLoginLink',
        backendPath: '/meeting/participant/:participantId/website-auto-login-url',
        actions: [Actions.CanLogInAsWebsiteUser],
      },
      import: <Link>{
        backendPath: '/meeting/:meetingId/participant/import',
        actions: [Actions.CanImportParticipants],
      },
      export: <Link>{
        backendPath: '/meeting/:meetingId/participant/export',
        actions: [Actions.CanExportParticipants],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/meeting/participant/:participantId/delete',
        actions: [Actions.CanDeleteParticipants],
      },
    },
    participantListMessage: {
      list: <Link>{
        backendPath: '/message/meeting/:meeting',
        actions: [Actions.CanEditParticipants],
      },
      create: <Link>{
        backendPath: '/participant-list-message/:participantId/create',
        actions: [Actions.CanEditParticipants],
      },
      preview: <Link>{
        backendPath: '/participant-list-message/:participantId/:messageId/preview',
        actions: [Actions.CanEditParticipants],
      },
      send: <Link>{
        name: 'messages.send',
        backendPath: '/participant-list-message/:participantId/:messageId/send',
        actions: [Actions.CanEditParticipants],
      },
      edit: <Link>{
        backendPath: '/participant-list-message/:participantId/:messageId/edit',
        actions: [Actions.CanEditParticipants],
      },
    },
    agenda: <Link>{
      icon: 'date_range',
      name: 'messages.meetingAgenda',
      path: '/meeting/:meetingId/:meetingTypeId/agenda',
      actions: [Actions.CanEditMeetings],
    },
    form: <Link>{
      icon: 'format_list_bulleted',
      name: 'messages.meetingFormList',
      path: '/meeting/:meetingId/:meetingTypeId/questionnaire',
      backendPath: '/meeting/:meetingId/questionnaire',
      actions: [Actions.CanEditMeetings],
    },
    formSubmissionStats: <Link>{
      backendPath: '/meeting/:meetingId/questionnaire/:formId/submission-stats',
    },
    curriculum: <Link>{
      icon: 'timeline',
      name: 'messages.curriculum',
      path: '/meeting/:meetingId/:meetingTypeId/curriculum',
      backendPath: '/meeting/:meetingId/curriculum',
      actions: [Actions.CanEditMeetings],
    },
    group: <Link>{
      icon: 'group_work',
      name: 'messages.groups',
      path: '/meeting/:meetingId/:meetingTypeId/group',
      backendPath: '/meeting/:meetingId/group-config',
      actions: [Actions.CanEditMeetings],
    },
    location: {
      list: <Link>{
        icon: 'location_on',
        name: 'messages.locations',
        path: '/meeting/:meetingId/:meetingTypeId/location/list',
        backendPath: '/meeting/:meetingId/location/list',
        actions: [Actions.CanEditMeetings],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/meeting/:meetingId/:meetingTypeId/location/create',
        backendPath: '/meeting/:meetingId/location/create',
        actions: [Actions.CanEditMeetings],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/meeting/:meetingId/:meetingTypeId/location/:locationId/edit',
        backendPath: '/meeting/:meetingId/location/:locationId/edit',
        actions: [Actions.CanEditMeetings],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/meeting/:meetingId/location/:locationId/delete',
        actions: [Actions.CanEditMeetings],
      },
    },
    message: {
      list: <Link>{
        icon: 'send',
        name: 'messages.messages',
        path: '/meeting/:meetingId/:meetingTypeId/message/list',
        backendPath: '/message/meeting/:meetingId/list',
        actions: [Actions.CanListEditSendMailings],
      },
      history: <Link>{
        name: 'messages.history',
        path: '/meeting/:meetingId/:meetingTypeId/message/history',
        backendPath: '/message/meeting/:meetingId/history',
        actions: [Actions.CanListEditSendMailings],
      },
      create: <Link>{
        name: 'messages.messages',
        path: '/meeting/:meetingId/:meetingTypeId/message/create',
        actions: [Actions.CanListEditSendMailings],
      },
      edit: <Link>{
        name: 'messages.messages',
        path: '/meeting/:meetingId/:meetingTypeId/message/edit/:messageId',
        actions: [Actions.CanListEditSendMailings],
      },
      createForParticipant: <Link>{
        name: 'messages.newMessage',
        path: '/meeting/:meetingId/:meetingTypeId/message/create/to-participant/:participantId',
        actions: [Actions.CanListEditSendMailings],
      },
      editForParticipant: <Link>{
        name: 'messages.edit',
        path: '/meeting/:meetingId/:meetingTypeId/message/edit/:messageId/to-participant/:participantId',
        actions: [Actions.CanListEditSendMailings],
      }
    },
    service: {
      option: {
        list: <Link>{
          icon: 'business_center',
          name: 'messages.options',
          path: '/meeting/:meetingId/:meetingTypeId/service/option/list',
          backendPath: '/meeting/:meetingId/service-option/list',
          actions: [Actions.CanEditMeetings],
        },
        create: <Link>{
          name: 'messages.create',
          path: '/meeting/:meetingId/:meetingTypeId/service/option/create',
          backendPath: '/meeting/:meetingId/service-option/create',
          actions: [Actions.CanEditMeetings],
        },
        edit: <Link>{
          name: 'messages.edit',
          path: '/meeting/:meetingId/:meetingTypeId/service/option/:serviceOptionId/edit',
          backendPath: '/meeting/service-option/:serviceOptionId/edit',
          actions: [Actions.CanEditMeetings],
        },
        delete: <Link>{
          name: 'messages.delete',
          backendPath: '/meeting/service-option/:serviceOptionId/delete',
          actions: [Actions.CanEditMeetings],
        },
      },
      category: {
        config: <Link>{
          icon: 'options',
          name: 'messages.categories',
          path: '/meeting/:meetingId/:meetingTypeId/service/category/config',
          backendPath: '/meeting/:meetingId/service-category-config',
          actions: [Actions.CanEditMeetings],
        }
      },
      form: {
        config: <Link>{
          icon: 'format_list_bulleted',
          name: 'messages.form',
          path: '/meeting/:meetingId/:meetingTypeId/service/form/config',
          backendPath: '/meeting/:meetingId/service-form-config',
          actions: [Actions.CanEditMeetings],
        }
      }
    },
    hotel: {
      list: <Link>{
        icon: 'hotel',
        name: 'messages.hotelsAndRooms',
        path: '/meeting/:meetingId/:meetingTypeId/hotel/list',
        backendPath: '/meeting/:meetingId/hotel/list',
        actions: [Actions.CanMeetingHotelsList],
      },
      assign: <Link>{
        backendPath: '/meeting/:meetingId/hotel/assign',
        actions: [Actions.CanMeetingHotelsList],
      },
      edit: <Link>{
        path: '/meeting/:meetingId/:meetingTypeId/hotel/:hotelId/edit',
        backendPath: '/meeting/:meetingId/hotel/:hotelId/edit',
        actions: [Actions.CanMeetingHotelsEdit],
      },
      room: {
        usage: <Link>{
          backendPath: '/meeting/:meetingId/hotel/room/:roomId/usage',
        },
        delete: <Link>{
          backendPath: '/meeting/:meetingId/hotel/room/:roomId/delete',
        },
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/meeting/hotel/:meetingHotelId/delete',
      },
    },
    exportRegistrationForm: {
      backendPath: '/form/meeting/:meetingId/export-registration-form',
      actions: [Actions.CanExportMeetingRegistrationForm],
    },
    exportParticipantFiles: {
      backendPath: '/meeting/:meetingId/participant_stats/files',
      actions: [Actions.CanGenerateEventFilesReport],
    },
  },
  lessons: <Link>{
    icon: 'school',
    name: 'messages.lessons',
    path: '/lessons',
    actions: [Actions.CanGoToAdminLessons],
  },
  group: {
    list: <Link>{
      icon: 'group_work',
      name: 'messages.groups',
      path: '/group/list',
      backendPath: '/group/list',
      actions: [Actions.CanListGroups],
    },
    create: <Link>{
      name: 'messages.newGroup',
      path: '/group/create',
      backendPath: '/group/create',
      actions: [Actions.CanCreateGroups],
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/group/:groupId/edit',
      backendPath: '/group/:groupId/edit',
      actions: [Actions.CanViewGroups],
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/group/:groupId/delete',
      actions: [Actions.CanDeleteGroups],
    },
    import: <Link>{
      name: 'messages.import',
      backendPath: '/group/import',
      actions: [Actions.CanImportGroups],
    }
  },
  confirmation: {
    list: <Link>{
      icon: 'check_circle',
      name: 'messages.confirmations',
      path: '/confirmation',
      actions: [Actions.CanListEditConfirmations, Actions.CanListEditMyTasks],
    },
    all: {
      list: <Link>{
        icon: 'check_circle',
        name: 'messages.confirmations',
        path: '/confirmation/all',
        backendPath: '/confirmation/list',
        actions: [Actions.CanListEditConfirmations],
      },
    },
    tasks: {
      list: <Link>{
        icon: 'check_circle',
        name: 'messages.myTaskList',
        path: '/confirmation/tasks',
        backendPath: '/confirmation/tasks',
        actions: [Actions.CanListEditMyTasks],
      }
    },
  },
  substitution: {
    list: <Link>{
      icon: 'repeat',
      name: 'messages.substitutions',
      path: '/substitution/list',
      backendPath: '/substitution/list',
      actions: [Actions.CanListEditSubstitutions],
    },
    create: <Link>{
      name: 'messages.create',
      path: '/substitution/create',
      backendPath: '/substitution/create',
      actions: [Actions.CanListEditSubstitutions],
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/substitution/:substitutionId/edit',
      backendPath: '/substitution/:substitutionId/edit',
      actions: [Actions.CanListEditSubstitutions],
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/substitution/:substitutionId/delete',
      actions: [Actions.CanListEditSubstitutions],
    },
  },
  myParticipants: {
    list: <Link>{
      icon: 'assignment_ind',
      name: 'messages.myParticipants',
      path: '/my-participants/list',
      backendPath: '/meeting/participant/list',
      actions: [Actions.CanListMyParticipantsList],
    },
  },
  speaker: {
    list: <Link>{
      icon: 'person',
      name: 'messages.speakers',
      path: '/speaker/list',
      backendPath: '/speaker/list',
      actions: [Actions.CanListEditSpeakers],
    },
    topMenuSpeakerList: <Link>{
      icon: 'person',
      name: 'messages.speakers',
      path: '/participant/top-menu-speaker/list',
      actions: [Actions.CanListEditSpeakers],
    },
    create: <Link>{
      icon: 'list-box',
      name: 'messages.create',
      path: '/speaker/create',
      backendPath: '/speaker/create',
      actions: [Actions.CanListEditSpeakers],
    },
    edit: <Link>{
      icon: 'list-box',
      name: 'messages.edit',
      path: '/speaker/:speakerId/edit',
      backendPath: '/speaker/:speakerId/edit',
      actions: [Actions.CanListEditSpeakers],
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/speaker/:speakerId/delete',
    },
    import: <Link>{
      icon: 'list-box',
      name: 'messages.import',
      path: '/speaker/import',
      backendPath: '/speaker/import',
      actions: [Actions.CanListEditSpeakers],
    }
  },
  form: {
    list: <Link>{
      icon: 'view_day',
      name: 'messages.menuFormList',
      path: '/form/list',
      backendPath: '/form/list',
      actions: [Actions.CanListEditForms]
    },
    create: <Link>{
      path: '/form/create',
      backendPath: '/form/create',
      name: 'messages.createForm',
    },
    edit: <Link>{
      path: '/form/:formId/edit',
      backendPath: '/form/:formId/edit',
    },
    editCommon: <Link>{
      path: '/form/common/edit',
      backendPath: '/form/common/edit',
      name: 'messages.editCommonForm',
    },
    preview: <Link>{
      path: '/form/:formId/preview',
      backendPath: '/form/:formId/preview',
    },
    export: <Link>{
      backendPath: '/form/:formId/export'
    },
    exportAll: <Link>{
      backendPath: '/form/:formId/export?withNotCompleted=1'
    },
    delete: <Link>{
      backendPath: '/form/:formId/delete',
      name: 'messages.delete'
    },
    save: <Link>{
      backendPath: '/form/:formId/save',
    },
    submission: {
      list: <Link>{
        path: '/form/:formId/submission/list',
        backendPath: '/form/:formId/submission/list'
      },
      preview: <Link>{
        backendPath: '/form/submission/:submissionId/preview',
      },
      previewQna: <Link>{
        backendPath: '/form/submission/:submissionId/preview-qna',
      },
      edit: <Link>{
        backendPath: '/form/submission/:submissionId/edit',
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/form/submission/:submissionId/delete',
      },
    },
    history: <Link>{
      path: '/form/filled-out/:id/history/:formId'
    },
    chart: <Link>{
      path: '/chart/:code'
    },
    presentation: <Link>{
      path: '/presentation/:code'
    },
    dispatch: <Link>{
      path: '/public/:code'
    },
  },
  profile: {
    list: <Link>{
      icon: 'recent_actors',
      name: 'messages.profiles',
      path: '/profile',
      actions: [
        Actions.CanListParticipantProfiles,
        Actions.CanListUserProfiles,
        Actions.CanListPassports,
      ],
    },
    participant: {
      list: <Link>{
        name: 'messages.profileParticipants',
        path: '/profile/participant',
        backendPath: '/participant-profile/list',
        actions: [Actions.CanListParticipantProfiles],
      },
      create: <Link>{
        name: 'messages.createProfile',
        path: '/profile/participant/create',
        backendPath: '/participant-profile/create',
        actions: [Actions.CanEditParticipantProfiles, Actions.CanEditMyParticipantProfiles],
      },
      edit: {
        main: <Link>{
          name: 'messages.edit',
          path: '/profile/participant/:profileId/edit',
          backendPath: '/participant-profile/:profileId/edit',
          actions: [Actions.CanEditParticipantProfiles, Actions.CanEditMyParticipantProfiles],
        },
        messages: <Link>{
          name: 'messages.messages',
          path: '/profile/participant/:profileId/messages',
          backendPath: '/participant-profile/:profileId/messages',
          actions: [Actions.CanEditParticipantProfiles, Actions.CanEditMyParticipantProfiles],
        },
        questionnaire: <Link>{
          name: 'messages.forms',
          path: '/profile/participant/:profileId/forms',
          backendPath: '/participant-profile/:profileId/form-submissions',
          actions: [Actions.CanEditParticipantProfiles, Actions.CanEditMyParticipantProfiles],
        },
        meetings: <Link>{
          name: 'messages.meetings',
          path: '/profile/participant/:profileId/meetings',
          backendPath: '/participant-profile/:profileId/meetings',
          actions: [Actions.CanEditParticipantProfiles, Actions.CanEditMyParticipantProfiles],
        },
      },
      delete: <Link>{
        name: 'messages.hardDelete',
        backendPath: '/participant-profile/:profileId/hard-delete',
      },
      export: <Link>{
        name: 'messages.export',
        path: '/participant-profile/export',
        actions: [Actions.CanExportParticipantProfiles]
      },
      import: <Link>{
        backendPath: '/participant-profile/import',
      },
      exportMoodleProgress: <Link>{
        name: 'messages.exportMoodleProgress',
        backendPath: '/user/export/moodle-progress',
        actions: [Actions.CanExportMoodleProgress, Actions.CanExportFullMoodleProgress]
      },
    },
    user: {
      list: <Link>{
        name: 'messages.menuUserList',
        path: '/profile/user',
        backendPath: '/user-profile/list',
        actions: [Actions.CanListUserProfiles],
      },
      create: <Link>{
        name: 'messages.createUser',
        path: '/profile/user/create',
        backendPath: '/user-profile/create',
        actions: [Actions.CanEditUserProfiles, Actions.CanEditMyUserProfiles],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/profile/user/:profileId/edit',
        backendPath: '/user-profile/:profileId/edit',
        actions: [Actions.CanEditUserProfiles, Actions.CanEditMyUserProfiles],
      },
      delete: <Link>{
        name: 'messages.hardDelete',
        backendPath: '/user-profile/:profileId/hard-delete',
      },
      export: <Link>{
        name: 'messages.export',
        path: '/user-profile/export',
        actions: [Actions.CanExportParticipantProfiles],
      },
    },
    passport: {
      list: <Link>{
        name: 'messages.passports',
        path: '/profile/passport',
        backendPath: '/passport/list',
        actions: [Actions.CanListPassports],
      },
      create: <Link>{
        name: 'messages.createPassport',
        path: '/profile/passport/create',
        backendPath: '/passport/create',
        actions: [Actions.CanEditPassports],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/profile/passport/:passportId/edit',
        backendPath: '/passport/:passportId/edit',
        actions: [Actions.CanEditPassports],
      },
      delete: <Link>{
        name: 'messages.hardDelete',
        backendPath: '/passport/:passportId/hard-delete',
      },
      export: <Link>{
        name: 'messages.activityExport',
        path: '/user/export/user_activity',
        backendPath: '/user/export/user-activity',
        actions: [Actions.CanExportNotActivePassports],
      }
    }
  },
  companyFile: {
    list: <Link>{
      icon: 'format_list_bulleted',
      name: 'messages.files',
      path: '/file/list',
      backendPath: '/company-file/list',
      actions: [Actions.CanListFiles],
    },
    create: <Link>{
      icon: 'insert_drive_file',
      name: 'messages.addFile',
      path: '/file/create',
      backendPath: '/company-file/create',
      actions: [Actions.CanCreateFiles],
    },
    edit: <Link>{
      icon: 'insert_drive_file',
      name: 'messages.edit',
      path: '/file/:fileId/edit',
      backendPath: '/company-file/:fileId/edit',
      actions: [Actions.CanViewFiles],
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/company-file/:fileId/delete',
      actions: [Actions.CanDeleteFiles],
    },
  },
  template: {
    tab: <Link>{
      icon: 'email',
      name: 'messages.templates',
      path: '/template/email-builder/list',
      actions: [Actions.CanListEditMailingTemplates],
    },
    emailBuilder: {
      list: <Link>{
        name: 'messages.emailBuilder',
        path: '/template/email-builder/list',
        actions: [Actions.CanListEditMailingTemplates],
      },
      create: <Link>{
        name: 'messages.emailBuilder',
        path: '/template/email-builder/create',
        actions: [Actions.CanListEditMailingTemplates],
      },
      edit: <Link>{
        name: 'messages.emailBuilder',
        path: '/template/email-builder/edit/:id',
        actions: [Actions.CanListEditMailingTemplates],
      },
    },
    email: {
      list: <Link>{
        name: 'messages.emailTemplates',
        path: '/template/email/list',
        backendPath: '/mailing/template/list',
        actions: [Actions.CanListEditMailingTemplates],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/template/email/create',
        backendPath: '/mailing/template/create',
        actions: [Actions.CanListEditMailingTemplates],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/template/email/:templateId/edit',
        backendPath: '/mailing/template/:templateId/edit',
        actions: [Actions.CanListEditMailingTemplates],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/mailing/template/:templateId/delete',
        actions: [Actions.CanListEditMailingTemplates],
      },
    },
    independentEmail: {
      list: <Link>{
        name: 'messages.menuIndependentTemplate',
        path: '/template/independent-email/list',
        backendPath: '/mailing/template/list/independent',
        actions: [Actions.CanListEditMailingTemplates],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/template/independent-email/create',
        backendPath: '/mailing/template/create',
        actions: [Actions.CanListEditMailingTemplates],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/template/independent-email/:templateId/edit',
        backendPath: '/mailing/template/:templateId/edit',
        actions: [Actions.CanListEditMailingTemplates],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/mailing/template/:templateId/delete',
        actions: [Actions.CanListEditMailingTemplates],
      },
    },
    contract: {
      list: <Link>{
        name: 'messages.contracts',
        path: '/template/contract/list',
        backendPath: '/contract/list',
        actions: [Actions.CanListContracts],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/template/contract/create',
        backendPath: '/contract/create',
        actions: [Actions.CanCreateContracts],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/template/contract/:contractId/edit',
        backendPath: '/contract/:contractId/edit',
        actions: [Actions.CanViewContracts],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/contract/:contractId/delete',
        actions: [Actions.CanDeleteContracts],
      },
    }
  },
  organizer: {
    list: <Link>{
      icon: 'people',
      name: 'messages.organizers',
      path: '/organizer/list',
      backendPath: '/organizer/list',
      actions: [Actions.CanListEditOrganizers],
    },
    create: <Link>{
      name: 'messages.create',
      path: '/organizer/create',
      backendPath: '/organizer/create',
      actions: [Actions.CanListEditOrganizers],
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/organizer/:organizerId/edit',
      backendPath: '/organizer/:organizerId/edit',
      actions: [Actions.CanListEditOrganizers],
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/organizer/:organizerId/delete',
      actions: [Actions.CanListEditOrganizers],
    },
  },
  report: {
    tab: <Link>{
      icon: 'table_chart',
      name: 'messages.reports',
      path: '/report',
      actions: [Actions.CanGenerateReports],
    }
  },
  companySettings: {
    tab: <Link>{
      icon: 'work',
      name: 'messages.settings',
      path: '/basic-settings/edit',
      actions: [Actions.CanListEditCompanies],
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/basic-settings/edit',
      backendPath: '/company/basic-settings/edit',
      actions: [Actions.CanListEditCompanies],
    },
  },
  profileField: {
    edit: <Link>{
      path: '/profile-field/edit',
      backendPath: '/company/profile-field/edit',
      name: 'messages.editProfileFields',
      actions: [Actions.CanViewProfileFieldConfig]
    },
  },
  line: {
    list: <Link>{
      name: 'messages.lines',
      path: '/line/list',
      backendPath: '/line/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/line/create',
      backendPath: '/line/create',
    },
    edit: <Link>{
      name: 'messages.line',
      path: '/line/:lineId/edit',
      backendPath: '/line/:lineId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/line/:lineId/delete',
    },
  },
  region: {
    list: <Link>{
      name: 'messages.regions',
      path: '/region/list',
      backendPath: '/region/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/region/create',
      backendPath: '/region/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/region/:regionId/edit',
      backendPath: '/region/:regionId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/region/:regionId/delete',
    },
  },
  meetingTag: {
    list: <Link>{
      name: 'messages.meetingTags',
      path: '/meeting-tag/list',
      backendPath: '/meeting-tag/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/meeting-tag/create',
      backendPath: '/meeting-tag/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/meeting-tag/:meetingTagId/edit',
      backendPath: '/meeting-tag/:meetingTagId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/meeting-tag/:meetingTagId/delete',
    },
  },
  fileCategory: {
    list: <Link>{
      name: 'messages.fileCategory',
      path: '/file-category/list',
      backendPath: '/file-category/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/file-category/create',
      backendPath: '/file-category/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/file-category/:fileCategoryId/edit',
      backendPath: '/file-category/:fileCategoryId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/file-category/:fileCategoryId/delete',
    },
  },
  meetingPath: {
    list: <Link>{
      name: 'messages.meetingPath',
      path: '/meeting-path/list',
      backendPath: '/meeting-path/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/meeting-path/create',
      backendPath: '/meeting-path/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/meeting-path/:pathId/edit',
      backendPath: '/meeting-path/:pathId/edit',
    },
    graph: <Link>{
      name: 'messages.showCharts',
      backendPath: '/meeting-path/:pathId/graph',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/meeting-path/:pathId/delete',
    },
  },
  meetingServiceCategory: {
    list: <Link>{
      icon: 'business_center',
      name: 'messages.registrationCategories',
      path: '/meeting-service-category/list',
      backendPath: '/meeting-service-category/list',
    },
    create: <Link>{
      icon: 'business_center',
      name: 'messages.create',
      path: '/meeting-service-category/create',
      backendPath: '/meeting-service-category/create',
    },
    edit: <Link>{
      icon: 'business_center',
      name: 'messages.edit',
      path: '/meeting-service-category/:meetingServiceCategoryId/edit',
      backendPath: '/meeting-service-category/:meetingServiceCategoryId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/meeting-service-category/:meetingServiceCategoryId/delete',
    },
  },
  consent: {
    list: <Link>{
      name: 'messages.consents',
      path: '/consent/list',
      backendPath: '/company/consent/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/consent/create',
      backendPath: '/company/consent/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/consent/:consentId/edit',
      backendPath: '/company/consent/:consentId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/company/consent/:consentId/delete',
    }
  },
  externalRole: {
    list: <Link>{
      name: 'messages.externalRoles',
      path: '/external-role/list',
      backendPath: '/external-role/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/external-role/create',
      backendPath: '/external-role/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/external-role/:externalRoleId/edit',
      backendPath: '/external-role/:externalRoleId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/external-role/:externalRoleId/delete',
    }
  },
  ldapConfig: {
    edit: <Link>{
      name: 'messages.ldap',
      path: '/ldap-config/edit',
      backendPath: '/company/ldap-config/edit',
      actions: [Actions.CanViewLDAPConfig],
    },
  },
  paymentConfig: {
    edit: <Link>{
      name: 'messages.payments',
      path: '/payment-config/edit',
      backendPath: '/company/payment-config/edit',
      actions: [Actions.CanViewCompanyPaymentConfig],
    },
  },
  specialtyGroup: {
    list: <Link>{
      name: 'messages.specialtyGroups',
      path: '/specialty-group/list',
      backendPath: '/specialty-group/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/specialty-group/create',
      backendPath: '/specialty-group/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/specialty-group/:specialtyGroupId/edit',
      backendPath: '/specialty-group/:specialtyGroupId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/specialty-group/:specialtyGroupId/delete',
    }
  },
  roleAllowedGroup: {
    edit: <Link>{
      name: 'messages.roleAllowedGroup',
      path: '/role-allowed-group/edit',
      backendPath: '/company/role-allowed-group/edit',
      actions: [Actions.CanViewRoleAllowedGroups],
    },
  },
  dashboardConfig: {
    edit: <Link>{
      name: 'messages.dashboardConfig',
      path: '/dashboard-config/edit',
      backendPath: '/company/dashboard-config/edit',
      actions: [Actions.CanViewCompanyDashboardConfig],
    },
  },
  mailingConfig: {
    edit: <Link>{
      name: 'messages.mailingConfig',
      path: '/mailing-config/edit',
      backendPath: '/company/mailing-config/edit',
      actions: [Actions.CanViewCompanyMailingConfig],
    },
  },
  contractConfig: {
    edit: <Link>{
      name: 'messages.contractConfig',
      path: '/contract-config/edit',
      backendPath: '/company/contract-config/edit',
      actions: [Actions.CanViewContractConfig],
    },
  },
  companyUserMeetingLimit: {
    list: <Link>{
      name: 'messages.companyUserMeetingLimit',
      path: '/company-user-meeting-limit/list',
      backendPath: '/company-user-meeting-limit/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/company-user-meeting-limit/create',
      backendPath: '/company-user-meeting-limit/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/company-user-meeting-limit/:limitId/edit',
      backendPath: '/company-user-meeting-limit/:limitId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/company-user-meeting-limit/:limitId/delete',
    }
  },
  cronConfig: {
    list: <Link>{
      name: 'messages.cronConfig',
      path: '/cron-config/list',
      backendPath: '/company/cron-config/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/cron-config/create',
      backendPath: '/company/cron-config/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/cron-config/:cronConfigId/edit',
      backendPath: '/company/cron-config/:cronConfigId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/company/cron-config/:cronConfigId/delete',
    }
  },
  configuration: {
    tab: <Link>{
      icon: 'settings_applications',
      name: 'messages.configuration',
      path: '/configuration',
      actions: [Actions.CanListEditCompanies, Actions.CanListHotels, Actions.CanEditGlobalRoles, Actions.CanListEditInstances]
    },
    role: {
      list: <Link>{
        icon: 'supervisor_account',
        name: 'messages.roles',
        path: '/configuration/role/list',
        backendPath: '/role/list',
        actions: [Actions.CanListRoles],
      },
      create: <Link>{
        icon: 'list_alt',
        name: 'messages.createRole',
        path: '/configuration/role/create',
        backendPath: '/role/create',
        actions: [Actions.CanCreateRoles],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/configuration/role/:roleId/edit',
        backendPath: '/role/:roleId/edit',
        actions: [Actions.CanViewRoles],
      },
      clone: <Link>{
        name: 'messages.clone',
        path: '/configuration/role/:roleId/clone',
        backendPath: '/role/:roleId/clone',
        actions: [Actions.CanEditRoles],
      },
      delete: <Link>{
        name: 'messages.delete',
        path: '/configuration/role/:roleId/delete',
        backendPath: '/role/:roleId/delete',
        actions: [Actions.CanDeleteRoles],
      },
    },
    company: {
      list: <Link>{
        icon: 'grain',
        name: 'messages.systemClients',
        path: '/configuration/company/list',
        backendPath: '/company/list',
        actions: [Actions.CanListEditCompanies],
      },
      create: <Link>{
        icon: 'grain',
        name: 'messages.createCompany',
        path: '/configuration/company/create',
        backendPath: '/company/create',
        actions: [Actions.CanListEditCompanies],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/company/:companyId/delete',
        actions: [Actions.CanDeleteCompanies],
      },
    },
    hotel: {
      list: <Link>{
        name: 'messages.hotels',
        path: '/configuration/hotel/list',
        backendPath: '/hotel/list',
        actions: [Actions.CanListHotels],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/configuration/hotel/create',
        backendPath: '/hotel/create',
        actions: [Actions.CanCreateHotels],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/configuration/hotel/:hotelId/edit',
        backendPath: '/hotel/:hotelId/edit',
        actions: [Actions.CanViewHotels],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/hotel/:hotelId/delete',
        actions: [Actions.CanDeleteHotels],
      },
    },
    instance: {
      list: <Link>{
        name: 'messages.instances',
        path: '/configuration/instance/list',
        backendPath: '/instance/list',
        actions: [Actions.CanListEditInstances],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/configuration/instance/create',
        backendPath: '/instance/create',
        actions: [Actions.CanListEditInstances],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/configuration/instance/:instanceId/edit',
        backendPath: '/instance/:instanceId/edit',
        actions: [Actions.CanListEditInstances],
      },
      log: <Link>{
        name: 'messages.showLogs',
        path: '/configuration/instance/:instanceId/log',
        backendPath: '/instance/:instanceId/activity-log/list',
        actions: [Actions.CanListEditInstances],
      },
      logExport: <Link>{
        name: 'messages.download',
        backendPath: '/instance/:instanceId/activity-log/export',
        actions: [Actions.CanViewLog],
      },
      consent: {
        list: <Link>{
          name: 'messages.consents',
          path: '/configuration/instance/:instanceId/consent/list',
          backendPath: '/instance/:instanceId/consent/list',
          actions: [Actions.CanListEditInstances],
        },
        create: <Link>{
          name: 'messages.create',
          path: '/configuration/instance/:instanceId/consent/create',
          backendPath: '/instance/:instanceId/consent/create',
          actions: [Actions.CanListEditInstances],
        },
        edit: <Link>{
          name: 'messages.edit',
          path: '/configuration/instance/:instanceId/consent/:consentId/edit',
          backendPath: '/instance/:instanceId/consent/:consentId/edit',
          actions: [Actions.CanListEditInstances],
        },
        delete: <Link>{
          name: 'messages.delete',
          backendPath: '/instance/:instanceId/consent/:consentId/delete',
          actions: [Actions.CanListEditInstances],
        },
      },
      languageRegion: {
        list: <Link>{
          name: 'messages.languageRegions',
          path: '/configuration/instance/:instanceId/language-region/list',
          backendPath: '/instance/:instanceId/language-region/list',
          actions: [Actions.CanListEditInstances],
        },
        create: <Link>{
          name: 'messages.create',
          path: '/configuration/instance/:instanceId/language-region/create',
          backendPath: '/instance/:instanceId/language-region/create',
          actions: [Actions.CanListEditInstances],
        },
        edit: <Link>{
          name: 'messages.edit',
          path: '/configuration/instance/:instanceId/language-region/:regionId/edit',
          backendPath: '/instance/:instanceId/language-region/:regionId/edit',
          actions: [Actions.CanListEditInstances],
        },
        delete: <Link>{
          name: 'messages.delete',
          backendPath: '/instance/:instanceId/language-region/:regionId/delete',
          actions: [Actions.CanListEditInstances],
        },
      }
    },
    advanced: {
      command: <Link>{
        name: 'messages.advanced',
        path: '/configuration/advanced/command',
        backendPath: '/advanced/execute',
        actions: [Actions.CanUseCommandLineInAdminMode],
      },
    },
  },
  variable: {
    list: <Link>{
      name: 'messages.variables',
      path: '/variable/list',
      backendPath: '/variable/list',
    },
    create: <Link>{
      name: 'messages.create',
      path: '/variable/create',
      backendPath: '/variable/create',
    },
    edit: <Link>{
      name: 'messages.edit',
      path: '/variable/:variableId/edit',
      backendPath: '/variable/:variableId/edit',
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/variable/:variableId/delete',
    },
  },
  // Participant mode
  participant: {
    base: <Link>{
      icon: 'list-box',
      name: 'messages.home',
      path: '/mobile'
    },
    topMenu: <Link>{
      icon: 'apps',
      name: 'messages.topMenuTitle',
      path: '/participant/top-menu'
    },
    info: <Link>{
      path: '/participant/meeting/:meetingId/info'
    },
    meetingPreview: <Link>{
      path: '/participant/meeting/:meetingId/preview'
    },
    meetingPreviewInfo: <Link>{
      path: '/participant/meeting/:meetingId/preview/info'
    },
    meetingPreviewAgenda: <Link>{
      path: '/participant/meeting/:meetingId/preview/agenda'
    },
    meetingPreviewSpeakers: <Link>{
      path: '/participant/meeting/:meetingId/preview/speakers'
    },
    meetingRegister: <Link>{
      path: '/participant/meeting/:meetingId/register/:formId'
    },
    meetingList: <Link>{
      icon: 'settings_voice',
      name: 'messages.events',
      path: '/participant/meeting/list',
      actions: [Actions.CanListMeetingsInParticipantMode]
    },
    meeting: <Link>{
      path: '/participant/meeting/:meetingId'
    },
    training: <Link>{
      path: '/participant/training/:meetingId'
    },
    trainingList: <Link>{
      icon: 'square_foot',
      name: 'messages.trainings',
      path: '/participant/training/list'
    },
    campaign: <Link>{
      path: '/participant/campaign/:campaignId'
    },
    campaignList: <Link>{
      icon: 'how_to_vote',
      name: 'messages.campaigns',
      path: '/participant/campaign/list'
    },
    questionnaire: {
      list: <Link>{
        path: '/participant/meeting/:meetingId/questionnaire/list',
      }
    },
    test: {
      list: <Link>{
        path: '/participant/meeting/:meetingId/test/list'
      }
    },
    agenda: <Link>{
      path: '/participant/meeting/:meetingId/agenda'
    },
    agendaSession: <Link>{
      path: '/participant/meeting/:meetingId/session/:sessionId'
    },
    speaker: {
      list: <Link>{
        path: '/participant/meeting/:meetingId/speaker/list'
      },
      info: <Link>{
        path: '/participant/meeting/:meetingId/speaker/:id'
      },
      topMenuSpeakerInfo: <Link>{
        path: '/participant/speaker/:id'
      },
    },
    file: {
      list: <Link>{
        path: '/participant/meeting/:meetingId/file/list'
      }
    },
    topMenuMaterials: {
      list: <Link>{
        path: '/participant/top-menu-materials/list',
        icon: 'folder_open',
        name: 'messages.materials'
      },
    },
    topMenuMyFiles: {
      list: <Link>{
        path: '/participant/top-menu-my-files/list',
        icon: 'folder_open',
        name: 'messages.myFiles'
      },
    },
    myFile: {
      list: <Link>{
        path: '/participant/meeting/:meetingId/my-file/list'
      }
    },
    meetingVideo: {
      list: <Link>{
        path: '/participant/meeting/:meetingId/meeting-video/list'
      }
    },
    participant: {
      list: <Link>{
        path: '/participant/meeting/:meetingId/participant/list'
      },
      info: <Link>{
        path: '/participant/meeting/:meetingId/participant/:id'
      }
    },
    gallery: <Link>{
      path: '/participant/meeting/:meetingId/gallery'
    },
    meetingMessage: {
      list: <Link>{
        path: '/participant/meeting/:meetingId/meeting-message/list'
      }
    },
    videoStream: <Link>{
      path: '/participant/meeting/:meetingId/video-stream'
    },
    contract: <Link>{
      path: '/participant/meeting/:meetingId/contract'
    },
    // participants trainings
    participantTraining: {
      info: <Link>{
        path: '/participant/training/:meetingId/info'
      },
      meetingPreview: <Link>{
        path: '/participant/training/:meetingId/preview'
      },
      meetingPreviewInfo: <Link>{
        path: '/participant/training/:meetingId/preview/info'
      },
      meetingPreviewAgenda: <Link>{
        path: '/participant/training/:meetingId/preview/agenda'
      },
      meetingPreviewSpeakers: <Link>{
        path: '/participant/training/:meetingId/preview/speakers'
      },
      meetingRegister: <Link>{
        path: '/participant/training/:meetingId/register/:formId'
      },
      meetingList: <Link>{
        icon: 'settings_voice',
        name: 'messages.events',
        path: '/participant/training/list',
        actions: [Actions.CanListMeetingsInParticipantMode]
      },
      training: <Link>{
        path: '/participant/training/:meetingId'
      },
      questionnaire: {
        list: <Link>{
          path: '/participant/training/:meetingId/questionnaire/list'
        }
      },
      test: {
        list: <Link>{
          path: '/participant/training/:meetingId/test/list'
        }
      },
      agenda: <Link>{
        path: '/participant/training/:meetingId/agenda'
      },
      agendaSession: <Link>{
        path: '/participant/training/:meetingId/session/:sessionId'
      },
      speaker: {
        list: <Link>{
          path: '/participant/training/:meetingId/speaker/list'
        },
        info: <Link>{
          path: '/participant/training/:meetingId/speaker/:id'
        },
        topMenuSpeakerInfo: <Link>{
          path: '/participant/speaker/:id'
        },
      },
      file: {
        list: <Link>{
          path: '/participant/training/:meetingId/file/list'
        }
      },
      myFile: {
        list: <Link>{
          path: '/participant/training/:meetingId/my-file/list'
        }
      },
      meetingVideo: {
        list: <Link>{
          path: '/participant/training/:meetingId/meeting-video/list'
        }
      },
      participant: {
        list: <Link>{
          path: '/participant/training/:meetingId/participant/list'
        },
        info: <Link>{
          path: '/participant/training/:meetingId/participant/:id'
        }
      },
      gallery: <Link>{
        path: '/participant/training/:meetingId/gallery'
      },
      meetingMessage: {
        list: <Link>{
          path: '/participant/training/:meetingId/meeting-message/list'
        }
      },
      videoStream: <Link>{
        path: '/participant/training/:meetingId/video-stream'
      }
    },
    // event campaigns
    participantCampaign: {
      info: <Link>{
        path: '/participant/campaign/:meetingId/info'
      },
      meetingPreview: <Link>{
        path: '/participant/campaign/:meetingId/preview'
      },
      meetingPreviewInfo: <Link>{
        path: '/participant/campaign/:meetingId/preview/info'
      },
      meetingPreviewAgenda: <Link>{
        path: '/participant/campaign/:meetingId/preview/agenda'
      },
      meetingPreviewSpeakers: <Link>{
        path: '/participant/campaign/:meetingId/preview/speakers'
      },
      meetingRegister: <Link>{
        path: '/participant/campaign/:meetingId/register/:formId'
      },
      meetingList: <Link>{
        icon: 'settings_voice',
        name: 'messages.events',
        path: '/participant/campaign/list',
        actions: [Actions.CanListMeetingsInParticipantMode]
      },
      meeting: <Link>{
        path: '/participant/campaign/:meetingId'
      },
      training: <Link>{
        path: '/participant/training/:meetingId'
      },
      campaign: <Link>{
        path: '/participant/campaign/:meetingId'
      },
      questionnaire: {
        list: <Link>{
          path: '/participant/campaign/:meetingId/questionnaire/list'
        }
      },
      test: {
        list: <Link>{
          path: '/participant/campaign/:meetingId/test/list'
        }
      },
      agenda: <Link>{
        path: '/participant/campaign/:meetingId/agenda'
      },
      agendaSession: <Link>{
        path: '/participant/campaign/:meetingId/session/:sessionId'
      },
      speaker: {
        list: <Link>{
          path: '/participant/campaign/:meetingId/speaker/list'
        },
        info: <Link>{
          path: '/participant/campaign/:meetingId/speaker/:id'
        }
      },
      file: {
        list: <Link>{
          path: '/participant/campaign/:meetingId/file/list'
        }
      },
      myFile: {
        list: <Link>{
          path: '/participant/campaign/:meetingId/my-file/list'
        }
      },
      meetingVideo: {
        list: <Link>{
          path: '/participant/campaign/:meetingId/meeting-video/list'
        }
      },
      participant: {
        list: <Link>{
          path: '/participant/campaign/:meetingId/participant/list'
        },
        info: <Link>{
          path: '/participant/campaign/:meetingId/participant/:id'
        }
      },
      gallery: <Link>{
        path: '/participant/campaign/:meetingId/gallery'
      },
      meetingMessage: {
        list: <Link>{
          path: '/participant/campaign/:meetingId/meeting-message/list'
        }
      },
      videoStream: <Link>{
        path: '/participant/campaign/:meetingId/video-stream'
      }
    }
  },
  // Mobile pages
  mobile: {
    login: <Link>{
      icon: 'exit_to_app',
      name: 'messages.login',
      path: '/mobile/login'
    },
    register: <Link>{
      icon: 'log_in',
      name: 'messages.register',
      path: '/mobile/register'
    }
  },
  website: {
    list: <Link>{
      icon: 'home',
      name: 'messages.websites',
      path: '/website/list',
      backendPath: '/website/list',
      actions: [Actions.CanListEditWebsites],
    },
    clone: <Link>{
      name: 'messages.clone',
      path: '/website/:websiteId/clone',
      backendPath: '/website/:websiteId/clone',
      actions: [Actions.CanListEditWebsites],
    },
    delete: <Link>{
      name: 'messages.delete',
      backendPath: '/website/:websiteId/delete',
      actions: [Actions.CanListEditWebsites],
    },
    page: {
      list: <Link>{
        icon: 'web_asset',
        name: 'messages.pages',
        path: '/website/:websiteId/page/list',
        backendPath: '/website/:websiteId/page/list',
        actions: [Actions.CanListEditWebsites],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/website/:websiteId/page/create',
        backendPath: '/website/:websiteId/page/create',
        actions: [Actions.CanListEditWebsites],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/website/:websiteId/page/:pageId/edit',
        backendPath: '/website/:websiteId/page/:pageId/edit',
        actions: [Actions.CanListEditWebsites],
      },
      clone: <Link>{
        name: 'messages.clone',
        backendPath: '/website/:websiteId/page/:pageId/clone',
        actions: [Actions.CanListEditWebsites],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/website/:websiteId/page/:pageId/delete',
        actions: [Actions.CanListEditWebsites],
      },
    },
    pageSchema: {
      list: <Link>{
        backendPath: '/website/:websiteId/page-schema/list',
        actions: [Actions.CanListEditWebsites],
      },
    },
    commonComponent: {
      list: <Link>{
        icon: 'inventory',
        name: 'messages.commonComponents',
        path: '/website/:websiteId/common-component/list',
        backendPath: '/website/:websiteId/common-component/list',
        actions: [Actions.CanListEditWebsites],
      },
      choiceList: <Link>{
        backendPath: '/website/:websiteId/common-component/choice-list',
      },
      create: <Link>{
        name: 'messages.create',
        path: '/website/:websiteId/common-component/create/:type',
        backendPath: '/website/:websiteId/common-component/create/:type',
        actions: [Actions.CanListEditWebsites],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/website/:websiteId/common-component/:commonComponentId/edit',
        backendPath: '/website/:websiteId/common-component/:commonComponentId/edit',
        actions: [Actions.CanListEditWebsites],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/website/:websiteId/common-component/:commonComponentId/delete',
        actions: [Actions.CanListEditWebsites],
      },
    },
    category: {
      list: <Link>{
        icon: 'category',
        name: 'messages.categories',
        path: '/website/:websiteId/category/list',
        backendPath: '/website/:websiteId/category/list',
        actions: [Actions.CanListEditWebsites],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/website/:websiteId/category/create',
        backendPath: '/website/:websiteId/category/create',
        actions: [Actions.CanListEditWebsites],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/website/:websiteId/category/:categoryId/edit',
        backendPath: '/website/:websiteId/category/:categoryId/edit',
        actions: [Actions.CanListEditWebsites],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/website/:websiteId/category/:categoryId/delete',
        actions: [Actions.CanListEditWebsites],
      },
    },
    tag: {
      list: <Link>{
        icon: 'tag',
        name: 'messages.tags',
        path: '/website/:websiteId/tag/list',
        backendPath: '/website/:websiteId/edit/tag/list',
        actions: [Actions.CanListEditWebsites],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/website/:websiteId/tag/create',
        backendPath: '/website/:websiteId/edit/tag/create',
        actions: [Actions.CanListEditWebsites],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/website/:websiteId/tag/:tagId/edit',
        backendPath: '/website/:websiteId/edit/tag/:tagId/edit',
        actions: [Actions.CanListEditWebsites],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/website/:websiteId/tag/:tagId/delete',
        actions: [Actions.CanListEditWebsites],
      },
    },
    article: {
      list: <Link>{
        icon: 'list_alt',
        name: 'messages.articles',
        path: '/website/:websiteId/article/list',
        backendPath: '/website/:websiteId/article/list',
        actions: [Actions.CanListEditWebsites],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/website/:websiteId/article/create',
        backendPath: '/website/:websiteId/article/create',
        actions: [Actions.CanListEditWebsites],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/website/:websiteId/article/:articleId/edit',
        backendPath: '/website/:websiteId/article/:articleId/edit',
        actions: [Actions.CanListEditWebsites],
      },
      clone: <Link>{
        name: 'messages.clone',
        backendPath: '/website/:websiteId/article/:articleId/clone',
        actions: [Actions.CanListEditWebsites],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/website/:websiteId/article/:articleId/delete',
        actions: [Actions.CanListEditWebsites],
      },
    },
    menu: {
      list: <Link>{
        icon: 'menu',
        name: 'messages.menus',
        path: '/website/:websiteId/menu/list',
        backendPath: '/website/:websiteId/menu/list',
        actions: [Actions.CanListEditWebsites],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/website/:websiteId/menu/create',
        backendPath: '/website/:websiteId/menu/create',
        actions: [Actions.CanListEditWebsites],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/website/:websiteId/menu/:menuId/edit',
        backendPath: '/website/:websiteId/menu/:menuId/edit',
        actions: [Actions.CanListEditWebsites],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/website/:websiteId/menu/:menuId/delete',
        actions: [Actions.CanListEditWebsites],
      },
    },
    settings: {
      config: <Link>{
        icon: 'settings',
        name: 'messages.settings',
        path: '/website/:websiteId/settings/config',
        backendPath: '/website/:websiteId/edit',
        actions: [Actions.CanListEditWebsites],
      },
      communication: <Link>{
        icon: 'mail',
        name: 'messages.communication',
        path: '/website/:websiteId/settings/communication',
        actions: [Actions.CanListEditWebsites],
      },
      gamification: <Link>{
        icon: 'games',
        name: 'messages.gamification',
        path: '/website/:websiteId/settings/gamification',
        actions: [Actions.CanListEditWebsites],
      },
      customFonts: {
        list: <Link>{
          name: 'messages.customFonts',
          path: '/website/:websiteId/settings/custom-font/list',
          backendPath: '/website/:websiteId/custom-font/list',
          actions: [Actions.CanListEditWebsites],
        },
        create: <Link>{
          name: 'messages.create',
          path: '/website/:websiteId/settings/custom-font/create',
          backendPath: '/website/:websiteId/custom-font/create',
          actions: [Actions.CanListEditWebsites],
        },
        edit: <Link>{
          name: 'messages.edit',
          path: '/website/:websiteId/settings/custom-font/:customFontId/edit',
          backendPath: '/website/:websiteId/custom-font/:customFontId/edit',
          actions: [Actions.CanListEditWebsites],
        },
        delete: <Link>{
          name: 'messages.delete',
          path: '/website/:websiteId/settings/custom-font/:customFontId/delete',
          backendPath: '/website/:websiteId/custom-font/:customFontId/delete',
          actions: [Actions.CanListEditWebsites],
        },
      },
      switcher: <Link>{
        icon: 'switch',
        name: 'messages.switcher',
        path: '/website/:websiteId/settings/switcher',
        actions: [Actions.CanListEditWebsites],
      },
      payments: <Link>{
        icon: 'pay',
        name: 'messages.payments',
        path: '/website/:websiteId/settings/payments',
        actions: [Actions.CanListEditWebsites],
      },
    },
    websiteStyle: {
      list: <Link>{
        name: 'messages.websiteStyle',
        path: '/website/style/list',
        backendPath: '/website/style/list',
        actions: [Actions.CanListEditWebsites],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/website/style/create',
        backendPath: '/website/style/create',
        actions: [Actions.CanListEditWebsites],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/website/style/:websiteStyleId/edit',
        backendPath: '/website/style/:websiteStyleId/edit',
        actions: [Actions.CanListEditWebsites],
      },
      clone: <Link>{
        name: 'messages.clone',
        backendPath: '/website/style/:websiteStyleId/clone',
        actions: [Actions.CanListEditWebsites],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/website/style/:websiteStyleId/delete',
        actions: [Actions.CanListEditWebsites],
      }
    },
    websiteSnippet: {
      list: <Link>{
        name: 'messages.websiteSnippet',
        path: '/website/snippet/list',
        backendPath: '/website/snippet/list',
        actions: [Actions.CanListEditSnippets],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/website/snippet/create',
        backendPath: '/website/snippet/create',
        actions: [Actions.CanListEditSnippets],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/website/snippet/:websiteSnippetId/edit',
        backendPath: '/website/snippet/:websiteSnippetId/edit',
        actions: [Actions.CanListEditSnippets],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/website/snippet/:websiteSnippetId/delete',
        actions: [Actions.CanListEditSnippets],
      }
    },
    websiteTemplate: {
      list: <Link>{
        name: 'messages.websiteTemplates',
        path: '/website/template/list',
        backendPath: '/website/template/list',
        actions: [Actions.CanListEditWebsites],
      },
      create: <Link>{
        name: 'messages.create',
        path: '/website/template/create',
        backendPath: '/website/template/create',
        actions: [Actions.CanListEditWebsites],
      },
      edit: <Link>{
        name: 'messages.edit',
        path: '/website/template/:websiteTemplateId/edit',
        backendPath: '/website/template/:websiteTemplateId/edit',
        actions: [Actions.CanListEditWebsites],
      },
      delete: <Link>{
        name: 'messages.delete',
        backendPath: '/website/template/:websiteTemplateId/delete',
        actions: [Actions.CanListEditWebsites],
      },
    },
  },
  internalMessages: {
    list: <Link>{
      icon: 'email',
      name: 'messages.messages',
      path: '/messages/list',
      backendPath: '/internal-message/list',
      actions: [Actions.CanListEditInternalMessages],
    },
    createThread: <Link>{
      name: 'messages.newMessage',
      path: '/messages/create',
      backendPath: '/internal-message/new-thread/:threadType',
      actions: [Actions.CanListEditInternalMessages],
    },
    createMessage: <Link>{
      name: 'messages.send',
      backendPath: '/internal-message/new-message',
      actions: [Actions.CanListEditInternalMessages],
    },
    setThreadAsRead: <Link>{
      backendPath: '/internal-message/set-thread-as-read',
      actions: [Actions.CanListEditInternalMessages],
    },
  },
  // Done refactor upper
  start: <Link>{
    icon: 'home',
    name: 'messages.menuHome',
    path: '/'
  },
  home: <Link>{
    icon: 'home',
    name: 'messages.menuHome',
    path: '/user/account'
  },
  config: <Link>{
    icon: 'cog',
    name: 'messages.menuSettings',
    path: null,
    actions: []
  },
  meetingMessageCreate: <Link>{
    icon: 'mail',
    name: 'messages.messageCreate',
    path: '/meeting/:meetingId/:meetingTypeId/message/create',
    actions: [Actions.CanListEditSendMailings],
  },
  meetingMessageEdit: <Link>{
    icon: 'mail',
    name: 'messages.messagesEdit',
    path: '/meeting/:meetingId/:meetingTypeId/message/{messageId}/edit',
    actions: [Actions.CanListEditSendMailings],
  },
  meetingLocationList: <Link>{
    icon: 'list-box',
    name: 'messages.meetingLocationList',
    path: '/meeting/:meetingId/:meetingTypeId/location/list'
  },
  meetingLocationCreate: <Link>{
    icon: 'list-box',
    name: 'messages.create',
    path: '/meeting/:meetingId/:meetingTypeId/location/create'
  },
};

LinkList.config.actions = extractActions(LinkList.configuration);

export { LinkList };
