import { Component, Input } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  host: {'class': 'form-input'},
})
export class InputEmailComponent extends InputAbstract {
  @Input() addPlaceholder = true;
  @Input() addClass = '';
}
