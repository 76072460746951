import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingComponent } from './loading/loading.component';
import { LoadingControllerInterface } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingController implements LoadingControllerInterface{
  loadingControllers: MatDialogRef<any>[] = [];

  constructor(public dialog: MatDialog) {}

  async create(data: any = {}): Promise<{ dismiss: () => Promise<void>; present: () => Promise<void> }> {
    let loading: MatDialogRef<any> = null;

    return {
      present: async () => {
        loading = this.dialog.open(LoadingComponent, {
          panelClass: 'modal-loading'
        });
        loading.disableClose = true;
        this.loadingControllers.push(loading);
      },
      dismiss: async () => {
        if (loading) {
          loading.close();
        }
      }
    };
  }

  async dismiss(): Promise<void> {
    this.loadingControllers = this.loadingControllers.filter(value => {
      value.close();
      return false;
    });
  }
}
