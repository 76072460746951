import { Injectable } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { TranslateService } from '@ngx-translate/core';

export interface Country {
  country: string,
  name: string,
  code: number
}

export interface Number {
  number?: number,
  code?: number,
  country?: string,
  pretty?: string,
  national?: string,
  international?: string,
  valid: boolean
}

@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  // @ts-ignore
  readonly region = new Intl.DisplayNames([this.translate.currentLang], {type: 'region'});
  readonly phoneUtil = PhoneNumberUtil.getInstance();

  constructor(
    private translate: TranslateService,
  ) {}

  public async getCountries(): Promise<[Country?]> {
    const countries: [Country?] = [];
    this.phoneUtil.getSupportedRegions().map(country => countries.push({
      country: country.toLowerCase(),
      name: this.region.of(country),
      code: this.phoneUtil.getCountryCodeForRegion(country)
    }));

    return countries.sort((a: Country, b: Country) => a.name > b.name ? 1 : -1);
  }

  parse(number: any, country: string = null): Number {
    if (!(typeof number === 'string' && number.startsWith('+'))) {
      number = +number || null;
    }

    if (number === null) {
      return {number: null, code: null, country, pretty: '', national: null, international: null, valid: true};
    }

    try {
      const phoneNumber = country
        ? this.phoneUtil.parse(number.toString(), country.toLowerCase())
        : this.phoneUtil.parse(number.toString());

      if (this.phoneUtil.isValidNumber(phoneNumber)) {
        return {
          number: phoneNumber.getNationalNumber(),
          code: phoneNumber.getCountryCode(),
          country: this.phoneUtil.getRegionCodeForCountryCode(phoneNumber.getCountryCode()).toLowerCase(),
          pretty: this.phoneUtil.format(phoneNumber, PhoneNumberFormat.E164),
          national: this.phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL),
          international: this.phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL),
          valid: true,
        };
      }
    } catch (e) {
      //
    }

    const code = this.phoneUtil.getCountryCodeForRegion(country);

    return {
      number,
      code,
      country,
      pretty: '+' + code + number,
      national: null,
      international: null,
      valid: false
    };
  }
}
