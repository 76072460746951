import * as packageJsonData from 'package.json';
const packageJson = packageJsonData;

export const environment = {
    production: false,
    version: packageJson.version,
    url: 'http://vinci2dev.vincievents.com/',
    loginPath: '/login',
    mobileLoginPath: '/mobile/login',
    instance: 'VINCI',
    defaultLanguage: 'pl',
    websocket: 'ws://217.168.131.251:8087/',
    frontendUrl: 'http://vinci2dev.vincievents.com',
};