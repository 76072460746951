<div *ngIf="isVisible">
  <mat-form-field>
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <mat-label *ngIf="!isFromEntity">{{ formSchema.label }}</mat-label>

    <input
      matInput
      placeholder="{{ formSchema.label }}"
      (focus)="focusEvent.emit($event)"
      [type]="formSchema.attr?.type ?? 'number'"
      [formControl]="$any(formSchema.formControl)"
      [readonly]="formSchema.attr?.readonly ?? false"
      [attr.min]="formSchema.attr?.min ?? min"
      [attr.max]="formSchema.attr?.max ?? max"
      [step]="formSchema.attr?.step"
    >
  </mat-form-field>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</div>
