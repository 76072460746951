import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

export function checkAsDirty(control: AbstractControl, skippedControl?: AbstractControl): void {
  if (control === skippedControl) {
    return;
  }

  if (control instanceof FormGroup || control instanceof FormArray) {
    for (const i of Object.keys(control.controls)) {
      checkAsDirty(control.controls[i], skippedControl);
    }
  } else if (control instanceof FormControl) {
    control.markAsTouched();
  }
}
