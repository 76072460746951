import { Component, Input } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-custom-label',
  templateUrl: './custom-label.component.html',
  host: {'class': 'd-block'},
})
export class CustomLabelComponent extends InputAbstract {
  @Input() forceDisplay = false;
}
