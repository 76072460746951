import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeService, UserService, UserStorageService } from '@frontend/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TitleService } from './layout/link/menu/service/title.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet [innerHTML]="customStyles"></router-outlet>`
})
export class RootComponent implements OnInit, OnDestroy {
  customStyles;

  private body;
  private subscribe;

  constructor(
    private themeService: ThemeService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private userStorageService: UserStorageService,
    private pageTitleService: Title,
    private titleService: TitleService,
  ) {}

  ngOnInit(): void {
    this.themeService.init().then();
    this.body = document.body;
    this.initTheme().then();
    this.setPageTitle();
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  async initTheme(): Promise<void> {
    await this.theme(false);

    this.subscribe = this.themeService.changeStyle.subscribe(async () => await this.theme());
  }

  getAdditionalCss(classes): void {
    this.userService.getData().then(data => {
      const cssInfo = data.instance;
      this.injectAdditionalCss(classes, cssInfo);
    });
  }

  injectAdditionalCss(classes, cssInfo): void {
    let css = '';
    if (classes === 'vinci-light' && cssInfo.cssLightMode) {
      css = cssInfo.cssLightMode;
    } else if (classes === 'vinci-night' && cssInfo.cssDarkMode) {
      css = cssInfo.cssDarkMode;
    }

    if (cssInfo.cssBothMode) {
      css = cssInfo.cssBothMode.concat(css);
    }

    this.customStyles = this.sanitizer.bypassSecurityTrustHtml(`<style>${css}</style>`);
  }

  setPageTitle(): void {
    this.userStorageService.isLoggedIn
      ? this.userService.getData().then(data => this.pageTitleService.setTitle(data.instance.name))
      : this.titleService.instanceTitle.subscribe(instanceName => this.pageTitleService.setTitle(instanceName));
  }

  private async theme(animation = true): Promise<void> {
    this.body.removeAttribute('class');

    if (animation) {
      this.body.classList.add('theme-animation');
      setTimeout(() => {
        this.body.classList.remove('theme-animation');
      }, 500);
    }
    const classes = await this.themeService.getThemeClass();
    this.body.classList.add(classes);
    setTimeout(() => {
      if (this.userStorageService.isLoggedIn) {
        this.getAdditionalCss(classes);
      }
    }, 100);
  }
}
