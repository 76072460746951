import {LinkList} from '../../link-list/link-list';
import {MenuListItem} from '../../../module/menu/interface/menu-list-item';

export const WebsiteMenu: MenuListItem[] = [
  {
    link: LinkList.website.page.list,
    activatingPaths: [
      LinkList.website.page.create,
      LinkList.website.page.edit,
    ]
  },
  {
    link: LinkList.website.commonComponent.list,
    activatingPaths: [
      LinkList.website.commonComponent.create,
      LinkList.website.commonComponent.edit,
    ]
  },
  {
    link: LinkList.website.category.list,
    activatingPaths: [
      LinkList.website.category.create,
      LinkList.website.category.edit,
    ]
  },
  {
    link: LinkList.website.tag.list,
    activatingPaths: [
      LinkList.website.tag.create,
      LinkList.website.tag.edit,
    ]
  },
  {
    link: LinkList.website.article.list,
    activatingPaths: [
      LinkList.website.article.create,
      LinkList.website.article.edit,
    ]
  },
  {
    link: LinkList.website.menu.list,
    activatingPaths: [
      LinkList.website.menu.create,
      LinkList.website.menu.edit,
    ]
  },
  {
    link: LinkList.website.settings.config,
    activatingPaths: [
      LinkList.website.settings.communication,
      LinkList.website.settings.gamification,
      LinkList.website.settings.customFonts.list,
      LinkList.website.settings.customFonts.create,
      LinkList.website.settings.customFonts.edit,
      LinkList.website.settings.switcher,
      LinkList.website.settings.payments,
    ]
  }
];
