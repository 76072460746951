import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationDomain } from '@frontend/core';
import { InputAbstract } from '../../../interface/input-abstract';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  host: {'class': 'form-input'},
})
export class InputSelectComponent extends InputAbstract implements OnInit, OnDestroy {
  @Input() required: boolean;
  @Input() visible: boolean;
  @Input() disabled: boolean;
  @Output() valueChangedTrigger: EventEmitter<any> = new EventEmitter();
  @HostBinding('class') get classes() {
    return this.fieldType;
  }

  private subscription: Subscription;
  private fieldType: string;

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setFieldTypeClass();

    this.formSchema.choices.forEach((choice) => {
      if (choice.attr?.translate && !choice.attr.translated) {
        choice.attr.translated = true;
        choice.label = this.translateService.instant(TranslationDomain + choice.label);
      }
    });

    this.handleAutoSelectCheckbox();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  isValid(): boolean {
    const formControl = this.formSchema.formControl;
    if (this.visible && this.required && !formControl.value) {
      formControl.markAsTouched();
      formControl.setErrors({required: true});

      return false;
    }

    return true;
  }

  private setFieldTypeClass(): void {
    if (!this.formSchema.expanded) {
      this.fieldType = this.formSchema.multiple ? 'input-select-multiple' : 'input-select';
    } else {
      this.fieldType = this.formSchema.multiple ? 'input-checkboxes' : 'input-radio-group';
    }
  }

  private handleAutoSelectCheckbox(): void {
    const autoSelectCheckbox = this.formSchema.children?.find(child => child.attr.autoSelect);
    if (autoSelectCheckbox) {
      this.subscription = autoSelectCheckbox.formControl.valueChanges.subscribe(value => {
        this.formSchema.children.forEach(checkbox => {
          if (autoSelectCheckbox.key !== checkbox.key) {
            checkbox.formControl.setValue(value ? (checkbox.options?.value ?? 1) : '');
          }
        });
      })
    }
  }
}
