import { Injectable } from '@angular/core';
import { Theme } from '../enum/theme';
import { UserStorageService } from './user-storage.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasicSystemInfo } from '@frontend/interfaces';
import { InstanceService } from './instance.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  themes = Theme;
  _theme: Theme;
  private change = new Subject<Theme>();
  changeStyle = this.change.asObservable();

  constructor(
    private userStorageService: UserStorageService,
    private instanceService: InstanceService,
  ) {}

  async init() {
    const theme = await this.getTheme();
    this.change.next(theme);
  }

  async setTheme(theme: Theme) {
    this._theme = theme;
    await this.userStorageService.setTheme(theme);

    this.change.next(theme);
  }

  async getTheme(): Promise<Theme> {
    if (!this._theme) {
      this._theme = await this.userStorageService.getTheme();

      if (!this._theme) {
        this._theme = this.themes.light;
      }
    }

    return this._theme;
  }

  async getThemeClass() {
    let themeStyle = '';

    if (!this._theme) {
      await this.getTheme();
    }

    switch (this._theme) {
      case 0:
        themeStyle = 'vinci-light';
        break;

      case 1:
        themeStyle = 'vinci-night';
        break;
    }
    return themeStyle;
  }

  get instanceIcon(): Observable<string> {
    return this.instanceService.instance().pipe(
      map((data: BasicSystemInfo) => data.instance.logoUrl)
    );
  }
}
