export enum DateFormatSettings {
  smart,
  longDate,
  shortDate,
  time,
  onlyTime,
  onlyDate,
  shortTime,
  longTime,
  dateWithTime,
  showInUtc,
  showInPcTimeZone,
  onlyDay,
  hotelNight,
}
