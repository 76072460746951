import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatSettings } from '../enum/date-format-settings.enum';

const moment = require('moment-timezone');

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  private dateToFormat: Date;
  private currentDate: Date;
  private smart: boolean;
  private date: boolean;
  private time: boolean;
  private fullDate: boolean;
  private fullTime: boolean;
  private momentDate: any;
  private showInUtc: boolean;
  private onlyDay: boolean;
  private hotelNight: boolean;

  constructor(private translateService: TranslateService) {}

  transform(value: any, ...args: DateFormatSettings[]): any {
    this.setConfigurations(args);

    if (typeof value === 'object') {
      this.momentDate = moment(<string>value.date);

      if (value.timezone && !this.showInUtc) {
        this.momentDate.parseZone(value.timezone).tz(moment.tz.guess());
      }
    } else {
      if (!this.showInUtc) {
        this.momentDate = moment(<string>value).tz(moment.tz.guess());
      } else {
        this.momentDate = moment.utc(<string>value);
      }
    }

    this.dateToFormat = new Date(this.momentDate.toLocaleString());

    if (this.smart && this.time) {
      return this.formatHours();
    }

    if (this.onlyDay || this.hotelNight) {
      return this.formatDate();
    }

    return this.formatDate() + ' ' + this.formatHours();
  }

  private setConfigurations(args: DateFormatSettings[]) {
    this.smart = false;
    this.date = true;
    this.time = true;
    this.fullDate = false;
    this.fullTime = false;

    this.currentDate = new Date();

    args.forEach(value => {
      switch (value) {
        case DateFormatSettings.smart:
          this.smart = true;
          break;
        case DateFormatSettings.longDate:
          this.fullDate = true;
          break;
        case DateFormatSettings.shortDate:
          this.fullDate = false;
          break;
        case DateFormatSettings.onlyTime:
          this.date = false;
          break;
        case DateFormatSettings.shortTime:
          this.fullTime = false;
          break;
        case DateFormatSettings.dateWithTime:
          this.date = false;
          break;
        case DateFormatSettings.longTime:
          this.fullTime = true;
          this.time = true;
          break;
        case DateFormatSettings.onlyDate:
          this.time = false;
          break;
        case DateFormatSettings.showInUtc:
          this.showInUtc = true;
          break;
        case DateFormatSettings.showInPcTimeZone:
          this.showInUtc = false;
          break;
        case DateFormatSettings.onlyDay:
          this.onlyDay = true;
          break;
        case DateFormatSettings.hotelNight:
          this.time = false;
          this.hotelNight = true;
          break;
      }
    });
  }

  private formatDate() {
    if (!this.date) {
      return '';
    }

    if (
      !this.fullDate &&
      this.currentDate.getFullYear() !== this.dateToFormat.getFullYear()
    ) {
      this.fullDate = true;
    }

    const dateFormat = this.translateService.instant(
      'messages.' + (this.fullDate ? 'DATE_LOCALE' : 'DATE_SHORT_LOCALE')
    );

    if (this.smart) {
      return this.momentDate.calendar(this.currentDate, {
        sameDay: '[' + this.translateService.instant('messages.today') + ']',
        nextDay: '[' + this.translateService.instant('messages.tomorrow') + ']',
        nextWeek: 'dddd',
        lastDay: '[' + this.translateService.instant('messages.yesterday') + ']',
        lastWeek: dateFormat,
        sameElse: dateFormat
      });
    } else if (this.onlyDay) {
      return this.momentDate.date();
    } else if (this.hotelNight) {
      const nextDay = moment(<string>this.momentDate.format('YYYY-MM-DD')).add(1, 'days');

      return (
        this.momentDate.format('M') === nextDay.format('M') ? this.momentDate.date() : this.momentDate.format('D.MM')
      ) + '/' + nextDay.format('D.MM.YYYY');
    } else {
      return this.momentDate.format(dateFormat);
    }
  }

  private formatHours(): string {
    if (this.time) {
      const dateFormat = this.translateService.instant(
        'messages.' + (this.fullTime ? 'TIME_LOCALE' : 'TIME_SHORT_LOCALE')
      );

      if (this.smart) {
        return this.momentDate.fromNow();
      }

      return this.momentDate.format(dateFormat);
    }

    return '';
  }
}
