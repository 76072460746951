import { EventEmitter, Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Actions, EnvService, LinkService } from '@frontend/core';
import { TranslationDomain } from '@frontend/core';
import { FormSchema } from '../interface/form-schema';
import { InputValidatorList } from './input-validator-list';
import { BasicBuilder } from './builders/basic-builder';
import { InputTypeList } from './input-type-list';

@Injectable({providedIn: 'root'})
export class ControlBuilder {
  constructor(
    private formBuilder: FormBuilder,
    private inputValidatorList: InputValidatorList,
    private inputTypeList: InputTypeList,
    private envService: EnvService,
    private translate: TranslateService,
    private linkService: LinkService,
  ) {}

  public create(
    newChildren: Array<FormSchema>,
    currentSchema: FormSchema,
    globalForm: FormSchema
  ): void {
    newChildren.forEach(child => child.isDynamic = true);

    this.buildForm(newChildren, currentSchema, globalForm);

    if (!currentSchema.children) {
      currentSchema.children = [];
    }

    currentSchema.children = [...currentSchema.children, ...newChildren];
  }

  public remove(formSchema: FormSchema, row: FormSchema): void {
    const controls = (<FormArray>formSchema.formControl).controls;
    if (!Array.isArray(controls)) {
      return;
    }

    controls.splice(controls.indexOf(row.formControl), 1);
    formSchema.formControl.updateValueAndValidity();
    formSchema.children.splice(formSchema.children.indexOf(row), 1);
  }

  public buildForm(
    children: Array<FormSchema>,
    currentSchema: FormSchema,
    globalForm: FormSchema,
    isGlobalLevel?: boolean
  ): void {
    if (isGlobalLevel) {
      globalForm.pageChanged$ = new EventEmitter<void>;
      globalForm.visibilityChanged$ = new EventEmitter<void>;
      this.getChildrenAsMap(globalForm);
    }

    if (children === null) {
      return;
    }

    const nativeControl: AbstractControl = currentSchema.formGroup || currentSchema.formControl;

    for (const formSchema of children) {
      if (
        formSchema.label
        && (
          (!globalForm.translated && !formSchema.attr.translated) || formSchema.attr.translate
        )
      ) {
        formSchema.label = this.translate.instant(TranslationDomain + formSchema.label);
        if (Array.isArray(formSchema.attr)) {
          formSchema.attr = {};
        }

        formSchema.attr.translated = true;
      }

      formSchema.parent = currentSchema;

      const componentDefinition = formSchema.type !== undefined
        ? this.inputTypeList.findComponent(formSchema.type)
        : null;

      !componentDefinition
        ? BasicBuilder.build(this, this.formBuilder, formSchema, globalForm)
        : componentDefinition.builder.build(this, this.formBuilder, formSchema, globalForm);

      if (
        formSchema.formControl && this.checkParentIsDisabled(currentSchema)
        || formSchema.attr?.hidden && formSchema.isDependent
        || formSchema.attr?.restricted && !this.linkService.checkUserHasPrivileges([Actions.CanEditRestrictedPersonalData])
      ) {
        formSchema.formControl.disable({onlySelf: false, emitEvent: false});
        formSchema.disabled = true;
      }

      if (formSchema.formControl && formSchema.constraints?.length > 0) {
        const validators = [];

        for (const constraint of formSchema.constraints) {
          const validator = this.inputValidatorList.findValidator(constraint.name);

          if (validator) {
            if (validator.IS_CUSTOM) {
              new validator(
                formSchema,
                nativeControl,
                constraint.payload,
                globalForm,
                this,
                this.envService
              );
            } else {
              if (constraint.payload) {
                validators.push(validator(constraint.payload));
              } else {
                validators.push(validator);
              }
            }
          }
        }

        formSchema.formControl.setValidators(Validators.compose(validators));
        formSchema.formControl.updateValueAndValidity();
      }

      if (nativeControl instanceof FormGroup) {
        nativeControl.addControl(formSchema.key, formSchema.formControl);
      } else if (nativeControl instanceof FormArray) {
        nativeControl.push(formSchema.formControl);
      }
    }

    this.getChildrenAsMap(currentSchema);
    currentSchema.isBuilt = true;
  }

  public getChildrenAsMap(input: FormSchema): void {
    const map = new Map<any, FormSchema>();

    if (input.children) {
      input.children.forEach(v => map.set(v.key, v));
    }

    input.map = map;
    input.map.forEach(v => this.getChildrenAsMap(v));
  }

  private checkParentIsDisabled(currentSchema?: FormSchema): boolean {
    return currentSchema && (currentSchema.disabled || this.checkParentIsDisabled(currentSchema.parent));
  }
}
