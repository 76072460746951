import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { SessionTime } from "@frontend/interfaces";
import { Observable } from 'rxjs/internal/Observable';
import { mergeMap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { UserStorageService } from './user-storage.service';
import { HttpService } from './http.service';
import { User } from '../interfaces/user';
import { BasicDataMeeting } from "@frontend/interfaces";
import { ModeService } from "./mode.service";

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  resetPasswordSubject: Subject<void> = new Subject<void>();
  changeCompanySubject: Subject<void> = new Subject<void>();
  sessionTimeEventEmitter: EventEmitter<SessionTime> = new EventEmitter<SessionTime>();
  unreadMsgCount = 0;
  private data;
  private subscription: Subscription;

  constructor(
    private http: HttpService,
    private userStorage: UserStorageService,
    private envService: EnvService,
    private modeService: ModeService
  ) {
    this.subscription = this.userStorage.userChange.subscribe(() => this.data = null);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  login(form, loginMode): Observable<User> {
    return this.http
      .post(this.envService.getApiUrl(`public/login`), {...form, loginMode})
      .pipe(
        mergeMap(async (resp: User) => {
          if (resp.changedMode !== null) {
            await this.modeService.setMode(resp.changedMode);
          }

          await this.setUser(resp, false);

          return resp;
        })
      );
  }

  websiteAutoLogin(token): Observable<any> {
    return this.http
      .post(this.envService.getApiUrl(`public/autologin/website`), {...token})
      .pipe(
        mergeMap(async (resp) => {
          await this.setUser(resp, false);

          return resp;
        })
      );
  }

  adminAutoLogin(token): Observable<any> {
    return this.http
      .post(this.envService.getApiUrl(`public/autologin/admin`), {...token})
      .pipe(
        mergeMap(async (resp) => {
          await this.setUser(resp, false);

          return resp;
        })
      );
  }

  refreshToken(lastUserAction: Date): Observable<boolean> {
    return this.http
      .get(this.envService.getApiUrl(`public/refresh-token/${lastUserAction.getTime()}`))
      .pipe(
        mergeMap(async resp => {
          await this.setUser(resp, false);

          return resp;
        })
      );
  }

  mobileLoginTry(form): Observable<User> {
    return this.http
      .post(this.envService.getApiUrl(`public/mobile-login`), form)
      .pipe(
        mergeMap(async (resp: User) => {
          if (resp.token) {
            await this.setUser(resp, false);
          }

          return resp;
        })
      );
  }

  register(form): Observable<any> {
    return this.http.post(this.envService.getApiUrl(`public/register`), form);
  }

  async getData(): Promise<User> {
    if (this.data) {
      return this.data;
    }

    this.data = firstValueFrom(this.fetchBasicDataObservable());

    return this.data;
  }

  fetchBasicDataObservable(): Observable<any> {
    return this.http.get(this.envService.getApiUrl(`user/account/basic-data`));
  }

  changeCompany(id: Number): Observable<any> {
    this.changeCompanySubject.next();

    return this.http
      .post(this.envService.getApiUrl(`user/account/change-selected-company/${id}`), {})
      .pipe(
        mergeMap(async resp => {
          this.data = null;
          await this.setUser(resp, true);

          return;
        })
      );
  }

  loginAs(userId: number): Observable<any> {
    this.changeCompanySubject.next();

    return this.http
      .post(this.envService.getApiUrl(`user/account/login-as/${userId}`), {})
      .pipe(
        mergeMap(async resp => {
          this.data = null;
          await this.setUser(resp, true);

          return;
        })
      );
  }

  async logout(): Promise<void> {
    this.unreadMsgCount = 0;

    return new Promise<void>((resolve, reject) => {
      this.http
        .post(this.envService.getApiUrl(`public/logout`), {})
        .subscribe({
          next: async () => {
            await this.userStorage.logout();
            resolve();
          },
          error: err => {
            console.error(err);
            reject(err);
          }
        });
    });
  }

  completeRegistration(token): Observable<any> {
    return this.http
      .get(this.envService.getApiUrl('public/complete-registration/' + token.token))
      .pipe(mergeMap(async (resp) => resp));
  }

  async getTopMenuMode(): Promise<boolean> {
    const data = await this.getData();

    return data.instance.topMenu;
  }

  async getNumberOfElement(element: 'Training' | 'Campaign' | 'Event'): Promise<BasicDataMeeting> {
    const data = await this.getData();

    return data.meetings.find(elem => elem.name === element);
  }

  async getNumberOfElements(): Promise<BasicDataMeeting[]> {
    const data = await this.getData();

    return data.meetings;
  }

  getCategorySymbolsForUser(websiteId: number): Observable<any> {
    return this.http.get(this.envService.getApiUrl(`public/website-category-symbols-for-user/${websiteId}`));
  }

  loadModalForm(formId: number): Observable<any> {
    return this.http.get(this.envService.getApiUrl(`/public/login/modal-form/${formId}`));
  }

  async setUser(user: {token: any}, emitEvent: boolean): Promise<void> {
    if (await this.userStorage.setUser(user, emitEvent)) {
      this.getData().then(userData => this.unreadMsgCount = userData.unreadMsgCount);
      await this.getUserAccountSettings();
    }
  }

  private async getUserAccountSettings(): Promise<void> {
    const resp = await firstValueFrom(
      this.http.post(this.envService.getApiUrl(`user/account/settings`), {})
    );

    await this.userStorage.setRoles(resp.roles);
    await this.userStorage.setUserFilters(resp.filters);
  }
}
