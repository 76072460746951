<ng-container *ngIf="display">
  <mat-accordion *ngFor="let meeting of cachedMeetingList" displayMode="flat" class="menu">
    <mat-expansion-panel *ngIf="meeting.name !== null" [(expanded)]="meeting.expanded">
      <mat-expansion-panel-header>
        <mat-panel-description>
          <span
            [ngClass]="{'active': meeting.active}"
          >{{ meeting.name | textEllipsis: {length: 50} }}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-menu-list [menuListItems]="[{menuListItems: meeting.menuListItems}]"></app-menu-list>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
