import { Component, Input } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  host: {'class': 'form-input'},
})
export class InputTextareaComponent extends InputAbstract {
  @Input() autoHeight = true;
  @Input() minHeight = false;
}
