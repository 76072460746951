import { ControlBuilder } from '../control-builder';
import { FormBuilder } from '@angular/forms';
import { FormSchema } from '../../interface/form-schema';
import { AbstractBuilder } from '../../interface/abstract-builder';

export class BasicBuilder extends AbstractBuilder {
  static build(
    cb: ControlBuilder,
    formBuilder: FormBuilder,
    input: FormSchema,
    globalForm: FormSchema
  ) {
    if (input.formPrototype || input.options?.allow_add) {
      input.formControl = formBuilder.array([]);

      if (input.children) {
        cb.buildForm(input.children, input, globalForm);
      }
    } else if (input.children?.length > 0) {
      input.formControl = formBuilder.group({});

      cb.buildForm(input.children, input, globalForm);
    } else {
      input.formControl = formBuilder.control(input.value);
    }

    if (input.disabled) {
      BasicBuilder.disable(input);
    }
  }

  static disable(input: FormSchema) {
    if (Array.isArray(input.children)) {
      input.children.forEach(value => BasicBuilder.disable(value));
    } else {
      if (input.formControl) {
        input.formControl.disable({ onlySelf: true, emitEvent: false });
      }
      input.disabled = true;
    }
  }
}
