import { Inject, Injectable, Optional } from '@angular/core';
import { User } from '../interfaces/user';
import { Storage } from '@ionic/storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EnvService } from './env.service';
import { Subject } from 'rxjs';
import { Mode } from '../enum/mode';
import { Theme } from '../enum/theme';
import { AvailableLanguagesService } from './available-languages.service';
import { CookieService } from 'ngx-cookie-service';
import { ɵREQUEST as REQUEST } from '@nguniversal/common/tokens';
import { Request } from 'express';
import { UserFilter } from '../interfaces/user-filter';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {
  userChange = new Subject<User>();
  userActionsChange = new Subject<Array<string>>();
  loginRequired = new Subject<void>();
  redirectUrl: string;
  private _theme: Theme;
  private _user: User = null;
  private _userFilters: UserFilter[] = [];
  private beforeLogout: (() => Promise<any>)[] = [];

  constructor(
    private storage: Storage,
    private envService: EnvService,
    private availableLanguagesService: AvailableLanguagesService,
    private cookieService: CookieService,
    @Optional() @Inject(REQUEST) private request: Request,
  ) {
    this.initStorage().then();
  }

  get user(): User {
    return this._user;
  }

  get userFilters(): UserFilter[] {
    return this._userFilters;
  }

  private _language = null;

  get language() {
    if (this.user && this.user.language) {
      return this.user.language;
    }

    try {
      return (
        this._language
        || this.availableLanguagesService.websiteLanguageFromAddress
        || this.envService.get('defaultLanguage')
      );
    } catch (e) {
      return this.envService.get('defaultLanguage');
    }
  }

  get isLoggedIn(): boolean {
    return this.user !== null;
  }

  async initStorage(): Promise<void> {
    this.storage = await this.storage.create();
  }

  async init(): Promise<boolean> {
    if (this.envService.isWebsite()) {
      await this.loadDataFromCookies();
    } else {
      await this.loadDataFromWebsiteStorage();
    }

    return !!this._user;
  }

  async setLanguage(value: any): Promise<void> {
    this._language = value;

    if (this.isLoggedIn) {
      this._user.language = value;
    }
    await this.cookieService.set('language', value);
  }

  async setUser(user: {token: any}, emitEvent: boolean): Promise<boolean> {
    if (user === null) {
      this._user = null;
    } else {
      const {token} = user;
      const helper = new JwtHelperService();
      const lastMode = await this.getMode();
      const userData = helper.decodeToken(token);

      if ((userData.exp * 1000) >= (new Date()).getTime()) {
        this._user = {token, ...userData};
        this._user.mode = this._user.selectedCompany === null ? Mode.participant : lastMode;
      } else {
        await this.logout();

        return false;
      }
    }

    await this.save(emitEvent);

    return true;
  }

  async setRoles(roles: string[]): Promise<void> {
    if (this._user) {
      this._user.roles = roles;
    }

    this.userActionsChange.next(roles);

    await this.save(false)
  }

  async setUserFilters(filters: UserFilter[]): Promise<void> {
    this._userFilters = filters;

    await this.saveFilters();
  }

  async setUserMode(mode): Promise<void> {
    if (this._user) {
      if (!!this._user?.substituteUser) {
        throw new Error(`Cannot change mode being logged in as other user!`);
      }

      this._user.mode = mode;
      await this.save(false);
    }
    await this.storage.set('mode', mode);
  }

  async getMode(): Promise<Mode> {
    if (!this._user) {
      return await this.storage.get('mode');
    }
    return this._user.mode;
  }

  async logout(): Promise<void> {
    for (const action of this.beforeLogout) {
      await action();
    }
    this.cookieService.delete('token', '/');
    await this.setUser(null, true);
    await this.setUserFilters([]);
  }

  hasAccess(action: string): boolean {
    if (!this.isLoggedIn || !this.user || !this.user.roles) {
      return false;
    }

    return this.user.roles.includes(action);
  }

  hasMeetingType(action: number): boolean {
    if (!this.isLoggedIn || !this.user || !this.user.meetingTypes) {
      return false;
    }

    return this.user.meetingTypes.includes(action);
  }

  async setTheme(theme: Theme): Promise<void> {
    await this.storage.set('theme', theme);
  }

  async getTheme(): Promise<any> {
    return await this.storage.get('theme');
  }

  addBeforeLogout(action: () => Promise<any>): void {
    this.beforeLogout.push(action);
  }

  async addFilter(userFilter: UserFilter, index: number, saveFilters: boolean, saveSortConfig: boolean): Promise<void> {
    const filterToSave: UserFilter = {
      url: userFilter.url,
    }

    if (saveFilters && userFilter.form) {
      filterToSave.form = userFilter.form;
    }

    if (saveFilters && userFilter.page) {
      filterToSave.page = userFilter.page;
    }

    if (saveSortConfig && userFilter.sort) {
      filterToSave.sort = userFilter.sort;
    }

    index !== -1 ? this._userFilters[index] = filterToSave : this._userFilters.push(filterToSave);

    await this.saveFilters();
  }

  private async loadDataFromWebsiteStorage(): Promise<void> {
    this._language = this.cookieService.get('language') || null;
    this._user = (await this.storage.get('user')) || null;
    this._userFilters = (await this.storage.get('userFilters')) || null;
    this._theme = (await this.getTheme()) || null;
  }

  private async loadDataFromCookies(): Promise<void> {
    let token;
    if (this.envService.isServerSide()) {
      this._language = this.request.cookies['language'] || null;
      token = this.request.cookies['token'] || null;
    } else {
      this._language = this.cookieService.get('language') || null;
      token = this.cookieService.get('token') || null;
    }

    await this.setUser(token ? {token: token} : null, false);
  }

  private async save(emitEvent: boolean): Promise<void> {
    if (emitEvent) {
      this.userChange.next(this.user);
    }

    await this.storage.set('user', this.user);
  }

  private async saveFilters(): Promise<void> {
    await this.storage.set('userFilters', this._userFilters);
  }
}
