<mat-nav-list class="menu">
  <ng-container *ngFor="let section of menuListItems">
    <div *ngIf="section.menuListItems.length > 0 && section.title && section.displayTitle" class="title-container">
      <span class="menu-list-item-title">{{ 'messages.' + section.title | translate | uppercase }}</span>
    </div>
    <app-list-menu-item
      *ngFor="let menuListItem of section.menuListItems"
      class="list-item"
      [menuListItem]="menuListItem"
      (hasRightEventEmitter)="itemHasRightOutput($event, section)"
    ></app-list-menu-item>
  </ng-container>
</mat-nav-list>
