import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import * as Hammer from 'hammerjs';

@Component({
  selector: 'lib-swipe-to-refresh',
  templateUrl: './swipe-to-refresh.component.html',
  styleUrls: ['./swipe-to-refresh.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwipeToRefreshComponent implements AfterViewInit {
  @Input() maxLoaderHeight = 80;
  @Input() scrollContentClass = '.content';
  @Output() refreshEvent = new EventEmitter<any>();
  refreshing = false;
  pan = false;
  value = 0;
  padding = 10;
  loaderSize = 25;
  @Input() disabled = false;
  @ViewChild('content', {static: true}) private content: ElementRef;
  @ViewChild('loaderSpace', {static: true}) private loaderSpace: ElementRef;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const main = <any>document.querySelector(this.scrollContentClass);
    const hammer = new Hammer(this.content.nativeElement, {
      inputClass: Hammer.TouchInput,
      touchAction: 'pan-y'
    });
    hammer
      .get('pan')
      .set({direction: Hammer.DIRECTION_VERTICAL, threshold: 1});

    hammer.on('panstart', $event => {
      if (
        (!this.disabled &&
          (main.scrollTop === 0 &&
            (!main.scrollingElement ||
              main.scrollingElement.scrollTop === 0))) ||
        main.scrollTop === undefined
      ) {
        this.pan = true;
        this.setHeight($event.deltaY);
      }
    });

    hammer.on('pandown', $event => {
      this.setHeight($event.deltaY);
    });

    hammer.on('panmove', $event => {
      this.setHeight($event.deltaY);
    });
    hammer.on('pancancel', $event => {
      this.stop($event);
    });

    hammer.on('panend', $event => {
      this.stop($event);
    });
  }

  public refresh() {
    if (this.refreshing) {
      return;
    }
    this.refreshing = true;
    this.pan = true;
    setTimeout(() => {
      this.loaderSpace.nativeElement.style.height = `${this.loaderSize + 2 * this.padding}px`;
      this.loaderSpace.nativeElement.style.opacity = 1;
    });
  }

  public stopRefresh() {
    this.refreshing = false;
    this.setHeight(0);
    this.pan = false;
  }

  private stop($event) {
    if (!this.pan) {
      return;
    }

    if ($event.deltaY >= this.maxLoaderHeight) {
      this.refreshEvent.emit();
      this.refresh();
    } else if (!this.refreshing) {
      this.setHeight(0);
      this.pan = false;
    }
  }

  private setHeight(distance: number) {
    if (this.refreshing || !this.pan) {
      return;
    }

    this.value = (distance / this.maxLoaderHeight) * 100;
    if (distance > this.maxLoaderHeight) {
      distance = this.maxLoaderHeight;
    } else if (distance < 0) {
      distance = 0;
    }
    this.loaderSpace.nativeElement.style.height = `${distance}px`;
    this.loaderSpace.nativeElement.style.opacity =
      distance / this.maxLoaderHeight;
    this.cd.markForCheck();
  }
}
