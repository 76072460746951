<ng-container *ngIf="isVisible && formSchema.formControl">
  <div style="display: none">
  <span
    #htmlButton
    (click)="changeHtml()"
    id="htmlButton"
    style="padding: 0"
    type="button"
  >
    <mat-icon style="font-size: 18px; height: auto; width: auto">code</mat-icon>
  </span>
  </div>
  <div *ngIf="loaded">
    <div
      *ngIf="formSchema.formControl.valid !== null"
      [ngClass]="{
      'ng-invalid': formSchema.formControl.invalid,
      'ng-touched': formSchema.formControl.touched,
      'ng-dirty': formSchema.formControl.dirty,
      'ng-valid': formSchema.formControl.valid,
      'field-wrapper': true
    }"
    >
      <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

      <quill-editor
        (onEditorCreated)="created()"
        [formControl]="$any(formSchema.formControl)"
        [modules]="config"
      ></quill-editor>
    </div>

    <app-error-messages
      [control]="formSchema.formControl"
      [errorMessage]="formSchema.attr?.errorMessage ?? null"
    ></app-error-messages>
  </div>
</ng-container>
