import { EventEmitter, Injectable } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { checkAsDirty } from '../function/checkAsDirty';
import { bindError } from '../function/bindError';
import { FormSchema } from '../interface/form-schema';
import { FileUploadProgressController, HttpService, LoadingController, ToastController } from '@frontend/core';
import { FormBuilder } from './form-builder.service';
import { LoadingService } from './loading.service';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class FormSubmitService {
  changeDetectorRefresh: EventEmitter<any> = new EventEmitter<any>();
  progressUpload = new Subject<any>();
  timeStamp = Date.now();
  formError = new Subject<boolean>()
  checkFormValidity = new Subject<void>();

  constructor(
    private http: HttpService,
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private fileUploadProgressController: FileUploadProgressController,
    private loadingService: LoadingService,
    private toastController: ToastController,
    private translateService: TranslateService,
  ) {}

  async loadSchema(formSchema: FormSchema, reload: boolean = false): Promise<any> {
    if (formSchema.children && !reload) {
      if (!formSchema.isBuilt) {
        this.formBuilder.build(formSchema);
      }
    } else {
      return this.httpLoad(formSchema);
    }
  }

  async sendData(
    formSchema: FormSchema,
    showLoading: boolean = true,
    showErrorToast: boolean = true,
    showFileUploadProgress: boolean = false,
    showSuccessToast: boolean = false,
    containerList?: { fieldContainers: Array<{ panels: MatExpansionPanel[] }> }
  ): Promise<any> {
    checkAsDirty(formSchema.formGroup);

    if (!formSchema.formGroup.valid) {
      this.scrollToError(formSchema, containerList);
      if (showErrorToast) {
        await this.showErrorToast({});
      }

      console.log(formSchema.formGroup);
      throw new Error('Invalid form control');
    }

    const formValue = {
      ...formSchema.formGroup.value,
      ...(formSchema.additionalData || [])
    };

    const {formData, isAnyFileInData} = this.checkIfIsRawFile(formValue);

    const loading = await this.loadingController.create({});
    const progress = await this.fileUploadProgressController.create({});

    if (showFileUploadProgress && isAnyFileInData) {
      await progress.present();
    } else if (showLoading) {
      await loading.present();
    }

    return isAnyFileInData
      ? await this.sendWithFile(formSchema, formData, loading, progress, showErrorToast, showSuccessToast)
      : await this.send(formSchema, formValue, loading, showErrorToast, showSuccessToast);
  }

  checkIfIsRawFile(formValue) {
    const formData: FormData = new FormData();
    const isAnyFileInData = this.buildFormData(formData, formValue, null);

    return {formData: formData, isAnyFileInData: isAnyFileInData};
  }

  index(form: FormSchema, positionName: string = 'position'): void {
    form.children.forEach((child: FormSchema, idx: number) => {
      const control = child.formControl.get(positionName);
      if (control) {
        control.setValue(idx);
      }
    });

    form.formControl.updateValueAndValidity();
  }

  indexContainers(formFieldContainers: FormSchema): void {
    let fieldPosition = 0;

    formFieldContainers.children.forEach((container, idx) => {
      container.formControl.get('position').setValue(idx);

      container.map.get('formFields').children?.forEach(field => {
        field.formControl.get('position').setValue(fieldPosition);
        fieldPosition++;
      });
    });

    formFieldContainers.formControl.updateValueAndValidity();
  }

  indexComponents(compositeField: FormSchema): void {
    compositeField.map.get('formFieldContainers').children.forEach(container => {
      container.map.get('formFields').children.forEach((field, idx) => {
        field.formControl.get('position').setValue(idx);
      });
    });
  }

  mergeFormData(resp, formSchema: FormSchema): void {
    formSchema.additionalData = {...formSchema.additionalData, ...resp.additionalData};
    formSchema.buttonStagesCompeted = resp.buttonStagesCompeted;
    formSchema.children = <FormSchema[]>resp.fields;
    formSchema.currentPage = 1;
    formSchema.dataListTypes = resp.dataListTypes;
    formSchema.dynamic = resp.dynamic;
    formSchema.fieldTypes = resp.fieldTypes;
    formSchema.fieldValidators = resp.fieldValidators;
    formSchema.formId = resp.formId;
    formSchema.formFieldPrototype = resp.formFieldPrototype;
    formSchema.formFieldValidators = resp.formFieldValidators;
    formSchema.formGroup = new FormGroup({});
    formSchema.formStagesCompleted = resp.formStagesCompleted;
    formSchema.format = resp.format;
    formSchema.isFormFromEntity = resp.isFormFromEntity;
    formSchema.key = 'global';
    formSchema.meetingServiceFormFieldOptionPrototype = resp.meetingServiceFormFieldOptionPrototype;
    formSchema.meetingServiceOptions = resp.meetingServiceOptions;
    formSchema.name = resp.name;
    formSchema.redirectLink = resp.redirectLink;
    formSchema.reloadAfterSubmission = resp.reloadAfterSubmission;
    formSchema.requiredButtonStagesText = resp.requiredButtonStagesText;
    formSchema.requiredFormStagesText = resp.requiredFormStagesText;
    formSchema.pageCount = resp.pageCount;
    formSchema.successHeader = resp.successHeader;
    formSchema.successMessage = resp.successMessage;
    formSchema.failureHeader = resp.failureHeader;
    formSchema.failureMessage = resp.failureMessage;
    const time = resp.time - (resp.now - resp.startFillingDate);
    formSchema.time = time > 0 ? time : null;
    formSchema.translated = resp.translated;
  }

  convertSize(total: number): string {
    return total < 1024000
      ? (total / 1024).toFixed(2) + ' KB'
      : (total / 1024000).toFixed(2) + ' MB';
  }

  scrollToError(
    formSchema: FormSchema,
    containerList?: {fieldContainers: Array<{panels: MatExpansionPanel[]}>}
  ): void {
    setTimeout(() => {
      const elements = <NodeList>document.querySelectorAll('.ng-invalid:not(form.ng-invalid)');
      if (elements.length === 0) {
        return;
      }

      if (containerList) {
        const invalidPanelIds = [];
        elements.forEach((node: HTMLElement) => {
          const parentPanel = node.closest('.mat-expansion-panel-content');
          if (parentPanel) {
            invalidPanelIds.push(parentPanel.id);
          }
        });

        containerList.fieldContainers.forEach(container => {
          container.panels.forEach(panel => {
            if (invalidPanelIds.includes(panel.id)) {
              panel.expanded = true;
            }
          });
        });
      }

      const found = Array.prototype.some.call(elements, function (element) {
        if (element.offsetHeight > 0) {
          element.parentElement.parentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });

          return true;
        }
      });

      if (!found && formSchema.availableLanguages) {
        const values = <Number[]>formSchema.availableLanguages.formControl.value;
        values.sort();

        for (let i = 0; i < values.length; i++) {
          if (formSchema.selectedLanguage.value == values[i]) {
            const languageId = values[(i + 1)];
            formSchema.selectedLanguage.setValue(languageId);
            formSchema.parentEventEmitter$.emit({forcedLanguageId: languageId});
            break;
          }
        }
        this.scrollToError(formSchema);
      }
    }, 50);
  }

  async showErrorToast(httpErrorResponse): Promise<void> {
    if (!httpErrorResponse.error) {
      this.toastController
        .create({message: this.translateService.instant('messages.errorInForm')})
        .then(toast => toast.present());
    } else if (httpErrorResponse.error.message) {
      this.toastController
        .create({message: this.translateService.instant(httpErrorResponse.error.message)})
        .then(toast => toast.present());
    } else if (!(httpErrorResponse.error instanceof Object)) { // Symfony core errors
      this.toastController.create({message: httpErrorResponse.error}).then(toast => toast.present());
    } else if (httpErrorResponse.error.type?.includes('ietf.org')) { // Error 500 message already taken care of
    } else if (httpErrorResponse.error instanceof Object) {
      let errorObject = {global: undefined};

      if (httpErrorResponse.error.global) {
        // If the response contains Symfony forms global error, remove other errors,
        // as they should have already been bound to appropriate fields
        errorObject.global = httpErrorResponse.error.global;
      } else {
        errorObject = httpErrorResponse.error;
      }

      const interval = 2000;
      for (const fieldName of Object.keys(errorObject)) {
        this.displayToasts(errorObject[fieldName], interval, 0);
      }
    }
  }

  private displayToasts(element: any, interval: number, i: number): void {
    if (typeof element === 'string') {
      setTimeout(() => {
        this.toastController
          .create({message: this.translateService.instant(element)})
          .then(toast => toast.present());
      }, interval * i);

      i++;
    } else if (Array.isArray(element)) {
      element.forEach(errorMsg => {
        setTimeout(() => {
          this.toastController
            .create({message: this.translateService.instant(errorMsg)})
            .then(toast => toast.present());
        }, interval * i);

        i++;
      });
    } else if (typeof element === 'object') {
      for (const elementKey in element) {
        this.displayToasts(element[elementKey], interval, i);
      }
    }
  }

  private sendWithFile(
    formSchema: FormSchema,
    formData: FormData,
    loading: { present?: () => Promise<void>; dismiss: any; },
    progress: { present?: () => Promise<void>; dismiss: any; },
    showErrorToast: boolean,
    showSuccessToast: boolean
  ): Promise<any> {
    this.timeStamp = Date.now();

    return new Promise((resolve, reject) => {
      this.http
        .post(formSchema.url, formData, {reportProgress: true, observe: 'events'})
        .subscribe({
          next: async resp => {
            if (resp.type === HttpEventType.UploadProgress) {
              const eta = await this.eta(resp.loaded, resp.total);
              const percent = await this.progress(resp.loaded, resp.total);
              const total = this.convertSize(resp.total);
              const progressData = {percentDone: percent, eta: eta, total: total};
              this.progressUpload.next(progressData);
            } else if (resp instanceof HttpResponse) {
              progress.dismiss();
              loading.dismiss();
              resolve(resp.body);
            }
          },
          error: httpErrorResponse => {
            bindError(formSchema.formControl, httpErrorResponse.error, this.translateService);

            if (showErrorToast) {
              this.showErrorToast(httpErrorResponse).then();
              httpErrorResponse.toastAlreadyDisplayed = true;
            }

            this.scrollToError(formSchema);
            progress.dismiss();
            loading.dismiss();
            reject(httpErrorResponse);
          }
        });
    });
  }

  private async progress(loaded: number, total: number): Promise<number> {
    return Math.round((100 * loaded) / total);
  }

  private async eta(loaded: number, total: number): Promise<string> {
    const time = Date.now() - this.timeStamp;
    const chunk = total - loaded;
    const speed = loaded / (time / 1000);
    const eta = Math.round(chunk / speed);

    return moment.utc(eta * 1000).format('mm:ss');
  }

  private send(formSchema: FormSchema, formValue, loading, showErrorToast, showSuccessToast): Promise<any> {
    const options = formSchema.format?.asPdf || formSchema.format?.asXlsx
      ? {responseType: 'blob', observe: 'response'}
      : {};

    return new Promise((resolve, reject) => {
      this.http
        .post(formSchema.url, formValue, options)
        .subscribe({
          next: async resp => {
            loading.dismiss();
            if (showSuccessToast && resp.success) {
              this.toastController
                .create({message: this.translateService.instant(resp.message)})
                .then(toast => toast.present());
            }
            resolve(resp);
          },
          error: httpErrorResponse => {
            bindError(formSchema.formGroup, httpErrorResponse.error, this.translateService);

            if (showErrorToast) {
              this.showErrorToast(httpErrorResponse).then();
              httpErrorResponse.toastAlreadyDisplayed = true;
            }

            this.scrollToError(formSchema);
            loading.dismiss();
            reject(httpErrorResponse);
          }
        });
    });
  }

  private buildFormData(formData, data, parentKey) {
    let isAnyFileInData = false;
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data && data.constructor && data.constructor.name === 'File')
    ) {
      Object.keys(data).forEach(key => {
        isAnyFileInData =
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          ) || isAnyFileInData;
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
      if (data && data.constructor && data.constructor.name === 'File') {
        isAnyFileInData = true;
      }
    }

    return isAnyFileInData;
  }

  private async httpLoad(formSchema: FormSchema): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadingService.loading = true;

      this.http
        .get(formSchema.url, {
          params: {
            onlySchema: true,
            ...(formSchema.additionalData || {})
          }
        })
        .subscribe({
          next: resp => {
            this.mergeFormData(resp, formSchema);
            this.formBuilder.build(formSchema);
          },
          error: err => {
            this.loadingService.loading = false;
            this.showErrorToast(err);
            reject(err);
          },
          complete: () => {
            this.loadingService.loading = false;
            resolve(formSchema);
          }
        });
    });
  }
}
