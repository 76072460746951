import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { LanguagePipe } from '../pipe/language.pipe';
import { UserStorageService } from '../services/user-storage.service';
import { EnvService } from '../services/env.service';
import { ModeService } from '../services/mode.service';
import { Mode } from '../enum/mode';
import { LinkService } from '../services/link.service';

@Injectable()
export class GuardService implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private userStorage: UserStorageService,
    private languagePipe: LanguagePipe,
    private envService: EnvService,
    private modeService: ModeService,
    private linkService: LinkService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const expectedRole = route.data.expectedRole;

    if (!this.userStorage.isLoggedIn) {
      this.userStorage.redirectUrl = state.url;
      const path = this.getLoginPagePath();

      return this.router.parseUrl(this.languagePipe.transform(path));
    } else if (expectedRole && !this.userStorage.hasAccess(expectedRole)) {
      const path = '/';

      return this.router.parseUrl(path);
    }

    return true;
  }

  getLoginPagePath(): string {
    const [mobileLoginPath, startLoginMode, loginPath] = [
      this.envService.get('mobileLoginPath'),
      this.envService.get('startLoginMode'),
      this.envService.get('loginPath')
    ];

    return [this.modeService.mode, startLoginMode].includes(Mode.participant)
      ? mobileLoginPath
      : loginPath;
  }

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  public getLoginPageLink(): string {
    return this.linkService.getUrl({ path: this.getLoginPagePath() });
  }

  public getParticipantLoginPageLink(): string {
    this.modeService.setMode(Mode.participant).then();

    return this.linkService.getUrl({ path: this.envService.get('mobileLoginPath') });
  }
}
