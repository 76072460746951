import { Component } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  host: {'class': 'form-input'},
})
export class LabelComponent extends InputAbstract {}
