import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { interval } from 'rxjs';
import * as moment from 'moment';
import 'moment-duration-format';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  @Input() time: number;
  @Output() timeEnd = new EventEmitter();
  public timeToEnd = 0;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.timeToEnd = this.time;
    const subscription = interval(1000).subscribe(() => {
      this.timeToEnd--;
      this.cd.markForCheck();
      if (this.timeToEnd === 0) {
        this.timeEnd.emit();
        subscription.unsubscribe();
      }
    });
  }

  getTimeToEnd() {
    let timeAsString;
    if (this.timeToEnd > 3600) {
      timeAsString = (<any>moment.duration(this.timeToEnd * 1000)).format(
        'HH:mm:ss',
        {
          trim: false
        }
      );
    } else {
      timeAsString = (<any>moment.duration(this.timeToEnd * 1000)).format(
        'mm:ss',
        {
          trim: false
        }
      );
    }

    return timeAsString;
  }
}
