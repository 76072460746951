<ng-container *ngIf="isVisible">
  <ng-container *ngIf="!formSchema.attr.toggle">
    <label
      *ngIf="formSchema.attr.labelPosition === 'top'"
      [innerHTML]="formSchema.consent || formSchema.label"
      class="width-max-content"
    ></label>

    <mat-checkbox
      [disabled]="formSchema.disabled || formSchema.parent?.attr?.readonly"
      [checked]="checked"
      (change)="materialInputChange($event)"
    >
      <span *ngIf="formSchema.attr.labelPosition !== 'top'" [innerHTML]="formSchema.consent || formSchema.label"></span>
    </mat-checkbox>
  </ng-container>

  <ng-container *ngIf="formSchema.attr.toggle">
    <label
      *ngIf="formSchema.attr.labelPosition === 'top'"
      [innerHTML]="formSchema.consent || formSchema.label"
      class="width-max-content"
    ></label>

    <mat-slide-toggle
      [disabled]="formSchema.disabled || formSchema.parent?.attr?.readonly"
      [checked]="checked"
      (change)="materialInputChange($event)"
    >
      <span *ngIf="formSchema.attr.labelPosition !== 'top'" [innerHTML]="formSchema.consent || formSchema.label"></span>
    </mat-slide-toggle>
  </ng-container>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
