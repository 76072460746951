import { EventEmitter, Injectable } from '@angular/core';
import { BasicSystemInfo, InstanceService } from '@frontend/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  _title = new EventEmitter<string>();
  title: string = null;
  translate: boolean;

  constructor(
    private instanceService: InstanceService,
  ) {}

  setTitle(title: string, translate: boolean = true): void {
    this.translate = translate;
    this._title.emit(title);
    setTimeout(() => (this.title = title));
  }

  get instanceTitle(): Observable<string> {
    return this.instanceService.instance().pipe(
      map((data: BasicSystemInfo) => data.instance.name)
    );
  }
}
