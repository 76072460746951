import { Injectable } from '@angular/core';
import { BasicBuilder } from './builders/basic-builder';
import { CheckboxBuilder } from './builders/checkbox-builder';
import { RadioBuilder } from './builders/radio-builder';
import { SelectBuilder } from './builders/select-builder';
import { ComponentDefinition } from '../interface/component-definition';
import { ButtonComponent } from '../component/inputs/button/button.component';
import { InputComponent } from '../component/inputs/input/input.component';
import { InputPasswordComponent } from '../component/inputs/input-password/input-password.component';
import { InputSelectComponent } from '../component/inputs/input-select/input-select.component';
import { InputCollectionComponent } from '../component/inputs/input-collection/input-collection.component';
import { InputTextareaComponent } from '../component/inputs/input-textarea/input-textarea.component';
import { InputCheckboxComponent } from '../component/inputs/input-checkbox/input-checkbox.component';
import { InputRadioComponent } from '../component/inputs/input-radio/input-radio.component';
import { InputDynamicChoiceComponent } from '../component/inputs/input-dynamic-choice/input-dynamic-choice.component';
import { InputHtmlSummernoteComponent } from '../component/inputs/input-html-summernote/input-html-summernote.component';
import { InputHiddenComponent } from '../component/inputs/input-hidden/input-hidden.component';
import { InputDateComponent } from '../component/inputs/input-date/input-date.component';
import { LabelComponent } from '../component/inputs/label/label.component';
import { InputRatingComponent } from '../component/inputs/input-rating/input-rating.component';
import { InputNumberComponent } from '../component/inputs/input-number/input-number.component';
import { InputDateTimeComponent } from '../component/inputs/input-date-time/input-date-time.component';
import { InputTagsComponent } from '../component/inputs/input-tags/input-tags.component';
import { InputRawFileComponent } from '../component/inputs/input-raw-file/input-raw-file.component';
import { InputColorPickerComponent } from '../component/inputs/input-color-picker/input-color-picker.component';
import { SubmitWithExampleComponent } from '../component/inputs/submit-with-example/submit-with-example.component';
import { InputTimeComponent } from '../component/inputs/input-time/input-time.component';
import { InputBusinessHoursComponent } from '../component/inputs/input-business-hours/input-business-hours.component';
import { InputEmailComponent } from '../component/inputs/input-email/input-email.component';
import { InputPhoneNumberComponent } from '../component/inputs/input-phone-number/input-phone-number.component';
import { PaginatorComponent } from '../component/inputs/paginator/paginator.component';
import { InputParticipantAccommodationChoiceComponent } from '../component/inputs/input-participant-accommodation-choice/input-participant-accommodation-choice.component';
import { InputParticipantHotelNightComponent } from '../component/inputs/input-participant-hotel-night/input-participant-hotel-night.component';
import { InputMeetingServicesComponent } from '../component/inputs/input-meeting-services/input-meeting-services.component';
import { InputDateTimeTextComponent } from '../component/inputs/input-date-time-text/input-date-time-text.component';

@Injectable({providedIn: 'root'})
export class InputTypeList {
  private list = <ComponentDefinition[]>[
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\SubmitType',
      component: ButtonComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\SubmitType',
      component: ButtonComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\SubmitWithExampleType',
      component: SubmitWithExampleComponent,
      builder: BasicBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\TextType',
      component: InputComponent,
      builder: BasicBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\NumberType',
      component: InputNumberComponent,
      builder: BasicBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\PasswordType',
      component: InputPasswordComponent,
      builder: BasicBuilder
    },
    {
      type: 'Symfony\\Bridge\\Doctrine\\Form\\Type\\EntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\EntityTypeWithEmptyLabel',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\FormEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\GroupEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\CompanyFileEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\MeetingEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\MeetingStageEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\ConsentEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\WebsiteMenuEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\UserFilesEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\WebsiteCategoryEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\WebsiteCategoryTypeFilterType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\WebsiteCategoryTypeListType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\WebsiteCategoryTypeTabType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\WebsiteSnippetCategoryType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\MoodleCourseEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\MoodleCoursesEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\WebsiteCoursePageEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\ChoiceType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\EnumType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\CollectionType',
      component: InputCollectionComponent,
      hasDynamicChildren: true,
      builder: BasicBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\TextareaType',
      component: InputTextareaComponent,
      builder: BasicBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\CheckboxType',
      component: InputCheckboxComponent,
      builder: CheckboxBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\CheckboxType',
      component: InputCheckboxComponent,
      builder: CheckboxBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\ConsentCheckboxType',
      component: InputCheckboxComponent,
      builder: CheckboxBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\RadioType',
      component: InputRadioComponent,
      builder: RadioBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\DynamicChoiceType',
      component: InputDynamicChoiceComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\HtmlType',
      component: InputHtmlSummernoteComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\LabelType',
      component: LabelComponent,
      builder: BasicBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\HiddenType',
      component: InputHiddenComponent,
      builder: BasicBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\DateType',
      component: InputDateComponent,
      builder: BasicBuilder
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\DateTimeType',
      component: InputDateTimeComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\RatingType',
      component: InputRatingComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\FileType',
      component: InputRawFileComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\TagsType',
      component: InputTagsComponent,
      builder: SelectBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\ColorPickerType',
      component: InputColorPickerComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\MeetingTypeEntityType',
      component: InputSelectComponent,
      builder: SelectBuilder,
      hasDynamicChildren: true
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\TimeType',
      component: InputTimeComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\BusinessHoursType',
      component: InputBusinessHoursComponent,
      builder: BasicBuilder,
      hasDynamicChildren: true
    },
    {
      type: 'Symfony\\Component\\Form\\Extension\\Core\\Type\\EmailType',
      component: InputEmailComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\PhoneNumberType',
      component: InputPhoneNumberComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Website\\CommonFormType\\SpeakerEntityType',
      component: InputSelectComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\PageBreakType',
      component: PaginatorComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Accommodation\\AccommodationChoiceType',
      component: InputParticipantAccommodationChoiceComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Accommodation\\AccommodationEditType',
      component: InputParticipantHotelNightComponent,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\MeetingServicesType',
      component: InputMeetingServicesComponent,
      hasDynamicChildren: true,
      builder: SelectBuilder
    },
    {
      type: 'App\\InternalMessage\\Form\\InternalMessageFormType',
      hasContainers: true,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\FlatDynamicType',
      component: InputCollectionComponent,
      hasDynamicChildren: true,
      builder: BasicBuilder
    },
    {
      type: 'App\\Form\\FormType\\Common\\DateTimeTextType',
      component: InputDateTimeTextComponent,
      builder: BasicBuilder
    },
  ];

  public findComponent(type: string): ComponentDefinition {
    const element = this.list.filter(e => type === e.type).pop();
    if (!element) {
      return null;
    }

    return element;
  }

  public addComponentDefinition(component: ComponentDefinition) {
    this.list.push(component);
  }

  public addComponentsDefinition(component: ComponentDefinition[]) {
    this.list.push(...component);
  }
}
