import { EventEmitter, Injectable } from '@angular/core';
import { LinkService, Link } from '@frontend/core';
import { Params, Router } from '@angular/router';
import { App } from '@capacitor/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BackService {
  link$ = new EventEmitter<string>();
  lastLink?: Link = null;
  lastLinkUrl?: string = null;
  beforeBack?: () => Promise<boolean>;
  linkHistory = [];

  constructor(
    private linkService: LinkService,
    private route: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.systemBackListener();
    this.linkService.url.subscribe(v => this.linkHistory.push(v));
  }

  systemBackListener() {
    App.addListener('backButton', () => this.goBackClick());
  }

  async goBackClick($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      $event.stopPropagation();
    }
    let canGoBack = true;
    if (this.beforeBack) {
      canGoBack = await this.beforeBack();
    }

    if (canGoBack) {
      this.goBack();
    }
  }

  async setUrl(link?: Link, linkAsString?: string, beforeBack?: () => Promise<boolean>) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['noback'] === '1') {
        this.link$.emit(null);
      } else {
        this.setBackUrl(link, linkAsString, beforeBack);
      }
    })
  }

  setBackUrl(link?: Link, linkAsString?: string, beforeBack?: () => Promise<boolean>): void {
    setTimeout(() => {
      let newUrl = null;
      if (link !== null) {
        newUrl = this.linkService.getUrl(link);
      } else if (typeof linkAsString !== 'undefined' && linkAsString !== null) {
        const linkFromString: Link = {
          path: linkAsString
        };
        newUrl = this.linkService.getUrl(linkFromString);
      }

      this.link$.emit(newUrl);
      this.lastLink = link;
      this.lastLinkUrl = newUrl;
      this.beforeBack = beforeBack;
    });
  }

  goBack() {
    let link = this.lastLinkUrl;
    this.linkHistory.pop();
    if (this.linkHistory.length >= 1) {
      link = this.linkHistory.pop();
    }

    this.route.navigateByUrl(link).then();
  }

  clear() {
    this.lastLinkUrl = '/';
    this.linkHistory = [];
  }
}
