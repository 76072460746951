import {LinkList} from '../../link-list/link-list';
import {MenuListSection} from '../../../module/menu/interface/menu-list-item';

export const ParticipantMenu: MenuListSection[] = [
  {
    displayTitle: false,
    menuListItems: [
      {link: LinkList.participant.meetingList}
    ]
  }
];
