import { CustomOptions, FormSchema } from '@frontend/form';
import cloneDeep from 'lodash-es/cloneDeep';

export class MultiLanguage extends CustomOptions {
  protected init(): void {
    (<any>window).schema = this.globalSchema;

    if (this.globalSchema.selectedLanguage !== undefined) {
      this.globalSchema.selectedLanguage.valueChanges.subscribe(val => this.toggleTranslations(val));
    }

    this.validatedField.options.allow_add = false;

    if (this.globalSchema.availableLanguages !== undefined) {
      this.updateTranslations(this.globalSchema.availableLanguages.formControl.value);

      this.globalSchema.availableLanguages.formControl.valueChanges
        .subscribe((valueChanges: Array<any>) => this.updateTranslations(valueChanges));
    }
  }

  private updateTranslations(newLanguageIds: Array<string>): void {
    const languageFieldName = this.payload['name'];

    const existingLanguageIds = <string[]>(this.validatedField.children
      ? this.validatedField.children.map(field => field.formControl.get(languageFieldName).value)
      : []);

    const noInitialTranslations = !!existingLanguageIds;

    newLanguageIds.forEach(newLanguageId => {
      if (!existingLanguageIds.includes(newLanguageId)) {
        this.cloneFields(languageFieldName, newLanguageId);
        existingLanguageIds.push(newLanguageId);
      }
    });

    existingLanguageIds.forEach(existingLanguageId => {
      if (!newLanguageIds.includes(existingLanguageId)) {
        const rowsToRemove = this.validatedField.children
          .filter((control: FormSchema) => control.formControl.get(languageFieldName).value === existingLanguageId)
          .pop();
        this.controlBuilder.remove(this.validatedField, rowsToRemove);
      }
    });

    if (noInitialTranslations) {
      this.toggleTranslations(newLanguageIds[0])
    }
  }

  private cloneFields(languageFieldName: string, val: string): void {
    const input = cloneDeep(this.validatedField.formPrototype);
    input.forEach((control: FormSchema) => {
      if (control.key === languageFieldName) {
        control.value = val;
      }
    });

    const formSchema = <FormSchema>{
      children: input
    };

    this.controlBuilder.create([formSchema], this.validatedField, this.globalSchema);
  }

  private toggleTranslations(languageId: string): void {
    this.validatedField.children?.forEach((control: FormSchema) => {
      const isHidden = control.formControl.get(this.payload['name']).value != languageId

      control.attr = { ...control.attr, hidden: isHidden };
    });
  }
}
