<div *ngIf="isVisible">
  <div
    [ngClass]="{
      'ng-invalid': formSchema.formControl.invalid,
      'ng-touched': formSchema.formControl.touched,
      'ng-dirty': formSchema.formControl.dirty,
      'ng-valid': formSchema.formControl.valid
    }"
  >
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <div
      class="file-input"
      [ngClass]="[formSchema.attr ? formSchema.attr.class || '' : '']"
    >
      <ng-container *ngIf="globalForm.initialsAvatarData">
        <app-initials-avatar-generator
          *ngIf="file.imageSrc || (globalForm.initialsAvatarData.seed && globalForm.initialsAvatarData.seed !== ' ')"
          [avatarSrc]="file.imageSrc"
          [colorSeed]="globalForm.initialsAvatarData.seed"
          [wordArray]="globalForm.initialsAvatarData.words"
          [firstName]="globalForm.initialsAvatarData.firstName"
          [lastName]="globalForm.initialsAvatarData.lastName"
        ></app-initials-avatar-generator>
        <app-initials-avatar-generator
          *ngIf="!file.imageSrc && !(globalForm.initialsAvatarData.seed && globalForm.initialsAvatarData.seed !== ' ')"
          [initials]="'?'"
          [bgColor]="'lightgray'"
        ></app-initials-avatar-generator>
      </ng-container>

      <ng-container *ngIf="!globalForm.initialsAvatarData && file.fileName">
        <div [class.circle]="!file.imageSrc">
          <ng-container *ngIf="file.imageSrc; else fileLinkOrIcon">
            <img
              [src]="file.imageSrc"
              (click)="openDialog()"
              class="image"
              style="cursor: pointer;"
              alt="uploaded image"
            >
          </ng-container>
          <ng-template #fileLinkOrIcon>
            <a *ngIf="file.downloadLink; else justIcon" [href]="file.downloadLink" target="_blank">
              <mat-icon>insert_drive_file</mat-icon>
            </a>
            <ng-template #justIcon>
              <mat-icon>insert_drive_file</mat-icon>
            </ng-template>
          </ng-template>
        </div>
      </ng-container>

      <button
        mat-flat-button
        type="button"
        class="upload-button mat-blue"
        [disabled]="formSchema.disabled"
        (click)="selectFile()"
      >
        {{ 'messages.upload' | translate }}
      </button>

      <div class="fileName">
        {{ file.fileName }}
      </div>
      <button
        *ngIf="showClearButton"
        type="button"
        mat-icon-button
        [disabled]="formSchema.disabled"
        class="button-clear"
        slot="end"
        color="danger"
        (click)="clearFile()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <input #fileInput type="file" (change)="onFileChange($event)">
  </div>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</div>
