import { Component, Input, OnInit } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input-color-picker',
  templateUrl: './input-color-picker.component.html',
  host: {'class': 'form-input'},
})
export class InputColorPickerComponent extends InputAbstract implements OnInit {
  @Input() addPlaceholder = true;
  @Input() addClass = '';
  color = '#fff';

  ngOnInit(): void {
    super.ngOnInit();

    this.setValue();
    this.formSchema.formControl.valueChanges.subscribe(() => this.setValue());
  }

  update(): void {
    this.formSchema.formControl.setValue(this.color);
  }

  private setValue(): void {
    this.color = this.formSchema.formControl.value;
  }
}
