<div class="d-flex spaced-items">
  <button
    *ngIf="fileBase64.length > 0"
    type="button"
    mat-flat-button
    class="download mat-green"
    (click)="download()"
  >
    <mat-icon>get_app</mat-icon>
    {{ 'messages.getExample' | translate }}
  </button>

  <button mat-flat-button class="mat-blue">
    <mat-icon>save</mat-icon>
    {{ 'messages.import' | translate }}
  </button>
</div>
