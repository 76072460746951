import { Injectable } from '@angular/core';
import * as QuillNamespace from 'quill';
import { QuillEditorComponent } from 'ngx-quill';
import { html_beautify } from 'js-beautify';

const Quill: any = QuillNamespace;

@Injectable({
  providedIn: 'root'
})
export class QuillHelperService {
  getConfigWithTag() {
    const config = this.getDefaultConfig();
    config.toolbar.container.push(['content']);

    return config;
  }

  getDefaultConfig() {
    return {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          // ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'], // remove formatting button

          ['link', 'image'], // link and file, video

          ['html']
        ]
      }
    };
  }

  insertTag(tag: string, position: number = null, quill: QuillEditorComponent) {
    if (position === null) {
      position = tag.length;
    }
    quill.placeholder = '';
    quill.quillEditor.focus();
    const cursorIndex = quill.quillEditor.getSelection(true).index;
    quill.quillEditor.enable(false);
    quill.quillEditor.enable(true);
    quill.quillEditor.insertText(cursorIndex, tag, 'user');
    quill.quillEditor.setSelection(cursorIndex + position, 0);

    const timer = setTimeout(() => {
      quill.quillEditor.focus();
    }, 10);
  }

  switchHtmlToText(quill: QuillEditorComponent) {
    let htmlEditor = quill.editorElem.querySelector<HTMLTextAreaElement>('.ql-html-editor');

    if (htmlEditor !== null) {
      quill.quillEditor.root.innerHTML = htmlEditor.value.replace(/\n/g, "");
      quill.editorElem.removeChild(htmlEditor);
    } else {
      htmlEditor = document.createElement("textarea");
      htmlEditor.className = 'ql-editor ql-html-editor';
      htmlEditor.innerHTML = html_beautify(quill.quillEditor.root.innerHTML);

      quill.editorElem.appendChild(htmlEditor);
    }
  }

  async initQuill() {
    const AlignStyle = Quill.import('attributors/style/align');
    const AlignClassStyle = Quill.import('attributors/class/align');
    const BackgroundStyle = Quill.import('attributors/style/background');
    const ColorStyle = Quill.import('attributors/style/color');
    const DirectionStyle = Quill.import('attributors/style/direction');
    const FontStyle = Quill.import('attributors/style/font');
    const SizeStyle = Quill.import('attributors/style/size');
    const BackgroundClass = Quill.import('attributors/class/background');
    const ColorClass = Quill.import('attributors/class/color');
    const Block = Quill.import('blots/block');
    const BlockEmbed = Quill.import('blots/block/embed');
    Block.tagName = 'DIV';
    await Promise.all([
      Quill.register(AlignClassStyle, true),
      Quill.register(AlignStyle, true),
      Quill.register(BackgroundStyle, true),
      Quill.register(ColorStyle, true),
      Quill.register(DirectionStyle, true),
      Quill.register(FontStyle, true),
      Quill.register(SizeStyle, true),
      Quill.register(BackgroundClass, true),
      Quill.register(ColorClass, true),
      Quill.register(Block, true),
      Quill.register(BlockEmbed, true)
    ]);
  }
}
