import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ErrorHandlerService } from './error-handler.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UserStorageService } from './user-storage.service';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    protected http: HttpClient,
    protected errorHandler: ErrorHandlerService,
    protected userStorageService: UserStorageService,
    protected envService: EnvService
  ) {}

  request(method: string, url: string, options?): Observable<any> {
    return this.http
      .request(method, url, this.prepareHeader(options))
      .pipe(
        catchError(err => {
          this.errorHandler.handle(err);
          return throwError(err);
        })
      );
  }

  delete<T>(url: string, options?): Observable<any> {
    return this.http
      .delete<T>(url, this.prepareHeader(options))
      .pipe(
        catchError(err => {
          this.errorHandler.handle(err);
          return throwError(err);
        })
      );
  }

  get<T>(url: string, options?): Observable<any> {
    return this.http
      .get<T>(url, this.prepareHeader(options))
      .pipe(
        catchError(err => {
          this.errorHandler.handle(err);
          return throwError(err);
        })
      );
  }

  head<T>(url: string, options?): Observable<any> {
    return this.http
      .head<T>(url, this.prepareHeader(options))
      .pipe(
        catchError(err => {
          this.errorHandler.handle(err);
          return throwError(err);
        })
      );
  }

  jsonp<T>(url: string, callbackParam: string): Observable<any> {
    return this.http
      .jsonp<T>(url, callbackParam)
      .pipe(
        catchError(err => {
          this.errorHandler.handle(err);
          return throwError(err);
        })
      );
  }

  options<T>(url: string, options?): Observable<any> {
    return this.http
      .options<T>(url, this.prepareHeader(options))
      .pipe(
        catchError(err => {
          this.errorHandler.handle(err);
          return throwError(err);
        })
      );
  }

  patch<T>(url: string, body: any | null, options?): Observable<any> {
    return this.http
      .patch<T>(url, body, this.prepareHeader(options))
      .pipe(
        catchError(err => {
          this.errorHandler.handle(err);
          return throwError(err);
        })
      );
  }

  post<T>(url: string, body: any | null, options?): Observable<any> {
    return this.http
      .post<T>(url, body, this.prepareHeader(options))
      .pipe(
        catchError(err => {
          this.errorHandler.handle(err);
          return throwError(err);
        })
      );
  }

  put<T>(url: string, body: any | null, options?): Observable<any> {
    return this.http
      .put<T>(url, body, this.prepareHeader(options))
      .pipe(
        catchError(err => {
          this.errorHandler.handle(err);
          return throwError(err);
        })
      );
  }

  public prepareHeader(options) {
    if (!options) {
      options = {headers: new HttpHeaders()};
    }
    if (!options.headers) {
      options.headers = new HttpHeaders();
    }

    if (!this.envService.get('production')) {
      options.withCredentials = true;
    }

    options.headers = this.setHeaders(options.headers);

    return options;
  }

  private setHeaders(headers: HttpHeaders): HttpHeaders {
    const user = this.userStorageService.user;

    if (user) {
      headers = headers.set('Authorization', `Bearer ${user.token}`);
    }

    headers = headers.set('Version', this.envService.get('version'));
    headers = headers.set('Instance', String(this.envService.get('instance')));
    headers = headers.set('Language', this.userStorageService.language);

    return headers;
  }
}
