import { AbstractControl, ValidatorFn } from '@angular/forms';

export class FileValidator {
  static validator(payload): ValidatorFn {
    const maxSize = payload.maxSize;

    return (control: AbstractControl) => {
      const file = <File>control.value;
      if (maxSize && file && file.size > maxSize) {
        const { suffix, size, i } = FileValidator.bytesToSize(maxSize);
        return {
          file: {
            size: (file.size / 1000 ** i).toFixed(1),
            limit: size,
            suffix: suffix
          }
        };
      }

      return null;
    };
  }

  static bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0)
      return {
        suffix: 'NA',
        size: 'NA',
        i: 0
      };
    const i = parseInt(<any>Math.floor(Math.log(bytes) / Math.log(1000)), 10);
    if (i === 0)
      return {
        suffix: `${sizes[i]}`,
        size: bytes,
        i: 0
      };
    return {
      suffix: sizes[i],
      size: `${(bytes / 1000 ** i).toFixed(1)}`,
      i: i
    };
  }
}
