import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScoreService {
  generateScoreView(points, maxPoints, passLimit, customMessage = ''): string {
    const percentage = Math.floor((points / maxPoints) * 100);
    const fullBoxClass = this.isPassed(points, maxPoints, passLimit)
      ? 'correct'
      : 'incorrect';

    let message = '<div class="questionnaire-score-box"><div class="questionnaire-score">';
    for (let i = 0; i < 100; i += 10) {
      const toEnd = percentage - i;
      const subWidthClass = toEnd > 10 ? 'sub-10' : toEnd > 0 ? 'sub-' + toEnd : '';
      message += `<div class="box ${i < percentage ? fullBoxClass : ''} ${subWidthClass}" ></div>`;
    }

    message += `</div>${customMessage} <span class="percentage">${percentage}%</span></div>`;

    return message;
  }

  isPassed(points: number, maxPoints: number, passLimit: number): boolean {
    const percentage = Math.floor((points / maxPoints) * 100);

    return percentage >= passLimit;
  }
}
