import { Injectable, OnDestroy } from '@angular/core';
import { Link, LinkService, Mode, ModeService, UserService, UserStorageService } from '@frontend/core';
import { LinkList } from '../../link-list/link-list';
import { MobileBeforeLoginMenu } from '../menu-config/mobile-before-login';
import { UserMenu } from '../menu-config/user-menu';
import { ParticipantMenu } from '../menu-config/participant-menu';
import { ParticipantTopMenu } from '../menu-config/participant-top-menu';
import { Observable, Subscription } from 'rxjs';
import { ReplacementService } from '../../../service/replacement.service';
import { BasicDataMeeting } from "@frontend/core";
import { TopMenuContentService } from "@frontend/core";
import { MoodleService } from "@frontend/core";
import { MenuListItem, MenuListSection } from "../../../module/menu/interface/menu-list-item";

@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnDestroy {
  public replace = new Map<string, Observable<any>>();
  public cachedValue = new Map();
  private subscribe: Subscription;

  constructor(
    private linkService: LinkService,
    private modeService: ModeService,
    private replacementService: ReplacementService,
    private userService: UserService,
    private userStorageService: UserStorageService,
    private topMenuContentService: TopMenuContentService,
    private moodleService: MoodleService,
  ) {
    this.init();
  }

  init() {
    const linkList = LinkList;

    for (const linkKey of Object.keys(linkList)) {
      this.linkService.addLink(linkKey, linkList[linkKey]);
    }

    this.setReplacements();
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  get isLoggedIn(): boolean {
    return this.modeService.isLoggedIn;
  }

  async getMenuConfig(): Promise<MenuListSection[]> {
    if (!this.userStorageService.isLoggedIn) {
      return [];
    }

    let topMenuMode = false;
    if (this.modeService.isLoggedIn && this.modeService.mode === Mode.participant) {
      topMenuMode = await this.userService.getTopMenuMode();
    }

    if (
      this.modeService.isLoggedIn
      && this.modeService.mode === Mode.participant
      && topMenuMode
    ) {
      return await this.checkWhatElementsShouldBeVisible(ParticipantTopMenu);
    } else if (this.modeService.isLoggedIn && this.modeService.mode === Mode.participant) {
      return ParticipantMenu;
    } else if (this.modeService.isLoggedIn && this.modeService.mode === Mode.user) {
      return UserMenu;
    } else if (this.modeService.isMobile && !this.modeService.isLoggedIn) {
      return MobileBeforeLoginMenu;
    }

    return [];
  }

  async checkWhatElementsShouldBeVisible(menu: MenuListSection[]): Promise<MenuListSection[]> {
    const elements = await this.userService.getNumberOfElements();
    const newMenu: MenuListItem[] = [];
    for (const menuSection of menu) {
      for (const menuElem of menuSection.menuListItems) {
        const currentElement = elements.find((elem: BasicDataMeeting) => elem.name === menuElem.content);
        if (typeof currentElement === 'undefined' || currentElement.registered > 0 || currentElement.available > 0) {
          newMenu.push(menuElem);
        }
      }
    }

    this.topMenuContentService.speakers.subscribe(data => {
      if (data.length > 0) {
        newMenu.push({
          link: <Link>{
            icon: 'person',
            name: 'messages.speakers',
            path: '/participant/top-menu-speaker/list'
          }
        })
      }
    });

    this.topMenuContentService.materialsData.subscribe(data => {
      if (data.length > 0) {
        newMenu.push({
          link: <Link>{
            path: '/participant/top-menu-materials/list',
            icon: 'folder_open',
            name: 'messages.materials'
          },
        })
      }
    });

    this.topMenuContentService.myFilesData.subscribe(data => {
      if (data.length > 0) {
        newMenu.push({
          link: <Link>{
            path: '/participant/top-menu-my-files/list',
            icon: 'folder_open',
            name: 'messages.myFiles'
          },
        })
      }
    });

    this.moodleService.isActive(enabled => {
      if (enabled) {
        newMenu.push({
          link: <Link>{
            path: 'moodle',
            icon: 'school',
            name: 'messages.lessons',
            runMethod: true,
            method: {
              methodName: 'redirect',
              serviceName: 'moodleService',
            }
          },
        })
      }
    });

    return [{menuListItems: newMenu}];
  }

  private setReplacements() {
    this.subscribe = this.linkService.url.subscribe(() => {
      const meetingId = this.linkService.params['meetingId'];
      if (meetingId !== this.cachedValue.get('meetingId')) {
        this.cachedValue.set('meetingId', meetingId);
        this.replace.set('meetingName', this.replacementService.getMeetingName());
      }
    });
  }
}
