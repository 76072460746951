import { Component, OnInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { InputAbstract } from '../../../interface/input-abstract';
import { FormSchema } from '../../../interface/form-schema';
import { ControlBuilder } from '../../../service/control-builder';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
  selector: 'app-input-collection',
  templateUrl: './input-collection.component.html',
  styleUrls: ['./input-collection.component.scss'],
})
export class InputCollectionComponent extends InputAbstract implements OnInit {
  step = -1;
  positionName = 'position';
  forceLabelDisplay = false;

  constructor(
    private customFormBuilder: ControlBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.forceLabelDisplay = this.formSchema.attr.forceLabelDisplay;
    this.positionName = this.formSchema.attr.positionName || this.positionName;
    if (this.formSchema.children && this.formSchema.attr.sortableBox) {
      this.sort();
    }
  }

  add(): void {
    const inputs = <FormSchema>{
      children: cloneDeep(this.formSchema.formPrototype)
    };

    this.customFormBuilder.create([inputs], this.formSchema, this.globalForm);
    this.index();
  }

  remove(row: FormSchema): void {
    this.customFormBuilder.remove(this.formSchema, row);
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  drop($event: CdkDragDrop<any, any>): void {
    this.setStep(-1);
    this.move(
      this.formSchema.children,
      $event.previousIndex,
      $event.currentIndex
    );

    this.index();
  }

  move(array, fromIndex, toIndex): void {
    if (fromIndex == toIndex) {
      return;
    }
    array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
  }

  stop($event) {
    $event.stopPropagation();
    return false;
  }

  index(): void {
    this.formSchema.children.forEach((val: FormSchema, idx: number) => {
      const control = val.formControl.get(this.positionName);
      if (control) {
        control.setValue(idx);
      }
    });
    this.formSchema.formControl.updateValueAndValidity();
  }

  round(number: number): number {
    return Math.round(number);
  }

  private sort(): void {
    this.formSchema.children = this.formSchema.children.sort(
      (a: FormSchema, b: FormSchema) => (
        a.formControl.get(this.positionName)?.value -
        b.formControl.get(this.positionName)?.value
      )
    );
  }
}
