import { LinkList } from '../../link-list/link-list';
import { MenuListSection } from '../../../module/menu/interface/menu-list-item';
import { MenuListMeetingsEventsComponent } from '../../../../main-options/meeting/menu-list-meetings-events/menu-list-meetings-events.component';
import { MenuListMeetingsTrainingsComponent } from '../../../../main-options/meeting/menu-list-meetings-trainings/menu-list-meetings-trainings.component';
import { MenuListMeetingsCampaignsComponent } from '../../../../main-options/meeting/menu-list-meetings-campaigns/menu-list-meetings-campaigns.component';
import { MenuListWebsitesComponent } from '../../../../main-options/website/menu-list-websites/menu-list-websites.component';

export const UserMenu: MenuListSection[] = [
  {
    menuListItems: [{link: LinkList.dashboard}]
  },
  {
    title: 'projects',
    menuListItems: [
      {
        link: LinkList.meeting.events,
        activatingPaths: [
          LinkList.meeting.eventList,
          LinkList.meeting.eventCalendar,
        ],
        component: MenuListMeetingsEventsComponent
      },
      {
        link: LinkList.meeting.trainings,
        activatingPaths: [
          LinkList.meeting.trainingList,
          LinkList.meeting.trainingCalendar,
        ],
        component: MenuListMeetingsTrainingsComponent
      },
      {
        link: LinkList.meeting.campaigns,
        activatingPaths: [
          LinkList.meeting.campaignList,
          LinkList.meeting.campaignCalendar,
          LinkList.meeting.eLearningCampaigns.event,
          LinkList.meeting.eLearningCampaigns.trigger,
        ],
        component: MenuListMeetingsCampaignsComponent
      },
      {
        link: LinkList.lessons,
        activatingPaths: [
          LinkList.lessons,
        ],
      },
      {
        link: LinkList.website.list,
        activatingPaths: [
          LinkList.website.list,
          LinkList.website.websiteTemplate.list,
          LinkList.website.websiteTemplate.edit,
          LinkList.website.websiteTemplate.create,
          LinkList.website.websiteStyle.list,
          LinkList.website.websiteStyle.edit,
          LinkList.website.websiteStyle.create,
          LinkList.website.websiteSnippet.list,
          LinkList.website.websiteSnippet.edit,
          LinkList.website.websiteSnippet.create,
        ],
        component: MenuListWebsitesComponent
      },
      {
        link: LinkList.internalMessages.list,
        activatingPaths: [
          LinkList.internalMessages.list,
        ]
      },
    ],
  },
  {
    title: 'resources',
    menuListItems: [
      {
        link: LinkList.profile.list,
        activatingPaths: [
          LinkList.profile.list,
          LinkList.profile.user.list,
          LinkList.profile.user.edit,
          LinkList.profile.passport.list,
          LinkList.profile.passport.edit,
          LinkList.profile.participant.list,
          LinkList.profile.participant.edit.main,
          LinkList.profile.participant.edit.messages,
          LinkList.profile.participant.edit.questionnaire,
          LinkList.profile.participant.edit.meetings,
        ]
      },
      {
        link: LinkList.group.list,
        activatingPaths: [
          LinkList.group.create,
          LinkList.group.edit,
        ]
      },
      {
        link: LinkList.confirmation.list,
        activatingPaths: [
          LinkList.confirmation.list,
          LinkList.confirmation.all.list,
          LinkList.confirmation.tasks.list,
        ]
      },
      {
        link: LinkList.substitution.list,
        activatingPaths: [
          LinkList.substitution.list,
          LinkList.substitution.create,
          LinkList.substitution.edit,
        ]
      },
      {
        link: LinkList.myParticipants.list,
        activatingPaths: [
          LinkList.myParticipants.list,
        ]
      },
      {
        link: LinkList.speaker.list,
        activatingPaths: [
          LinkList.speaker.list,
          LinkList.speaker.create,
          LinkList.speaker.edit,
          LinkList.speaker.import,
        ],
        newSection: true
      },
      {
        link: LinkList.form.list,
        activatingPaths: [
          LinkList.form.list,
          LinkList.form.create,
          LinkList.form.edit,
          LinkList.form.preview,
          LinkList.form.dispatch,
          LinkList.form.submission.list,
        ]
      },
      {
        link: LinkList.companyFile.list,
        activatingPaths: [
          LinkList.companyFile.list,
          LinkList.companyFile.create,
          LinkList.companyFile.edit,
        ]
      },
      {
        link: LinkList.template.tab,
        activatingPaths: [
          LinkList.template.email.list,
          LinkList.template.email.create,
          LinkList.template.email.edit,
          LinkList.template.contract.list,
          LinkList.template.contract.create,
          LinkList.template.contract.edit,
          LinkList.template.emailBuilder.create,
          LinkList.template.emailBuilder.edit,
          LinkList.template.emailBuilder.list,
          LinkList.template.independentEmail.create,
          LinkList.template.independentEmail.edit,
          LinkList.template.independentEmail.list,
        ]
      },
      {
        link: LinkList.organizer.list,
        activatingPaths: [
          LinkList.organizer.list,
          LinkList.organizer.create,
          LinkList.organizer.edit,
        ]
      },
      {
        link: LinkList.report.tab,
      },
      {
        link: LinkList.companySettings.tab,
        activatingPaths: [
          LinkList.companySettings.edit,
          LinkList.profileField.edit,
          LinkList.meetingServiceCategory.list,
          LinkList.meetingServiceCategory.create,
          LinkList.meetingServiceCategory.edit,
          LinkList.line.create,
          LinkList.line.edit,
          LinkList.line.list,
          LinkList.region.list,
          LinkList.region.edit,
          LinkList.region.create,
          LinkList.meetingTag.list,
          LinkList.meetingTag.edit,
          LinkList.meetingTag.create,
          LinkList.fileCategory.create,
          LinkList.fileCategory.edit,
          LinkList.fileCategory.list,
          LinkList.meetingPath.create,
          LinkList.meetingPath.edit,
          LinkList.meetingPath.list,
          LinkList.ldapConfig.edit,
          LinkList.paymentConfig.edit,
          LinkList.consent.list,
          LinkList.consent.edit,
          LinkList.consent.create,
          LinkList.specialtyGroup.list,
          LinkList.specialtyGroup.edit,
          LinkList.specialtyGroup.create,
          LinkList.externalRole.list,
          LinkList.externalRole.edit,
          LinkList.externalRole.create,
          LinkList.companyUserMeetingLimit.list,
          LinkList.companyUserMeetingLimit.edit,
          LinkList.companyUserMeetingLimit.create,
          LinkList.ldapConfig.edit,
          LinkList.roleAllowedGroup.edit,
          LinkList.dashboardConfig.edit,
          LinkList.mailingConfig.edit,
          LinkList.contractConfig.edit,
          LinkList.cronConfig.list,
          LinkList.cronConfig.create,
          LinkList.cronConfig.edit,
          LinkList.variable.list,
          LinkList.variable.create,
          LinkList.variable.edit,
        ]
      }
    ]
  },
  {
    title: 'settings',
    menuListItems: [
      {
        link: LinkList.configuration.tab,
        newSection: true,
        activatingPaths: [
          LinkList.configuration.company.list,
          LinkList.configuration.company.create,
          LinkList.configuration.hotel.list,
          LinkList.configuration.hotel.edit,
          LinkList.configuration.hotel.create,
          LinkList.configuration.role.create,
          LinkList.configuration.role.edit,
          LinkList.configuration.role.list,
          LinkList.configuration.instance.list,
          LinkList.configuration.instance.create,
          LinkList.configuration.instance.edit,
          LinkList.configuration.instance.consent.list,
          LinkList.configuration.instance.consent.edit,
          LinkList.configuration.instance.consent.create,
          LinkList.configuration.instance.languageRegion.list,
          LinkList.configuration.instance.languageRegion.edit,
          LinkList.configuration.instance.languageRegion.create,
          LinkList.configuration.advanced.command,
        ],
      }
    ]
  },
];
