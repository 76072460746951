import { Injectable, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { QuillModule } from 'ngx-quill';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule, createTranslateLoader, TokenInterceptor } from '@frontend/core';
import { LoadingService } from '@frontend/form';
import { MaterialWrapperModule } from '@frontend/material-wrapper';
import { environment } from '../environments/environment';
import { RootComponent } from './root.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ChatService } from './chat/service/chat.service';
import { ChatStorageService } from './chat/service/chat-storage.service';
import { ChatJoinService } from './chat/service/chat-join.service';
import { ChatLocalNotificationService } from './chat/service/chat-local-notification.service';
import { NotFoundComponent } from './layout/components/not-found/not-found.component';
import { MenuService } from './layout/link/menu/service/menu.service';
import { BackService } from './layout/link/menu/service/back.service';
import { ErrorPresenterService } from './layout/error/error-presenter.service';
import { AutologinComponent } from './layout/components/autologin/autologin.component';
import { DesktopRedirectComponent } from './layout/components/desktop-redirect/desktop-redirect.component';
import { LogoutComponent } from './layout/components/logout/logout.component';
import { UnsubscribeComponent } from './layout/components/unsubscribe/unsubscribe.component';
import { CompleteRegistrationComponent } from './layout/components/complete-registration/complete-registration.component';

import 'hammerjs';

@Injectable({
  providedIn: 'root'
})
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': { enable: false },
    'rotate': { enable: false },
    'pan': { enable: false }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    NotFoundComponent,
    AutologinComponent,
    DesktopRedirectComponent,
    LogoutComponent,
    UnsubscribeComponent,
    CompleteRegistrationComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule.forRoot(environment),
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    FullCalendarModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [Injector]
      },
    }),
    MaterialWrapperModule,
    HammerModule,
    MatDialogModule,
    NgbModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    MenuService,
    ErrorPresenterService,
    LoadingService,
    FileOpener,
    ChatService,
    ChatStorageService,
    ChatJoinService,
    ChatLocalNotificationService,
    BackService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    }
  ],
  bootstrap: [RootComponent]
})
export class AppModule {
}
