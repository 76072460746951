<ng-container *ngIf="isVisible">
  <mat-form-field [class]="formSchema.attr?.class">
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <mat-label *ngIf="!isFromEntity">{{ formSchema.label }}</mat-label>

    <input
      #input
      matInput
      placeholder="{{ formSchema.label }}"
      [ngClass]="addClass"
      (focus)="focusEvent.emit($event)"
      [type]="formSchema.attr?.type ?? 'text'"
      [formControl]="$any(formSchema.formControl)"
      [readonly]="formSchema.attr?.readonly ?? false"
      [attr.min]="formSchema.attr?.min"
      [attr.max]="formSchema.attr?.max"
      [minlength]="formSchema.attr?.minlength"
      [maxlength]="formSchema.attr?.maxlength"
      [step]="formSchema.attr?.step"
      [tabindex]="formSchema.attr?.class === 'soft-disable' ? -1 : 0"
    >
  </mat-form-field>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
