import { FormControl } from '@angular/forms';
import { CustomOptions } from '@frontend/form';

export class GlobalLanguageSelector extends CustomOptions {
  protected init() {
    this.globalSchema.availableLanguages = this.validatedField;
    this.globalSchema.selectedLanguage = new FormControl(this.validatedField.formControl.value[0]);

    if (this.validatedField.choices.length === 1) {
      this.validatedField.attr = {...this.validatedField.attr, hidden: true};
    }
  }
}
