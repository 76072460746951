import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input-tags',
  templateUrl: './input-tags.component.html',
  host: {'class': 'form-input'},
})
export class InputTagsComponent extends InputAbstract implements OnInit {
  list = [];
  validatorMessage: string;

  @ViewChild('ngSelect') ngSelect: NgSelectComponent;

  constructor(
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    const initValues = <string[]>this.formSchema.formControl.value;
    if (initValues) {
      initValues.forEach(value => {
        this.list.push(this.add(value));
      });
    }

    this.validatorMessage = this.formSchema.options?.tagValidator ?? 'invalidTag';
  }

  onAdd($event): void {
    $event.valid = this.isTagValid($event.value);
    this.validate();
  }

  onChange(): void {
    this.updateValue(this.list);
  }

  add($event: string) {
    return {
      value: $event,
      valid: true
    };
  }

  clear(item: any): void {
    const index = this.list.findIndex(value => value.value === item.value);
    this.list.splice(index, 1);
    this.list = [...this.list];
    this.updateValue(this.list);
    this.cd.markForCheck();
  }

  onFocusOut(event: any): void {
    const path = event.path || (event.composedPath && event.composedPath());
    if (path[0].value) {
      this.ngSelect.element.classList.add('ng-select-focused');
    }
  }

  private isTagValid(value: string): boolean {
    switch (this.formSchema.options?.tagValidator) {
      case 'email':
        return this.isEmailValid(value);
      case 'domain':
        return this.isDomainValid(value);
    }

    return true;
  }

  private isEmailValid(email: string): boolean {
    const re = /\S+@\S+\.\S+/;

    return re.test(String(email).toLowerCase());
  }

  private isDomainValid(domain: string): boolean {
    const re = /\S{2,}\.\S{2,}/;

    return re.test(String(domain));
  }

  private updateValue(values: Array<any>): void {
    this.formSchema.formControl.setValue(values.map(value => value.value));
  }

  private validate(): void {
    if (!this.list.every(value => value.valid)) {
      this.formSchema.formControl.setErrors({[this.validatorMessage]: true});
      this.formSchema.formControl.markAsTouched();
    }
  }
}
