export const Actions = {
  CanLoginAsParticipant: 'CanLoginAsParticipant',
  CanLoginAsUser: 'CanLoginAsUser',
  CanListParticipants: 'CanListParticipants',
  CanEditParticipants: 'CanEditParticipants',
  CanDeleteParticipants: 'CanDeleteParticipants',
  CanAddParticipants: 'CanAddParticipants',
  CanListParticipantProfiles: 'CanListParticipantProfiles',
  CanEditParticipantProfiles: 'CanEditParticipantProfiles',
  CanListUserProfiles: 'CanListUserProfiles',
  CanEditUserProfiles: 'CanEditUserProfiles',
  CanListEditCompanies: 'CanListEditCompanies',
  CanListEditForms: 'CanListEditForms',
  CanListEditSendMailings: 'CanListEditSendMailings',
  CanListEditMailingTemplates: 'CanListEditMailingTemplates',
  CanEditGlobalRoles: 'CanEditGlobalRoles',
  CanListPassports: 'CanListPassports',
  CanEditPassports: 'CanEditPassports',
  ParticipantCanRecordMeetingVideo: 'ParticipantCanRecordMeetingVideo',
  ParticipantCanSendSMS: 'ParticipantCanSendSMS',
  CanEditParticipantRoles: 'CanEditParticipantRoles',
  CanShowCharts: 'CanShowCharts',
  CanLoginToWebsite: 'CanLoginToWebsite',
  CanMeetingHotelsEdit: 'CanMeetingHotelsEdit',
  CanMeetingHotelsList: 'CanMeetingHotelsList',
  CanUserLineRegionNumberEdit: 'CanUserLineRegionNumberEdit',
  CanHaveSuperior: 'CanHaveSuperior',
  CanBeSuperior: 'CanBeSuperior',
  CanSetSuperior: 'CanSetSuperior',
  CanListEditInstances: 'CanListEditInstances',
  CanListEditWebsites: 'CanListEditWebsites',
  CanListEditBuilderTemplates: 'CanListEditBuilderTemplates',
  CanListEditContractTemplates: 'CanListEditContractTemplates',
  CanGoToAdminLessons: 'CanGoToAdminLessons',
  CanListMyParticipantProfiles: 'CanListMyParticipantProfiles',
  CanEditMyParticipantProfiles: 'CanEditMyParticipantProfiles',
  CanListMyUserProfiles: 'CanEditMyUserProfiles',
  CanEditMyUserProfiles: 'CanEditMyUserProfiles',
  CanListParticipantsOnlyAddedToClientByMe: 'CanListParticipantsOnlyAddedToClientByMe',
  CanExportParticipants: 'CanExportParticipants',
  CanChatAsParticipant: 'CanChatAsParticipant',
  CanSoftDeletePassports: 'CanSoftDeletePassports',
  CanHardDeletePassports: 'CanHardDeletePassports',
  CanListEditConfirmations: 'CanListEditConfirmations',
  CanListEditMyTasks: 'CanListEditMyTasks',
  CanViewSystemDashboard: 'CanViewSystemDashboard',
  CanViewFullMeetingDashboard: 'CanViewFullMeetingDashboard',
  CanViewBasicMeetingDashboard: 'CanViewBasicMeetingDashboard',
  CanSoftDeleteProfiles: 'CanSoftDeleteProfiles',
  CanHardDeleteProfiles:  'CanHardDeleteProfiles',
  CanListEditSnippets: 'CanListEditSnippets',
  CanForceEditConsentText: 'CanForceEditConsentText',
  CanExportNotActivePassports: 'CanExportNotActivePassports',
  CanListEditElearningCampaigns: 'CanListEditElearningCampaigns',
  CanExportParticipantProfiles: 'CanExportParticipantProfiles',
  CanEditConsentCheckbox: 'CanEditConsentCheckbox',
  CanExportMoodleProgress: 'CanExportMoodleProgress',
  CanExportFullMoodleProgress: 'CanExportFullMoodleProgress',
  CanListProfilesOnlyInAllowedGroups: 'CanListProfilesOnlyInAllowedGroups',
  CanListEditProfilesOnlyInAllowedGroups: 'CanListEditProfilesOnlyInAllowedGroups',
  CanUseCommandLineInAdminMode: 'CanUseCommandLineInAdminMode',
  CanLogInAsWebsiteUser: 'CanLogInAsWebsiteUser',
  CanListEditSpeakers: 'CanListEditSpeakers',
  CanListEditSubstitutions: 'CanListEditSubstitutions',
  CanListMyParticipantsList: 'CanListMyParticipantsList',
  CanListEditOrganizers: 'CanListEditOrganizers',
  CanViewFormSubmissions: 'CanViewFormSubmissions',
  CanDeleteFormSubmissions: 'CanDeleteFormSubmissions',
  CanChangeStageToContractApproved: 'CanChangeStageToContractApproved',
  CanChangeStageToRejected: 'CanChangeStageToRejected',
  CanListEditInternalMessages: 'CanListEditInternalMessages',
  CanReceiveAllInternalMessages: 'CanReceiveAllInternalMessages',
  CanApproveNewMeetingRequests: 'CanApproveNewMeetingRequests',
  CanSeeParticipantStatuses: 'CanSeeParticipantStatuses',
  CanLoadParticipantList: 'CanLoadParticipantList',
  CanLoadProfileList: 'CanLoadProfileList',
  CanLoadAddParticipantList: 'CanLoadAddParticipantList',
  CanGenerateReports: 'CanGenerateReports',
  CanGenerateEGAReport: 'CanGenerateEGAReport',
  CanGeneratePIT11AReport: 'CanGeneratePIT11AReport',
  CanGenerateCONTRACTSReport: 'CanGenerateCONTRACTSReport',
  CanGenerateEventFilesReport: 'CanGenerateEventFilesReport',
  CanDeleteCompanies: 'CanDeleteCompanies',
  CanListOnlyMyParticipants: 'CanListOnlyMyParticipants',
  CanListOnlyMySubordinatesParticipants: 'CanListOnlyMySubordinatesParticipants',
  CanExportMeetingRegistrationForm: 'CanExportMeetingRegistrationForm',
  CanImportParticipants: 'CanImportParticipants',
  CanExportMeetingsReport: 'CanExportMeetingsReport',
  CanViewParticipantAccommodation: 'CanViewParticipantAccommodation',
  CanEditParticipantAccommodation: 'CanEditParticipantAccommodation',
  CanViewParticipantMeetingServices: 'CanViewParticipantMeetingServices',
  CanEditParticipantMeetingServices: 'CanEditParticipantMeetingServices',
  CanListParticipantFormSubmissions: 'CanListParticipantFormSubmissions',
  CanViewParticipantFormSubmissions: 'CanViewParticipantFormSubmissions',
  CanViewParticipantMessageHistory: 'CanViewParticipantMessageHistory',
  CanListParticipantContracts: 'CanListParticipantContracts',
  CanSendPasswordSetLink: 'CanSendPasswordSetLink',
  CanExportParticipantStats: 'CanExportParticipantStats',
  CanExportUserProfiles: 'CanExportUserProfiles',
  CanViewLog: 'CanViewLog',
  CanEditRestrictedPersonalData: 'CanEditRestrictedPersonalData',

  CanListFiles: 'CanListFiles',
  CanCreateFiles: 'CanCreateFiles',
  CanViewFiles: 'CanViewFiles',
  CanEditFiles: 'CanEditFiles',
  CanDeleteFiles: 'CanDeleteFiles',

  CanListRoles: 'CanListRoles',
  CanCreateRoles: 'CanCreateRoles',
  CanViewRoles: 'CanViewRoles',
  CanEditRoles: 'CanEditRoles',
  CanDeleteRoles: 'CanDeleteRoles',

  CanListGroups: 'CanListGroups',
  CanCreateGroups: 'CanCreateGroups',
  CanViewGroups: 'CanViewGroups',
  CanEditGroups: 'CanEditGroups',
  CanDeleteGroups: 'CanDeleteGroups',
  CanImportGroups: 'CanImportGroups',

  CanListHotels: 'CanListHotels',
  CanCreateHotels: 'CanCreateHotels',
  CanViewHotels: 'CanViewHotels',
  CanEditHotels: 'CanEditHotels',
  CanDeleteHotels: 'CanDeleteHotels',

  CanListParticipantFiles: 'CanListParticipantFiles',
  CanCreateParticipantFiles: 'CanCreateParticipantFiles',
  CanDownloadParticipantFiles: 'CanDownloadParticipantFiles',
  CanEditParticipantFiles: 'CanEditParticipantFiles',
  CanDeleteParticipantFiles: 'CanDeleteParticipantFiles',

  CanListContracts: 'CanListContracts',
  CanCreateContracts: 'CanCreateContracts',
  CanViewContracts: 'CanViewContracts',
  CanEditContracts: 'CanEditContracts',
  CanDeleteContracts: 'CanDeleteContracts',

  CanListMeetings: 'CanListMeetings',
  CanListMeetingsInParticipantMode: 'CanListMeetingsInParticipantMode',
  CanListOnlyPublicMeetings: 'CanListOnlyPublicMeetings',
  CanCreateMeetings: 'CanCreateMeetings',
  CanViewMeetings: 'CanViewMeetings',
  CanEditMeetings: 'CanEditMeetings',
  CanDeleteMeetings: 'CanDeleteMeetings',

  CanViewRoleAllowedGroups: 'CanViewRoleAllowedGroups',
  CanEditRoleAllowedGroups: 'CanEditRoleAllowedGroups',

  CanViewCompanyDashboardConfig: 'CanViewCompanyDashboardConfig',
  CanEditCompanyDashboardConfig: 'CanEditCompanyDashboardConfig',

  CanViewCompanyMailingConfig: 'CanViewCompanyMailingConfig',
  CanEditCompanyMailingConfig: 'CanEditCompanyMailingConfig',

  CanViewCompanyPaymentConfig: 'CanViewCompanyPaymentConfig',
  CanEditCompanyPaymentConfig: 'CanEditCompanyPaymentConfig',

  CanViewContractConfig: 'CanViewContractConfig',
  CanEditContractConfig: 'CanEditContractConfig',

  CanViewProfileFieldConfig: 'CanViewProfileFieldConfig',
  CanEditProfileFieldConfig: 'CanEditProfileFieldConfig',

  CanViewLDAPConfig: 'CanViewLDAPConfig',
  CanEditLDAPConfig: 'CanEditLDAPConfig',

  CanListParticipantNotes: 'CanListParticipantNotes',
  CanCreateParticipantNotes: 'CanCreateParticipantNotes',
  CanDeleteParticipantNotes: 'CanDeleteParticipantNotes',

  CanHandleVariables: 'CanHandleVariables',
};
