import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ToastComponent } from './toast/toast.component';
import { ToastConfig, ToastControllerInterface } from '@frontend/core';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ToastController implements ToastControllerInterface {
  constructor(private _snackBar: MatSnackBar, private translateService: TranslateService) {}

  async create(toastConfig: ToastConfig): Promise<{present(): Promise<void>}> {
    const rawMessage = toastConfig.message ? toastConfig.message : 'messages.done';
    const interpolateParams = toastConfig.interpolateParams || {};

    toastConfig.message = this.translateService.instant(rawMessage, interpolateParams);
    toastConfig.duration = toastConfig.duration || 3000;
    toastConfig.position = toastConfig.position || 'top';
    toastConfig.cssClass = toastConfig.cssClass || 'success-toast';

    if (toastConfig.showCloseButton == null) {
      toastConfig.showCloseButton = true;
    }

    return {
      present: async (): Promise<void> => {
        const component = this._snackBar.openFromComponent(ToastComponent, {
          data: {},
          duration: toastConfig.duration,
          verticalPosition: <MatSnackBarVerticalPosition>toastConfig.position,
          panelClass: toastConfig.cssClass
        });

        component.instance.text = toastConfig.message;
        component.instance.showCloseButton = toastConfig.showCloseButton;
      }
    };
  }
}
