import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent implements OnInit {
  title: string;
  message: string;
  constructor(private matDialogRef: MatDialogRef<TimeoutModalComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close();
  }

}
