import { Inject, Injectable, Injector, makeStateKey, Optional, TransferState } from '@angular/core';
import { AvailableLanguagesService, EnvService, HttpService } from '@frontend/core';
import { WebsiteConfig } from '../interface/website-config';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _config: WebsiteConfig;

  constructor(
    @Optional() @Inject('websiteConfig') private websiteConfig: WebsiteConfig,
    private envService: EnvService,
    private transferState: TransferState,
    private injector: Injector,
    private availableLanguagesService: AvailableLanguagesService,
  ) {}

  get config(): WebsiteConfig {
    return this._config;
  }

  get favicon(): string {
    return this._config.favicon;
  }

  async init(): Promise<void> {
    const WEBSITE_CONFIG = makeStateKey<WebsiteConfig>('config');
    if (!this.envService.isServerSide() && this.transferState.hasKey(WEBSITE_CONFIG)) {
      this._config = this.transferState.get<WebsiteConfig>(WEBSITE_CONFIG, null);
      this.transferState.remove(WEBSITE_CONFIG);
    } else if (!this._config) {
      if (this.websiteConfig) {
        this._config = this.websiteConfig;
      } else {
        await this.getConfigFromEnv();
      }
      this.transferState.set(WEBSITE_CONFIG, this._config);
    }
    this.updateApiUrl();

    this.availableLanguagesService.init(this.config.languages);
    this.availableLanguagesService.setSelectedLanguage(this.config.defaultLanguage);
  }

  private updateApiUrl(): void {
    if (!!this.config.apiAddress) {
      this.envService.update('url', this.config.apiAddress)
    }
  }

  private async getConfigFromEnv(): Promise<void> {
    const websiteAddressId = this.envService.get('websiteAddressId');
    const httpService = this.injector.get<HttpService>(HttpService);

    const data = await firstValueFrom(
      httpService.get(this.envService.getApiUrl(`public/website/${websiteAddressId}/info`))
    );

    this._config = {
      ...data,
      websiteAddressId: websiteAddressId,
    };
  }
}
