import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatSettings, TranslationDomain } from '@frontend/core';
import { FormSchema } from '@frontend/form';
import { InputAbstract } from '../../../interface/input-abstract';
import { SelectChoice } from '../../../interface/select-choice';

@Component({
  selector: 'app-input-participant-hotel-night',
  templateUrl: './input-participant-hotel-night.component.html',
  host: {'class': 'form-input'},
})
export class InputParticipantHotelNightComponent extends InputAbstract implements OnInit {
  @Input() meetingNight: FormSchema;
  @Input() disabled = false;

  hotels: FormSchema;
  rooms: FormSchema;
  toggler: FormSchema;
  price: string;

  dateFormatSettings = DateFormatSettings;

  constructor(
    protected translator: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.globalForm.currentPage = 1;

    this.initFields();

    this.addFormControlToFormGroup('participantHotelNights');

    this.hotels.formControl.valueChanges.subscribe(value => this.handleHotelChange(value));

    this.setData();

    this.toggler.formControl.valueChanges.subscribe(value => this.handleToggle(value));
    this.rooms.formControl.valueChanges.subscribe(value => this.handleRoomChange(value));
  }

  private initFields(): void {
    const availableHotels = this.filterAvailableHotels(this.formSchema.children, this.meetingNight);
    this.toggler = {
      attr: {toggle: true},
      disabled: !availableHotels.length || this.disabled,
      options: {value: (this.meetingNight.map.get('id').formControl.value ?? 1)},
      formControl: this.meetingNight.map.get('id').formControl,
      page: 1
    }

    this.hotels = {
      attr: {},
      choices: this.transformChildrenToChoices(availableHotels),
      formControl: new FormControl(null, Validators.required),
      label: this.translator.instant(TranslationDomain + 'chooseHotel'),
      page: 1
    };

    this.rooms = {
      additionalData: {hotelRooms: null},
      attr: {},
      choices: [],
      formControl: new FormControl(null, Validators.required),
      label: this.translator.instant(TranslationDomain + 'chooseRoom'),
      page: 1
    };
  }

  private addFormControlToFormGroup(controlName: string): void {
    if (!this.globalForm.formGroup.get(controlName)) {
      this.globalForm.formGroup.setControl(controlName, new FormArray([this.toggler.formControl]));
    } else {
      // @ts-ignore
      this.globalForm.formGroup.get(controlName).push(this.toggler.formControl);
    }
  }

  private handleHotelChange(value: number | null): void {
    this.rooms.formControl.setValue(null);

    if (value === null) {
      return;
    }

    const hotelRooms = this.formSchema.children
      .filter(element => element.map.get('id').value == value)
      .pop().map.get('hotelRooms').children;

    this.rooms.choices = this.transformChildrenToChoices(
      this.filterAvailableRooms(hotelRooms, this.meetingNight)
    );
    this.rooms.additionalData.hotelRooms = hotelRooms;
  }

  private handleRoomChange(value: number | null): void {
    if (value === null) {
      this.setPrice(null);

      return;
    }

    const night = this.rooms.additionalData.hotelRooms
      .filter(element => element.map.get('id').value == value).pop()
      .map.get('hotelNights').children.filter(
        element => element.map.get('date').value.date === this.meetingNight.map.get('date').formControl.value.date
      ).pop()

    this.setPrice(night);

    const nightId = night?.map.get('id').formControl.value;
    this.toggler.formControl.setValue(nightId);
    this.toggler.options.value = nightId;
  }

  private handleToggle(checked: boolean): void {
    if (checked) {
      this.hotels.formControl.enable({emitEvent: false});
      this.rooms.formControl.enable({emitEvent: false});
    } else {
      this.hotels.formControl.reset();
      this.hotels.formControl.disable({emitEvent: false});
      this.rooms.formControl.reset();
      this.rooms.formControl.disable({emitEvent: false});
      this.setPrice(null);
    }
  }

  private setData(): void {
    if (!this.toggler.formControl.value) {
      this.handleToggle(false);

      return;
    }

    this.formSchema.children.forEach(hotel => {
      hotel.map.get('hotelRooms').children.forEach(room => {
        room.map.get('hotelNights').children.forEach(night => {
          if (night.map.get('id').formControl.value == this.toggler.formControl.value) {
            this.hotels.formControl.setValue(hotel.map.get('id').formControl.value);
            this.rooms.formControl.setValue(room.map.get('id').formControl.value);
            this.setPrice(night);
          }
        })
      })
    });
  }

  private transformChildrenToChoices(children: Array<FormSchema>): Array<SelectChoice> {
    return children.map(child => ({
      label: child.map.get('name').value,
      value: child.map.get('id').value,
    }));
  }

  private filterAvailableHotels(hotels: Array<FormSchema> | null, meetingNight: FormSchema): Array<FormSchema> {
    return hotels?.filter(hotel =>
      hotel.map.get('hotelRooms')?.children?.some(room =>
        room.map.get('hotelNights')?.children?.some(night =>
          night.map.get('date').formControl.value.date === meetingNight.map.get('date').formControl.value.date
        )
      )
    ) ?? [];
  }

  private filterAvailableRooms(rooms: Array<FormSchema>, meetingNight: FormSchema): Array<FormSchema> {
    return rooms.filter(room =>
      room.map.get('hotelNights').children.some(night =>
        night.map.get('date').formControl.value.date === meetingNight.map.get('date').formControl.value.date
      )
    );
  }

  private setPrice(hotelNight?: FormSchema): void {
    this.price = hotelNight ? hotelNight.map.get('price').formControl.value + ' PLN' : null;
  }
}
