import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Injector} from '@angular/core';
import {EnvService} from '../services/env.service';
import {HttpService} from '../services/http.service';

export function createTranslateLoader(injector: Injector) {
  return new TranslateHttpLoader(injector);
}

export class TranslateHttpLoader implements TranslateLoader {
  private http;
  private env;

  constructor(private injector: Injector) {
    this.env = this.injector.get(EnvService);
    this.http = this.injector.get(HttpService);
  }

  getTranslation(lang: string): Observable<any> {
    const url = this.env.getBaseUrl(`/translation/${lang}`);

    return this.http.get(url);
  }
}
