export enum MessageType {
  createNewChatMessage,
  sendNewChatMessage,
  confirmReceiveChatMessage,
  joinMeetingChat,
  joinSessionChat,
  createPrivateChat,
  loadChatMessages,
  chatMessages,
  loadChats,
  chats,
  start
}
