import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService, Theme, ThemeService } from '@frontend/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  logo: any;
  themes = Theme;
  private subscribe: Subscription;

  constructor(
    private themeService: ThemeService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.theme().then();
    this.subscribe = this.themeService.changeStyle.subscribe(resp => this.theme().then());
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  navigate() {
    this.router.navigate(['']).then();
  }

  private async theme() {
    this.logo =
      (await this.themeService.getTheme()) === this.themes.light
        ? 'assets/icon/logo_blue.png'
        : 'assets/icon/logo_white.png';
  }
}
