import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './layout/components/not-found/not-found.component';
import { AppComponent } from './app.component';
import { RedirectService } from '@frontend/core';
import { AutologinComponent } from "./layout/components/autologin/autologin.component";
import { CompleteRegistrationComponent } from "./layout/components/complete-registration/complete-registration.component";
import { DesktopRedirectComponent } from "./layout/components/desktop-redirect/desktop-redirect.component";
import { UnsubscribeComponent } from "./layout/components/unsubscribe/unsubscribe.component";
import { LogoutComponent } from "./layout/components/logout/logout.component";

const routes: Routes = [
  { path: '404', component: NotFoundComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'autologin/:token', component: AutologinComponent },
  { path: 't/:token', component: CompleteRegistrationComponent },
  { path: 'go-to-desktop', component: DesktopRedirectComponent },
  {
    path: ':lang',
    component: AppComponent,
    children: [
      { path: 'logout', component: LogoutComponent },
      { path: 'unsubscribe/:token', component: UnsubscribeComponent },
      { path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
      { path: 'c', loadChildren: () => import('./public/c/c.module').then(m => m.CModule) },
      { path: 'chart', loadChildren: () => import('./public/chart/chart.module').then(m => m.ChartModule) },
      { path: 'presentation', loadChildren: () => import('./public/presentation/presentation.module').then(m => m.PresentationModule) },
      { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule) }
    ]
  },
  { path: '', pathMatch: 'full', children: [], canActivate: [RedirectService] },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
