import {Injectable} from '@angular/core';
import {FileUploadProgressControllerInterface} from "../interface/file-upload-progress-controller.interface";

@Injectable({
  providedIn: 'root'
})
export class FileUploadProgressController implements FileUploadProgressControllerInterface {
  fileUploadProgressController: FileUploadProgressControllerInterface;

  async create(data: any = {}): Promise<any> {
    return this.fileUploadProgressController.create(data);
  }

  async dismiss(): Promise<any> {
    return this.fileUploadProgressController.dismiss();
  }
}
