import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxSummernoteModule } from 'ngx-summernote';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { StarRatingModule } from 'angular-star-rating';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { CoreModule } from '@frontend/core';
import { FormBuilder } from './service/form-builder.service';
import { ControlBuilder } from './service/control-builder';
import { InputTypeList } from './service/input-type-list';
import { InputValidatorList } from './service/input-validator-list';
import { FormSubmitService } from './service/form-submit.service';
import { FormFillService } from './service/form-fill.service';
import { ScoreService } from './service/score.service';
import { QuillHelperModuleModule } from './quill-helper-module/quill-helper-module.module';
import { ControlFilterPipe } from './pipe/control-filter.pipe';
import { InputComponent } from './component/inputs/input/input.component';
import { ResponseMessageComponent } from './component/response-message/response-message.component';
import { ErrorMessagesComponent } from './component/error-messages/error-messages.component';
import { FormComponent } from './component/form/form.component';
import { GroupComponent } from './component/group/group.component';
import { ButtonComponent } from './component/inputs/button/button.component';
import { InputPasswordComponent } from './component/inputs/input-password/input-password.component';
import { InputSelectComponent } from './component/inputs/input-select/input-select.component';
import { InputCollectionComponent } from './component/inputs/input-collection/input-collection.component';
import { InputTextareaComponent } from './component/inputs/input-textarea/input-textarea.component';
import { InputCheckboxComponent } from './component/inputs/input-checkbox/input-checkbox.component';
import { LanguageSelectComponent } from './component/inputs/language-select/language-select.component';
import { InputRadioComponent } from './component/inputs/input-radio/input-radio.component';
import { InputDynamicChoiceComponent } from './component/inputs/input-dynamic-choice/input-dynamic-choice.component';
import { InputHtmlComponent } from './component/inputs/input-html/input-html.component';
import { InputHiddenComponent } from './component/inputs/input-hidden/input-hidden.component';
import { InputDateComponent } from './component/inputs/input-date/input-date.component';
import { LabelComponent } from './component/inputs/label/label.component';
import { InputRatingComponent } from './component/inputs/input-rating/input-rating.component';
import { InputNumberComponent } from './component/inputs/input-number/input-number.component';
import { InputDateTimeComponent } from './component/inputs/input-date-time/input-date-time.component';
import { InputTagsComponent } from './component/inputs/input-tags/input-tags.component';
import { TimerComponent } from './component/timer/timer.component';
import { InputRawFileComponent } from './component/inputs/input-raw-file/input-raw-file.component';
import { InputTimeComponent } from './component/inputs/input-time/input-time.component';
import { InputColorPickerComponent } from './component/inputs/input-color-picker/input-color-picker.component';
import { InputHtmlSummernoteComponent } from './component/inputs/input-html-summernote/input-html-summernote.component';
import { SelectFilterChart } from './component/inputs/select-filter-chart/select-filter-chart';
import { ImagePreviewDialogComponent } from './component/image-preview-dialog/image-preview-dialog.component';
import { SubmitWithExampleComponent } from './component/inputs/submit-with-example/submit-with-example.component';
import { InputBusinessHoursComponent} from './component/inputs/input-business-hours/input-business-hours.component';
import { InputBusinessHoursSingleDayComponent} from './component/inputs/input-business-hours-single-day/input-business-hours-single-day.component';
import { InputEmailComponent } from './component/inputs/input-email/input-email.component';
import { InputPhoneNumberComponent } from './component/inputs/input-phone-number/input-phone-number.component';
import { FormDependentFieldListService } from './service/form-dependent-field-list.service';
import { LabelPageBreakComponent } from './component/inputs/label-page-break/label-page-break.component';
import { PaginatorComponent } from './component/inputs/paginator/paginator.component';
import { InputParticipantAccommodationChoiceComponent } from './component/inputs/input-participant-accommodation-choice/input-participant-accommodation-choice.component';
import { InputParticipantHotelNightComponent } from './component/inputs/input-participant-hotel-night/input-participant-hotel-night.component';
import { InputMeetingServicesComponent } from './component/inputs/input-meeting-services/input-meeting-services.component';
import { InputDateTimeTextComponent } from './component/inputs/input-date-time-text/input-date-time-text.component';
import { AbstractInputDateComponent } from './component/inputs/abstract-input-date/abstract-input-date.component';
import { CustomLabelComponent } from './component/inputs/custom-label/custom-label.component';

@NgModule({
  declarations: [
    InputComponent,
    ResponseMessageComponent,
    ErrorMessagesComponent,
    FormComponent,
    GroupComponent,
    ButtonComponent,
    InputPasswordComponent,
    InputSelectComponent,
    InputCollectionComponent,
    InputTextareaComponent,
    InputCheckboxComponent,
    LanguageSelectComponent,
    ControlFilterPipe,
    InputRadioComponent,
    InputDynamicChoiceComponent,
    InputHtmlComponent,
    InputHiddenComponent,
    InputDateComponent,
    LabelComponent,
    InputRatingComponent,
    InputNumberComponent,
    InputDateTimeComponent,
    InputTagsComponent,
    TimerComponent,
    InputRawFileComponent,
    InputTimeComponent,
    InputColorPickerComponent,
    InputHtmlSummernoteComponent,
    SelectFilterChart,
    ImagePreviewDialogComponent,
    SubmitWithExampleComponent,
    InputBusinessHoursComponent,
    InputBusinessHoursSingleDayComponent,
    InputEmailComponent,
    InputPhoneNumberComponent,
    LabelPageBreakComponent,
    PaginatorComponent,
    InputParticipantAccommodationChoiceComponent,
    InputParticipantHotelNightComponent,
    InputMeetingServicesComponent,
    InputDateTimeTextComponent,
    AbstractInputDateComponent,
    CustomLabelComponent,
  ],
  providers: [
    FormBuilder,
    ControlBuilder,
    InputTypeList,
    InputValidatorList,
    FormSubmitService,
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    FormFillService,
    ScoreService,
    FormDependentFieldListService,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule,
    StarRatingModule.forRoot(),
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMomentDateModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatRadioModule,
    NgSelectModule,
    NgxSummernoteModule,
    NgxMatTimepickerModule,
    ColorPickerModule,
    QuillHelperModuleModule,
    CoreModule,
  ],
  exports: [
    InputComponent,
    InputPasswordComponent,
    ResponseMessageComponent,
    FormComponent,
    ButtonComponent,
    InputComponent,
    InputPasswordComponent,
    InputSelectComponent,
    InputCollectionComponent,
    InputCheckboxComponent,
    InputTextareaComponent,
    InputRadioComponent,
    InputDynamicChoiceComponent,
    InputHtmlComponent,
    InputHiddenComponent,
    InputDateComponent,
    LabelComponent,
    InputNumberComponent,
    InputDateTimeComponent,
    InputTagsComponent,
    GroupComponent,
    TimerComponent,
    InputRawFileComponent,
    LanguageSelectComponent,
    ErrorMessagesComponent,
    InputTimeComponent,
    InputColorPickerComponent,
    ControlFilterPipe,
    QuillModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    StarRatingModule,
    InputHtmlSummernoteComponent,
    SelectFilterChart,
    SubmitWithExampleComponent,
    InputBusinessHoursComponent,
    InputBusinessHoursSingleDayComponent,
    InputEmailComponent,
    InputPhoneNumberComponent,
    LabelPageBreakComponent,
    PaginatorComponent,
    InputParticipantAccommodationChoiceComponent,
    InputParticipantHotelNightComponent,
    InputMeetingServicesComponent,
    InputDateTimeTextComponent,
    AbstractInputDateComponent,
  ]
})
export class FormModule {}
