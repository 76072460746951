import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserStorageService } from '@frontend/core';
import { CachedMeeting } from '../interface/cached-meeting';
import { PathService } from '../../../layout/service/path.service';
import { MeetingCachingListService } from '../service/meeting-caching-list.service';
import { MeetingTypeId } from '../const/meeting-type-id';
import { MenuListMeetingsComponent } from '../menu-list-meetings/menu-list-meetings.component';

@Component({
  selector: 'app-menu-list-meetings-campaigns',
  templateUrl: './menu-list-meetings-campaigns.component.html',
  styleUrls: ['../menu-list-meetings/menu-list-meetings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuListMeetingsCampaignsComponent extends MenuListMeetingsComponent implements OnInit {
  cachedMeetingList: CachedMeeting[] = new Array<CachedMeeting>();

  constructor(
    meetingCachingListService: MeetingCachingListService,
    pathService: PathService,
    cd: ChangeDetectorRef,
    router: Router,
    userStorageService: UserStorageService
  ) {
    super(meetingCachingListService, pathService, cd, router, userStorageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refreshMeetingMenu(MeetingTypeId.CAMPAIGN);
    this.subscriptions.push(this.getNavigationEndSubscription(MeetingTypeId.CAMPAIGN));
    this.subscriptions.push(this.meetingCachingListService.changeEventList.subscribe(value => {
      if (value === MeetingTypeId.CAMPAIGN) {
        this.refreshMeetingMenu(MeetingTypeId.CAMPAIGN);
      }
    }));
  }
}
