import { Component, OnInit } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input-rating',
  templateUrl: './input-rating.component.html',
  host: {'class': 'form-input'},
})
export class InputRatingComponent extends InputAbstract implements OnInit {
  ngOnInit(): void {
    super.ngOnInit();

    if (this.formSchema.formControl.value === null) {
      this.formSchema.formControl.setValue('');
    }
  }
}
