import { MenuMethod } from "./menu-method";

export interface Link {
  path?: string;
  name?: string;
  icon?: string;
  actions?: string[];
  meetingTypes?: number[];
  breadCrumb?: Link[];
  method?: MenuMethod;
  runMethod?: boolean;
  fontSet?: string;
  symbol?: string;
  partialActivatingPath?: string;
  backendPath?: string;
}
