import { Injectable } from '@angular/core';
import { WebsitesCache } from '../interface/websites-cache';
import { Subject, Subscription } from 'rxjs';
import { WebsiteMenu } from '../../../layout/link/menu/menu-config/website-menu';
import { ReplacementService } from '../../../layout/service/replacement.service';
import { UserStorageService } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class WebsitesCachingListService {
  list: WebsitesCache[] = new Array<WebsitesCache>();
  changeWebsitesList: Subject<void> = new Subject<void>();
  private subscription: Subscription;

  constructor(private replacementService: ReplacementService, private userStorageService: UserStorageService) {
    this.subscription = userStorageService.userChange.subscribe(value => {
      this.list = [];
      this.changeWebsitesList.next(null);
    });
  }

  add(websiteId: any) {
    let found = false;
    let foundOn = null;
    this.list.forEach(((value, key) => {
      if (value.id === websiteId) {
        found = true;
        foundOn = key;
      }
    }));

    if (found) {
    } else {
      if (this.list.length >= 4) {
        this.list.pop();
      }

      const websiteItem = {
        id: websiteId,
        name: null,
        menuListItems: this.createWebsitesMenu(websiteId),
        active: true,
        expanded: true
      };

      this.list.unshift(websiteItem);
      this.updateName(websiteItem);
    }
  }

  private createWebsitesMenu(websiteId) {
    const menu = WebsiteMenu;
    const newMenu = [];
    menu.forEach((value, index) => {
      const element = Object.assign({}, value);
      element.params = {
        'websiteId': websiteId
      };
      newMenu[index] = element;
    });

    return newMenu;
  }

  private updateName(websiteItem: { name: any; menuListItems: any[]; id: any }) {
    this.replacementService.getWebsiteName(websiteItem.id).subscribe(value => {
      websiteItem.name = value;
      this.changeWebsitesList.next();
    });
  }
}
