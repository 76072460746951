import { Component, OnInit } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input-business-hours-single-day',
  templateUrl: './input-business-hours-single-day.component.html',
  styleUrls: ['./input-business-hours-single-day.component.scss'],
  host: {'class': 'form-input'},
})
export class InputBusinessHoursSingleDayComponent extends InputAbstract implements OnInit {
  labelText: string;

  constructor(
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.labelText = this.translateService.instant('messages.' + this.formSchema.map.get('day').value);
  }
}
