import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService, LinkService, Theme, ThemeService } from '@frontend/core';
import { ErrorPresenterService } from './layout/error/error-presenter.service';
import { MatIconRegistry } from '@angular/material/icon';
import { ChatLocalNotificationService } from './chat/service/chat-local-notification.service';
import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
import { TranslateService } from "@ngx-translate/core";

const {SplashScreen, StatusBar} = Plugins;

@Component({
  selector: 'app-base',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  langLoaded = false;

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private linkService: LinkService,
    private errorPresenterService: ErrorPresenterService,
    private matIconRegistry: MatIconRegistry,
    private chatLocalNotificationService: ChatLocalNotificationService,
    private themeService: ThemeService,
    private translateService: TranslateService,
  ) {
    this.errorPresenterService.init();
    this.linkService.setActivatedRoute(this.route);

    this.route.params.subscribe(async val => {
      await this.languageService.setLangFromPath(val.lang);
      this.langLoaded = true;
    });

    matIconRegistry.registerFontClassAlias('filled');
    matIconRegistry.registerFontClassAlias('outlined');
    matIconRegistry.setDefaultFontSetClass('filled');

    this.chatLocalNotificationService.init();

    if (Capacitor.isPluginAvailable('StatusBar')) {
      this.setStatusBar();
      this.themeService.changeStyle.subscribe(() => this.setStatusBar());
    }
  }

  ngOnInit(): void {
    this.initializeApp();
    this.setPageLang();
  }

  initializeApp() {
    SplashScreen.hide().then();
  }

  private setStatusBar() {
    StatusBar.setStyle({
      style:
        this.themeService._theme === Theme.dark
          ? StatusBarStyle.Dark
          : StatusBarStyle.Light
    }).then();
  }

  private setPageLang(): void {
    document.documentElement.lang = this.translateService.currentLang;
  }
}
