import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { InputAbstract } from "../../../interface/input-abstract";
import { MatDialog } from "@angular/material/dialog";
import { ImagePreviewDialogComponent } from "../../image-preview-dialog/image-preview-dialog.component";

@Component({
  selector: 'app-input-raw-file',
  templateUrl: './input-raw-file.component.html',
  styleUrls: ['./input-raw-file.component.scss'],
  host: {'class': 'form-input'},
})
export class InputRawFileComponent extends InputAbstract implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  file: {
    fileName: string;
    imageSrc: string;
    downloadLink?: string;
  } = {
    fileName: '',
    imageSrc: null,
    downloadLink: null
  };
  showClearButton = false;
  private deleteValue = 'DELETE';

  constructor(private cd: ChangeDetectorRef, private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.formSchema.formControl.valueChanges.subscribe(value => this.onReactiveInputValueChange(value));
    this.clear();
    this.initControl(this.formSchema.formControl.value);
  }

  selectFile() {
    this.fileInput.nativeElement.click();
  }

  clearFile() {
    this.formSchema.formControl.setValue(this.deleteValue);
    this.fileInput.nativeElement.value = null;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.formSchema.formControl.setValue(file);
    }
  }

  initControl(value) {
    if (value?.mime) {
      if (value.mime.split('/')[0] === 'image') {
        this.file.imageSrc = value.path;
      }

      this.file.fileName = value.name;
      this.file.downloadLink = value.path;
      this.showClearButton = true;
    } else {
      this.onReactiveInputValueChange(null).then();
    }
  }

  openDialog(): void {
    if (window.innerWidth > 780) {
      this.dialog.open(ImagePreviewDialogComponent, {
        data: {
          imgSrc: this.file.imageSrc
        }
      });
    } else {
      this.dialog.open(ImagePreviewDialogComponent, {
        data: {
          imgSrc: this.file.imageSrc
        },
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%'
      });
    }
  }

  private async onReactiveInputValueChange(file: File | string | null) {
    if (
      file !== null &&
      file !== this.deleteValue &&
      file.constructor &&
      file.constructor.name === 'File'
    ) {
      const f: File = <File>file;
      this.setFileImageSrc(f);
      this.file.fileName = f.name;
      this.showClearButton = true;
    } else {
      this.clear();
    }

    this.cd.detectChanges();
  }

  private setFileImageSrc(file: File) {
    if (file.type.split('/')[0] === 'image') {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.file.imageSrc = reader.result.toString();
        this.cd.markForCheck();
      };
    } else {
      this.file.imageSrc = null;
    }
  }

  private clear() {
    this.file.fileName = '';
    this.file.imageSrc = null;
    this.showClearButton = false;
  }
}
