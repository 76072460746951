import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Validators } from '@angular/forms';
import { InputAbstract } from '../../../interface/input-abstract';
import { RequiredFieldsService } from '../../../../../form-bootstrap/src/lib/service/required-fields.service';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  host: {'class': 'form-input'},

})
export class InputCheckboxComponent extends InputAbstract implements OnInit {
  @Input() disableWhenUnchecked = false;
  @HostBinding('class.consent-checkbox') get isConsentCheckboxClass() {
    return !!this.formSchema.consent;
  }

  checked = false;

  constructor(
    protected requiredFieldsService: RequiredFieldsService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.updateValue();
    this.formSchema.formControl.valueChanges.subscribe(() => this.updateValue());

    if (this.requiredFieldsService.isFieldRequired(this.formSchema)) {
      this.formSchema.formControl.setValidators(Validators.required);
    }
  }

  materialInputChange($event: MatCheckboxChange | MatSlideToggleChange): void {
    this.formSchema.formControl.setValue($event.checked ? (this.formSchema.options?.value ?? 1) : '');
    if (this.disableWhenUnchecked) {
      this.toggleState($event.checked);
    }
  }

  convertNullToEmptyString(): void {
    if (this.formSchema.formControl.value === null) {
      this.formSchema.formControl.setValue('', { emitEvent: false });
    }
  }

  private updateValue(): void {
    this.convertNullToEmptyString();

    this.checked = !!this.formSchema.formControl.value;
    if (this.disableWhenUnchecked) {
      this.toggleState(this.checked);
    }
  }

  private toggleState(isChecked: boolean): void {
    isChecked
      ? this.formSchema.formControl.enable({emitEvent: false})
      : this.formSchema.formControl.disable({emitEvent: false});
  }
}
