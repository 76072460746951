import { Injectable } from '@angular/core';
import { AlertController } from '@frontend/gui';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SuccessRegistrationHandlerService {

  constructor(
    private alertController: AlertController,
    private translate: TranslateService,
    private router: Router
  ) {}

  async handle(customSuccessMessage: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant('messages.form'),
      message: this.translate.instant(customSuccessMessage || 'messages.questionnaireSuccessFill'),
      buttons: [{
        text: this.translate.instant('messages.ok'),
        handler: () => this.router.navigate(['/'])
      }]
    });

    await alert.present();
  }
}
