import { Component, Input, OnInit } from '@angular/core';
import { FormSchema } from '../../../interface/form-schema';
import { LanguageService } from '@frontend/core';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  host: {'class': 'form-input'},
})
export class LanguageSelectComponent implements OnInit {
  @Input() form: FormSchema;
  activeLanguages: Array<{ label; value }>;
  selectedLanguageId: string;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.updateList(this.form.availableLanguages.formControl.value);
    this.changeLanguage(this.form.selectedLanguage.value);

    this.form.availableLanguages.formControl.valueChanges.subscribe(
      (activeLangIds: Array<string>) => {
        this.updateList(activeLangIds);
        this.changeLanguage(this.selectLanguage());
      }
    );

    this.form.parentEventEmitter$.subscribe((value) => {
      if (value?.forcedLanguageId) {
        this.selectedLanguageId = value.forcedLanguageId;
      }
    });
  }

  changeLanguage(langId: string): void {
    this.selectedLanguageId = langId;
    this.form.selectedLanguage.setValue(langId);
  }

  map(langId: string): string {
    return this.languageService.mapIcon(langId);
  }

  private updateList(activeLangIds: Array<string>): void {
    this.activeLanguages = this.form.availableLanguages.choices.filter(
      choice => activeLangIds ? activeLangIds.includes(choice.value) : false
    );
  }

  private selectLanguage(): string {
    return this.activeLanguages[0]?.value ?? this.form.availableLanguages.choices[0].value;
  }
}
