<mat-toolbar>
  <mat-toolbar-row class="d-flex justify-content-between">
    <span>{{ title | translate }}</span>
    <button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content>
  <span>{{ message | translate }}</span>
</mat-dialog-content>
