import { Injectable } from '@angular/core';
import { MenuElement } from '../link/menu/interface/menu-element';
import { LinkService, Link } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class PathService {
  constructor(private linkService: LinkService) {}

  checkUserHasAccess(menuElement: MenuElement): boolean {
    return (
      this.hasUserRequiredActions(menuElement) && this.hasUserMeetingTypeAccess(menuElement)
    );
  }

  isCurrentLink(paths: Link[], overrideParams: any = {}): boolean {
    return this.linkService.isCurrentLink(paths, overrideParams);
  }

  getUrl(link: Link): string {
    return this.linkService.getUrl(link);
  }

  isVisible(menuElement: MenuElement): boolean {
    let isVisible;

    if (!menuElement.displayOnlyWhenChildVisible) {
      isVisible = true;
    } else {
      isVisible = this.isCurrentLink(menuElement.paths);
    }

    return isVisible;
  }

  hasUserRequiredActions(menuElement: MenuElement): boolean {
    let hasActions = false;
    const link = menuElement.link;

    if (link.actions !== undefined) {
      hasActions = this.linkService.checkUserHasPrivileges(link.actions);
    } else if (menuElement.children?.length > 0) {
      for (const element of menuElement.children) {
        hasActions =
          hasActions ||
          !element.link.actions ||
          this.linkService.checkUserHasPrivileges(element.link.actions);
      }
    } else {
      hasActions = true;
    }

    return hasActions;
  }

  hasUserMeetingTypeAccess(menuElement: MenuElement): boolean {
    let hasMeetingTypeAccess = true;

    if (menuElement.link.meetingTypes !== undefined) {
      hasMeetingTypeAccess = this.linkService.checkUserHasMeetingTypeAccess(menuElement.link.meetingTypes);
    }

    return hasMeetingTypeAccess;
  }
}
