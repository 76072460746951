<ng-container *ngIf="isVisible">
  <mat-form-field>
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <mat-label *ngIf="!isFromEntity">{{ formSchema.label }}</mat-label>

    <textarea
      matInput
      [cdkTextareaAutosize]="autoHeight"
      [ngClass]="{'min-height': !autoHeight || minHeight || formSchema.attr.minHeight}"
      placeholder="{{ !formSchema?.attr?.hideLabel ? (formSchema.label) : null }}"
      (focus)="focusEvent.emit($event)"
      [formControl]="$any(formSchema.formControl)"
      [readonly]="formSchema.attr?.readonly ?? false"
    ></textarea>
  </mat-form-field>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
