<button
  *ngFor="let lang of activeLanguages"
  type="button"
  mat-flat-button
  [ngClass]="{ activated: lang.value === this.selectedLanguageId }"
  color="{{lang.value === this.selectedLanguageId ? 'primary' : 'base'}}"
  (click)="changeLanguage(lang.value)"
>
  <span slot="icon-only" class="flag-icon flag-icon-{{ map(lang.label) }}"></span>
</button>
