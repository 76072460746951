import { Component } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  host: {'class': 'form-input'},
})
export class InputPasswordComponent extends InputAbstract {
  typePassword = true;
}
