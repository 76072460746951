<h1 mat-dialog-title>{{ alertConfig.header }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="alertConfig.message"></p>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    [attr.color]="button.color || 'basic'"
    *ngFor="let button of alertConfig.buttons"
    (click)="click(button)"
  >
    {{ button.text }}
  </button>
</div>
