import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignChartService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {}

  getMessageStatistic(eventID: string, channel: string, message: string, link?: string): Observable<any> {
    let params = new HttpParams();
    if (channel) params = params.append('channel', channel);
    if (message) params = params.append('multi_channel_message', message);
    if (link) params = params.append('tracking_link', link);

    return this.http.get(this.envService.getApiUrl(`meeting/${eventID}/message_statistic`), {params})
  }

  getChannelFilter() {
    return this.http.get(this.envService.getApiV2Url('channels'));
  }

  getMessagesFilter() {
    return this.http.get(this.envService.getApiV2Url('multi_channel_messages'))
  }

  getTrackingLinksFilter() {
    return this.http.get(this.envService.getApiV2Url('tracking_links'))
  }
}
