import { Inject, Injectable } from '@angular/core';
import { EnvService } from './env.service';
import { HttpService } from './http.service';
import { Observable } from "rxjs";
import { Moodle } from "../interfaces/moodle";
import { DOCUMENT } from "@angular/common";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MoodleService {
  window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpService,
    private envService: EnvService,
  ) {
    this.window = this.document.defaultView;
  }

  get moodle(): Observable<Moodle> {
    return this.http.get(this.envService.getApiUrl(`/user/account/moodle`));
  }

  getCourse(course: number, website: number): Observable<any> {
    return this.http.get(this.envService.getApiUrl(`/e-learning/course/${course}/website/${website}`));
  }

  isActive(enabled): void {
    this.moodle.subscribe(resp => enabled(resp.enabled));
  }

  async redirect(): Promise<number> {
    if (!this.envService.isServerSide()) {
      const moodle: Moodle = await firstValueFrom(this.moodle);
      if (moodle.enabled) {
        setTimeout(() => {
          window.history.pushState({ prevUrl: window.location.href }, null, "/");
          this.window.location.href = moodle.url + '?login=' + moodle.username + '&key=' + moodle.key
        }, moodle.delay * 1000);
      }

      return moodle.delay;
    }

    return 0;
  }
}
