import { Injectable } from '@angular/core';
import { LoadingControllerInterface } from '../interface/loading-controller.interface';

@Injectable({
  providedIn: 'root'
})
export class LoadingController {
  loadingController: LoadingControllerInterface;

  async create(data: any = {}): Promise<{ present: () => Promise<void>; dismiss: () => Promise<void> }> {
    return this.loadingController.create(data);
  }

  async dismiss(): Promise<void> {
    return this.loadingController.dismiss();
  }
}
