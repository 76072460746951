<ng-container *ngIf="isVisible">
  <mat-form-field floatLabel="always">
    <mat-label *ngIf="!isFromEntity">{{ formSchema.label }}</mat-label>

    <input
      matInput
      [autocomplete]="formSchema.attr.autocomplete ? 'on' : 'off'"
      [(colorPicker)]="color"
      [style.background]="color"
      placeholder="{{ formSchema.label }}"
      [ngClass]="addClass"
      (focus)="focusEvent.emit($event)"
      [type]="formSchema.attr?.type ?? 'text'"
      [placeholder]="addPlaceholder && formSchema.attr?.placeholder ?? ''"
      (colorPickerChange)="update()"
      [cpAlphaChannel]="'disabled'"
      [cpPresetColors]="[
        '#fff',
        '#000',
        '#2889e9',
        '#e920e9',
        '#fff500',
        'rgb(236,64,64)'
      ]"
      [readonly]="formSchema.attr?.readonly ?? false"
    />
  </mat-form-field>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
