import { ControlBuilder } from '../control-builder';
import { FormBuilder } from '@angular/forms';
import { FormSchema } from '../../interface/form-schema';
import { AbstractBuilder } from '../../interface/abstract-builder';
import { BasicBuilder } from './basic-builder';

export class SelectBuilder extends AbstractBuilder {
  static build(
    cb: ControlBuilder,
    formBuilder: FormBuilder,
    input: FormSchema,
    globalForm: FormSchema
  ) {
    if (!input.expanded) {
      input.formControl = formBuilder.control(input.value);
    } else if (input.expanded && !input.multiple) {
      input.formControl = formBuilder.control(input.value);
      cb.buildForm(input.children, input, globalForm);
    } else if (input.expanded && input.multiple) {
      input.formControl = formBuilder.array([]);
      cb.buildForm(input.children, input, globalForm);
    }
    if (input.disabled) {
      BasicBuilder.disable(input);
    }
  }
}
