import { Component, Input, OnInit } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';
import { TranslateService } from '@ngx-translate/core';
import { PhoneService, Country, Number } from '../../../service/phone.service';

@Component({
  selector: 'app-phone-number',
  templateUrl: './input-phone-number.component.html',
  styleUrls: ['./input-phone-number.component.scss'],
  host: {'class': 'form-input'},
})
export class InputPhoneNumberComponent extends InputAbstract implements OnInit {
  @Input() addPlaceholder = true;
  @Input() addClass = '';

  countries: [Country?] = [];
  country = this.translate.currentLang === 'en' ? 'us' : this.translate.currentLang;
  parsedNumber: Number;

  constructor(
    private translate: TranslateService,
    private phoneService: PhoneService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (this.formSchema.language) {
      this.country = this.formSchema.language;
    }

    this.countries = await this.phoneService.getCountries();
    this.parsedNumber = this.phoneService.parse(this.formSchema.formControl.value);
    if (!this.parsedNumber.valid) {
      this.parsedNumber = this.phoneService.parse(this.formSchema.formControl.value, this.country);
    }

    this.country = this.parsedNumber.country || this.country;
    this.formSchema.formControl.setValue(this.parsedNumber.pretty);
  }

  change(number: number): void {
    this.parsedNumber = this.phoneService.parse(number, this.country);
    if (this.parsedNumber.valid) {
      this.formSchema.formControl.setValue(this.parsedNumber.pretty);
      this.country = this.parsedNumber.country;
    } else {
      this.formSchema.formControl.setValue('+' + this.parsedNumber.code + this.parsedNumber.number);
    }
  }

  setCountry(): void {
    this.change(this.parsedNumber.number);
  }

  customSearchFn(term: string, item: Country): boolean {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1
      || item.country.toLowerCase() === term
      || item.code.toString().includes(term);
  }
}
