import { EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CustomOptions } from '@frontend/form';
import { Subject } from 'rxjs';

export class LoadChoicesWhen extends CustomOptions {
  private parent?: AbstractControl;

  protected init() {
    this.parent = this.formGroup.get(this.payload.name);

    if (!this.parent) {
      this.globalSchema.children.some(container => {
        container.children?.some(field => {
          if (field.key === this.payload.name) {
            this.parent = field.formControl;

            return true;
          }
        });

        return !!this.parent;
      });
    }

    this.setInitialState();
    this.start();
  }

  private start(): void {
    if (!this.parent) {
      console.log(`Parent field: ${this.payload.name} not found for field: ${this.validatedField.key}`);

      return;
    }

    this.validatedField.parentEventEmitter$ = new EventEmitter<any>();

    this.parent.valueChanges.subscribe(() => {
      this.setInitialState();
      this.validatedField.formControl.setValue(null);
      this.check();
    });

    this.validatedField.items$ = new Subject<any>();
    this.validatedField.items$.subscribe(items => {
      if (items.length > 0) {
        this.validatedField.attr.hidden = false;
        this.validatedField.attr.readonly = false;
      } else {
        this.setInitialState();
      }

      this.validatedField.formControl.setValue(null);
    });
  }

  private setInitialState(): void {
    if (!this.validatedField.formControl.value && this.validatedField.choices.length === 0) {
      switch (+this.payload.disabledType) {
        case 1:
          this.validatedField.attr.hidden = true;
          break;
        case 2:
          this.validatedField.attr.readonly = true;
          break;
      }
    }
  }

  private check(): void {
    if (!this.parent.disabled) {
      this.validatedField.parentEventEmitter$.emit(this.parent.value);
    }
  }
}
