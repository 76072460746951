import { EventEmitter, Injectable } from '@angular/core';
import { FormSchema } from '../interface/form-schema';
import { ControlBuilder } from './control-builder';
import { FormGroup } from '@angular/forms';

@Injectable({providedIn: 'root'})
export class FormBuilder {
  constructor(
    private controlBuilder: ControlBuilder,
  ) {}

  public build(form: FormSchema): void {
    form.formGroup = new FormGroup({});
    form.parentEventEmitter$ = new EventEmitter();

    this.controlBuilder.buildForm(form.children, form, form, true);
    form.formGroup.updateValueAndValidity();
  }
}
