import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

export const ENV = new InjectionToken<string>('ENV');

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  private readonly API_PATH = 'api/';
  private readonly PUBLIC_PATH = 'public/';
  private readonly API_V2_PATH = 'apiv2/';

  constructor(
    @Inject(ENV) private environment: any = {},
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  getBaseUrl(forPath?: string): string {
    let url = this.get('url');

    if (window.location.protocol === 'https:') {
      url = url.replace('http:', 'https:');
    }

    return url + this.prepareAdditionalPath(forPath);
  }

  getFrontendUrl(forPath?: string): string {
    let url = this.get('frontendUrl');

    if (window.location.protocol === 'https:') {
      url = url.replace('http:', 'https:');
    }

    return url + this.prepareAdditionalPath(forPath);
  }

  getApiUrl(forPath?: string): string {
    return (
      `${this.getBaseUrl()}${this.API_PATH}` + this.prepareAdditionalPath(forPath)
    );
  }

  getApiV2Url(forPath?: string): string {
    return (
      `${this.getBaseUrl()}${this.API_V2_PATH}` + this.prepareAdditionalPath(forPath)
    );
  }

  getPublicUrl(forPath?: string):string {
    return (
      `${this.getBaseUrl()}${this.PUBLIC_PATH}` + this.prepareAdditionalPath(forPath)
    );
  }

  get(name: string): any {
    return this.environment[name];
  }

  update(name: string, value: any): void {
    this.environment[name] = value;
  }

  async init(environment: any): Promise<void> {
    this.environment = environment;
    return;
  }

  public isServerSide(): boolean {
    return isPlatformServer(this.platform);
  }

  public isWebsite(): boolean {
    return this.environment.isWebsite || false;
  }

  private prepareAdditionalPath(path?: string): string {
    let localPath = '';
    if (path) {
      localPath = path;
      if (localPath.charAt(0) === '/') {
        localPath = localPath.slice(1);
      }
    }
    return localPath;
  }
}
