export interface BasicSystemInfo {
  instance: {
    symbol: string;
    name: string;
    cssBothMode: string;
    cssLightMode: string;
    cssDarkMode: string;
    topMenu: boolean;
    canChangePassword: boolean;
    moodleForceRedirect: boolean,
    logoUrl: string;
  }
}
