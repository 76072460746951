import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService, HttpService, LinkService } from '@frontend/core';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReplacementService {
  constructor(
    private httpService: HttpService,
    private envService: EnvService,
    private activatedRoute: ActivatedRoute,
    private linkService: LinkService
  ) {}

  getMeetingName(meetingId?: number): Observable<any> {
    if (!meetingId) {
      meetingId = this.linkService.params['meetingId'];
    }

    const url = this.envService.getApiUrl(`/meeting/${meetingId}/info`);

    return this.httpService.get(url).pipe(
      shareReplay(1),
      map(resp => resp.name)
    );
  }

  getWebsiteName(websiteId?: number): Observable<any> {
    if (!websiteId) {
      websiteId = this.linkService.params['websiteId'];
    }

    const url = this.envService.getApiUrl(`/website/${websiteId}/info`);

    return this.httpService.get(url).pipe(
      shareReplay(1),
      map(resp => resp.name)
    );
  }
}
