import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textEllipsis'
})
export class TextEllipsisPipe implements PipeTransform {
  transform(value: string, ...args: TextEllipsis[]): any {
    let length = args.length > 0 ? args[0].length : value.length;
    let showDots = false;

    if (value.length > length) {
      showDots = true;
      length -= 3;
    }

    return value.substring(0, length) + (showDots ? "..." : "");
  }
}

interface TextEllipsis {
  length: number;
}
