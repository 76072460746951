<div class="d-flex spaced-items align-items-center">
  <div>
    <div
      class="text-color-grey mb-10px"
    >{{ meetingNight.map.get('date').formControl.value.date | dateFormat: dateFormatSettings.hotelNight }}</div>
    <app-input-checkbox
      [formSchema]="toggler"
      [globalForm]="globalForm"
      [disableWhenUnchecked]="true"
    ></app-input-checkbox>
  </div>

  <app-input-select
    *ngIf="toggler.formControl.value"
    [formSchema]="hotels"
    [globalForm]="globalForm"
    [disabled]="disabled"
  ></app-input-select>
  <app-input-select
    *ngIf="toggler.formControl.value"
    [formSchema]="rooms"
    [globalForm]="globalForm"
    [disabled]="disabled"
  ></app-input-select>
  <div class="text-nowrap">{{ price }}</div>
</div>
