import { Injectable } from '@angular/core';
import { CachedMeeting } from '../interface/cached-meeting';
import { Subject } from 'rxjs';
import { MeetingMenu } from '../../../layout/link/menu/menu-config/meeting-menu';
import { CampaignMenu } from "../../../layout/link/menu/menu-config/campaign-menu";
import { ReplacementService } from '../../../layout/service/replacement.service';
import { MeetingTypeId } from '../const/meeting-type-id';
import { UserStorageService } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class MeetingCachingListService {
  list: {
    [MeetingTypeId.EVENT]: Array<CachedMeeting>,
    [MeetingTypeId.TRAINING]: Array<CachedMeeting>,
    [MeetingTypeId.CAMPAIGN]: Array<CachedMeeting>
  } = {1: [], 2: [], 3: []};
  changeEventList: Subject<any> = new Subject<any>();

  constructor(
    private replacementService: ReplacementService,
    private userStorageService: UserStorageService
  ) {
    this.userStorageService.userChange.subscribe(() => this.clear());
  }

  add(meetingId: number, meetingTypeId: number): void {
    if (this.findCachedMeeting(meetingId, meetingTypeId)) {
      return;
    }

    if (this.list[meetingTypeId].length >= 5) {
      this.list[meetingTypeId].pop();
    }

    this.replacementService.getMeetingName(meetingId).subscribe(meetingName => {
      const meetingItem: CachedMeeting = {
        id: meetingId,
        name: meetingName,
        menuListItems: this.createMeetingMenu(meetingId, meetingTypeId),
        active: true,
        expanded: true,
        meetingTypeId: meetingTypeId
      };

      this.list[meetingTypeId].unshift(meetingItem);

      this.changeEventList.next(meetingTypeId);
    });
  }

  updateCachedMeetingName(meetingId: number, meetingTypeId: number, meetingName: string): void {
    this.list[meetingTypeId].forEach((cachedMeeting: CachedMeeting) => {
      if (cachedMeeting.id === meetingId && cachedMeeting.name !== meetingName) {
        cachedMeeting.name = meetingName;
        this.changeEventList.next(meetingTypeId);
      }
    });
  }

  private findCachedMeeting(meetingId: number, meetingTypeId: number): CachedMeeting|null {
    return this.list[meetingTypeId].filter((cachedMeeting: CachedMeeting) => cachedMeeting.id === meetingId).pop();
  }

  private createMeetingMenu(meetingId, meetingTypeId): Array<any> {
    const menu = (meetingTypeId === MeetingTypeId.CAMPAIGN ? CampaignMenu : MeetingMenu);
    const newMenu = [];
    menu.forEach((value, index) => {
      const element = Object.assign({}, value);
      element.params = {
        'meetingId': meetingId,
        'meetingTypeId': meetingTypeId
      };
      newMenu[index] = element;
    });

    return newMenu;
  }

  private clear(): void {
    this.list[MeetingTypeId.EVENT] = [];
    this.list[MeetingTypeId.TRAINING] = [];
    this.list[MeetingTypeId.CAMPAIGN] = [];
  }
}
