/**
 * @frontend/interfaces defined in frontend/tsconfig.json
 */
export * from './interfaces/user';
export * from './interfaces/error';
export * from './interfaces/link';
export * from './interfaces/agenda-day';
export * from './interfaces/session';
export * from './interfaces/basic-data-meeting';
export * from './interfaces/basic-data-company';
export * from './interfaces/basic-system-info';
export * from './interfaces/company-file';
export * from './interfaces/menu-method';
export * from './interfaces/moodle';
export * from './interfaces/session-time';
export * from './interfaces/speaker';
