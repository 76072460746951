import { CustomOptions, FormSchema } from '@frontend/form';
import * as moment from 'moment';
import { Moment } from 'moment';
import cloneDeep from 'lodash-es/cloneDeep';

export class DynamicDateRange extends CustomOptions {
  startDateSourceField: FormSchema;
  endDateSourceField: FormSchema;
  dateFormat: string;

  separator: string;
  dayFormat: string;
  monthFormat: string;
  yearFormat: string;

  sourceConfig = [
    {key: 'startDateSourceFieldSymbol', propertyName: 'startDateSourceField'},
    {key: 'endDateSourceFieldSymbol', propertyName: 'endDateSourceField'},
  ];

  protected init(): void {
    this.validatedField.formPrototype = cloneDeep(this.validatedField.children);

    this.sourceConfig.forEach(source => {
      const controlField = this.findControlField(this.payload[source.key]);

      if (!controlField) {
        console.error(`Field '${this.payload[source.key]}' not found`);
        return;
      }

      this[source.propertyName] = controlField;

      setTimeout(() => {
        controlField.formControl.valueChanges.subscribe(() => this.update());
      });
    });

    if (!this.startDateSourceField.value && !this.endDateSourceField.value) {
      this.removeExistingFields();
    }

    this.initDateFormat();

    if (this.startDateSourceField.disabled && this.endDateSourceField.disabled) {
      setTimeout(() => this.updateLabels());
    } else {
      this.update();
    }
  }

  private initDateFormat(): void {
    this.dateFormat = this.payload.dateFormat;

    ['.', '/', '-'].some(separator => {
      if (this.dateFormat.includes(separator)) {
        const splitFormat = this.dateFormat.split(separator);
        this.separator = separator;
        this.dayFormat = splitFormat[0];
        this.monthFormat = splitFormat[1];
        this.yearFormat = splitFormat[2];

        return true;
      }
    });
  }

  private update(): void {
    this.removeExistingFields();

    const startDate = moment(this.startDateSourceField.formControl.value);
    const endDate = moment(this.endDateSourceField.formControl.value);
    if (!startDate || !endDate) {
      return;
    }

    const clonedFields = [];
    const diff = endDate.diff(startDate, 'days');

    for (let i = -1; i <= diff; i++) {
      cloneDeep(this.validatedField.formPrototype).map((clonedField: FormSchema) => {
        this.modifyLabel(clonedField, startDate, i);
        clonedFields.push(clonedField);
      });
    }

    this.controlBuilder.create(clonedFields, this.validatedField, this.globalSchema);
  }

  private updateLabels(): void {
    const startDate: Moment = this.startDateSourceField.formControl.value;
    const endDate: Moment = this.endDateSourceField.formControl.value;
    if (!startDate || !endDate) {
      return;
    }

    let i = -1;
    this.validatedField.children.forEach((field: FormSchema) => {
      this.modifyLabel(field, startDate, i);
      i++;
    });
  }

  private modifyLabel(field: FormSchema, startDate: Moment, i: number): void {
    const fromDate = cloneDeep(startDate).add(i, 'day');
    const toDate = cloneDeep(startDate).add(i + 1, 'day');

    field.label += ' ' +
      (fromDate.format(this.monthFormat) === toDate.format(this.monthFormat)
        ? fromDate.format(this.dayFormat)
        : fromDate.format(this.dayFormat + this.separator + this.monthFormat))
      + '/' + toDate.format(this.dateFormat);

    field.key += 'date' + fromDate.format('YYYY-MM-DD');
  }

  private removeExistingFields(): void {
    // @ts-ignore
    for (const fieldSymbol of Object.keys(this.validatedField.formControl.controls)) {
      // @ts-ignore
      this.validatedField.formControl.removeControl(fieldSymbol);
    }
    this.validatedField.children = [];
  }
}
