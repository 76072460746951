import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { CompanyFile } from '@frontend/interfaces';
import { EnvService } from './env.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TopMenuContentService {
  topMenuLoad: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpService,
    private env: EnvService,
  ) {}

  get myFilesData(): Observable<CompanyFile[]> {
    return this.http.get(this.env.getApiUrl('participant/meeting/all-my-file/list')).pipe(map(files => files.data));
  }

  get materialsData(): Observable<CompanyFile[]> {
    return this.http.get(this.env.getApiUrl('participant/file/list')).pipe(map(materials => materials.data));
  }

  get speakers(): Observable<any[]> {
    return this.http.get(this.env.getApiUrl(`speaker/list?topMenu=true&sortBy=country`)).pipe(map(resp => resp.data));
  }
}
