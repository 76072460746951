import { Component, Input } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  host: {'class': 'form-input'},
})
export class InputNumberComponent extends InputAbstract {
  @Input() min: number;
  @Input() max: number;
}
