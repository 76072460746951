<div *ngIf="isVisible && formSchema.formControl">
  <mat-form-field>
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <mat-label *ngIf="!isFromEntity">{{ formSchema.label }}</mat-label>

    <input
      matInput
      id="email"
      placeholder="{{ formSchema.label }}"
      [ngClass]="addClass"
      [type]="'email'"
      [formControl]="$any(formSchema.formControl)"
      [readonly]="formSchema.attr?.readonly ?? false"
      [attr.min]="formSchema.attr?.min"
      [attr.max]="formSchema.attr?.max"
    >
  </mat-form-field>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</div>
