import { AbstractControl, ValidatorFn } from '@angular/forms';

export class NpwzDoctorValidator {
  static validator(): ValidatorFn {
    return (control: AbstractControl) => {
      const npwz = control.value;

      if (!npwz) {
        return;
      }

      if (npwz.length != 7) {
        return {invalidDoctorLicenceNumber: true};
      }

      let sum = 0;
      for (let index = 1; index < 7; index++) {
        sum += npwz[index] * index;
      }

      if (sum % 11 != npwz[0]) {
        return {invalidDoctorLicenceNumber: true};
      }
    };
  }
}
