import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'lib-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.css']
})
export class InfiniteScrollComponent implements AfterViewInit, AfterContentChecked {
  content: Element;
  contentClass = '.infinite-scroll-content';

  @Output() startLoadingEvent = new EventEmitter<any>();
  @Input() disabled = false;
  loading = false;
  lastHeight = 0;
  updateScrollPosition = false;

  reset() {
    this.loading = false;
    this.lastHeight = 0;
    this.updateScrollPosition = false;
    this.disabled = false;
    this.ngAfterViewInit();
  }

  ngAfterViewInit(): void {
    this.content = document.querySelector(this.contentClass);
    this.content.scrollTop = this.content.scrollHeight;
    (<any>this.content).onscroll = $event => this.onScroll(this.content.scrollTop);
  }

  ngAfterContentChecked() {
    if (this.updateScrollPosition && this.content) {
      this.updateScrollPosition = false;
      const addedElementHeight =
        this.content.scrollHeight - this.lastHeight - this.lastHeight;
      console.log({
        sh: this.content.scrollHeight,
        lh: this.lastHeight,
        nh: addedElementHeight,
        scrollTop: this.content.scrollTop,
        consten: this.content
      });

      if (addedElementHeight >= this.content.scrollTop) {
        console.log(
          'scroll to :' + (addedElementHeight + this.content.scrollTop + 50)
        );

        this.content.scrollTo({
          top: addedElementHeight + this.content.scrollTop + 50
        });
      } else {
        this.content.scrollTo({
          top: this.content.scrollTop - this.lastHeight + 50
        });
      }
    }
  }

  stopLoading() {
    this.loading = false;
    this.updateScrollPosition = true;
  }

  startLoading() {
    if (!this.loading) {
      this.lastHeight = this.content.scrollHeight;
      this.loading = true;
      this.startLoadingEvent.emit();
    }
  }

  private onScroll(position) {
    if (!this.disabled && position == 0) {
      this.startLoading();
    }
  }
}
