import { EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { SelectChoice } from './select-choice';

export interface FormSchema {
  additionalData?: {
    option?: string;
    meetingId?: number;
    targetUserEmail?: string;
    targetUserId?: number;
    verificationCode?: string;
    hotelRooms?: Array<FormSchema>;
    participantId?: any;
    command?: string;
    triggers?: string;
  };
  attr?: Attr;
  availableLanguages?: FormSchema;
  buttonStagesCompeted?: boolean;
  children?: Array<FormSchema>;
  choices?: Array<any>;
  config?: {
    type?: string;
    userField?: string;
  }
  consent?: string;
  constraints?: Array<Constraint>;
  containerId?: string;
  contractId?: number;
  currentPage?: number;
  dataListTypes?: Array<{id: string, symbol: string}>;
  description?: string;
  disabled?: boolean;
  dynamic?: any;
  expanded?: boolean;
  fieldId?: number;
  fields?: Array<any>;
  fieldTypes?: Array<{id: string, symbol: string}>;
  fieldValidators?: Array<any>;
  formControl?: AbstractControl;
  formFieldPrototype?: Array<FormSchema>;
  formFieldValidators?: Array<FormSchema>;
  formGroup?: FormGroup;
  formId?: number;
  formPrototype?: Array<FormSchema>;
  formStagesCompleted?: boolean;
  format?: {
    asXlsx?: boolean;
    asPdf?: boolean;
  },
  id?: number;
  initialsAvatarData?: {
    firstName: string;
    lastName: string;
    seed: string;
    words: string[];
  };
  isBuilt?: boolean;
  isControlField?: boolean;
  isDependent?: boolean;
  isDynamic?: boolean;
  isFormFromEntity?: boolean;
  isSubmissionPreview?: boolean;
  items$?: Subject<any>;
  key?: string;
  label?: string;
  language?: string;
  loading?: boolean;
  map?: Map<any, FormSchema>;
  meetingServiceFormFieldOptionPrototype?: Array<FormSchema>;
  meetingServiceOptions?: Array<SelectChoice>;
  multiple?: boolean;
  name?: string;
  options?: {
    allow_add?: boolean;
    allow_delete?: boolean;
    isCustomBuilt?: boolean;
    dataUrl?: string;
    empty_label?: string;
    tagValidator?: string;
    value?: any;
  };
  page?: number;
  pageChanged$?: EventEmitter<any>;
  pageCount?: number;
  parent?: FormSchema;
  parentEventEmitter$?: EventEmitter<any>;
  redirectLink?: string;
  reloadAfterSubmission?: boolean;
  requiredButtonStagesText?: string;
  requiredFormStagesText?: string;
  selectedChoice?: any;
  selectedLanguage?: AbstractControl;
  showPassword?: boolean;
  successHeader?: string;
  successMessage?: string;
  failureHeader?: string;
  failureMessage?: string;
  symbol?: string;
  time?: number;
  translated?: boolean;
  type?: string;
  url?: string;
  value?: any;
  visibilityChanged$?: EventEmitter<any>;
}

export interface Attr {
  id?: string,
  asTable?: boolean;
  autocomplete?: boolean;
  autoSelect?: boolean;
  class?: string;
  color?: string;
  dateFormat?: string;
  deadlineDate?: Date;
  defaultImg?: string;
  deleteUrl?: string;
  disabled?: string;
  displayOptionsInOneLine?: boolean;
  endDate?: Date;
  errorMessage?: string;
  expansion?: boolean;
  extended?: boolean;
  forceLabelDisplay?: boolean;
  height?: number;
  hidden?: boolean;
  hideAddButton?: boolean;
  hideAddColumnButton?: boolean;
  hideDeleteButton?: boolean;
  hideLabel?: boolean;
  icon?: string;
  innerComponentClass?: string;
  keepChildren?: boolean;
  label?: string;
  labelPosition?: 'top';
  max?: number;
  maxlength?: number;
  min?: number;
  minlength?: number;
  minHeight?: boolean;
  noIcon?: boolean;
  positionName?: string;
  placeholder?: string;
  readMoreOnText?: string;
  readMoreOffText?: string;
  readMoreText?: string;
  readonly?: boolean;
  receiveMailing?: any;
  receiveSms?: any;
  receivePush?: any;
  receiveAll?: any;
  restricted?: boolean;
  slot?: string;
  sortableBox?: string;
  startDate?: Date;
  step?: string;
  timezones?: any;
  toggle?: boolean;
  translate?: boolean;
  translated?: boolean;
  type?: string;
  outerClass?: string;
  width?: number;
  withContainers?: boolean;
  wrapperClass?: string;
}

export interface Constraint {
  name: string;
  payload?: any;
}
