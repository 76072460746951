import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-initials-avatar-generator',
  templateUrl: './initials-avatar-generator.component.html',
  styleUrls: ['./initials-avatar-generator.component.scss']
})
export class InitialsAvatarGeneratorComponent implements OnInit, OnChanges {
  @Input() initials = null;
  @Input() wordArray = [];
  @Input() colorSeed: string = null;
  @Input() bgColor: string = null;
  @Input() saturation = 70;
  @Input() lightness = 55;
  @Input() fontColor = 'white';
  @Input() avatarSrc = null;
  @Input() borderColor: string = null;
  @Input() borderSize = 2;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() fontSize = '16px';

  style = {
    'background-color': this.bgColor,
    color: this.fontColor,
    'font-size': this.fontSize,
  };

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  private init() {
    this.updateColor();

    if (this.colorSeed != null) {
      this.colorGenerator();
    }

    if (this.wordArray.length === 0 && (this.firstName || this.lastName)) {
      this.initialGeneratorFirstAndLastName();
    } else if (this.wordArray.length > 0) {
      this.initialsGenerator();
    }

    this.style['font-size'] = this.fontSize;
  }

  private updateColor() {
    this.style['background-color'] = this.bgColor;
    this.style['color'] = this.fontColor;
    if (this.borderColor) {
      this.style['border'] = this.borderSize + 'px solid ' + this.borderColor;
    }
  }

  private colorGenerator() {
    let hash = 0;
    for (let i = 0; i < this.colorSeed.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = this.colorSeed.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    this.bgColor = 'hsl(' + h + ', ' + this.saturation + '%, ' + this.lightness + '%)';
    this.updateColor();
  }

  private initialsGenerator(): any {
    this.initials = '';
    this.wordArray.forEach(elem => {
      elem
        .trim()
        .split(' ')
        .filter(e => e.trim().length > 0)
        .forEach(word => {
          this.initials += word.trim().substring(0, 1);
        });
    });
  }

  private initialGeneratorFirstAndLastName(): void {
    let firstName = '';
    let lastName = '';
    if (this.firstName) {
      firstName = this.firstName.split('')[0].trim().substring(0, 1)
    }
    if (this.lastName) {
      lastName = this.lastName.split('')[0].trim().substring(0, 1)
    }
    this.initials = firstName + lastName;
  }
}
