<ng-container *ngIf="isVisible">
  <mat-radio-button
    [disabled]="formSchema.disabled || formSchema?.parent?.attr?.readonly || (!!formSchema?.attr?.disabled)"
    slot="start"
    [value]="formSchema.options['value'] || null"
  ><span *ngIf="formSchema.label" [innerHTML]="formSchema.label | safeHtml"></span><button
    *ngIf="formSchema.attr.readMoreText"
    type="button"
    class="read-on-button"
    (click)="toggleReadMore()"
  >{{ readMoreIsOn ? formSchema.attr.readMoreOffText : formSchema.attr.readMoreOnText }}</button></mat-radio-button>
  <div
    *ngIf="formSchema.attr.readMoreText && readMoreIsOn"
    class="read-on-text"
    [innerHTML]="formSchema.attr.readMoreText | safeHtml"
  ></div>
</ng-container>
