import { Profile } from '../../../../apps/vinci/src/app/meeting/profile-list/interface/profile';

export interface FormSubmissionResponseData {
  blockingComplianceForm?: {
    formName: string,
    formFilledOut: number,
  };
  canCompleteTestAgain?: boolean;
  forcedMessageDisplay?: boolean;
  formFilledOut?: number;
  maxPoint?: number;
  message?: string;
  passingScore?: number;
  passLimit?: number;
  paymentUrl?: string;
  points?: number;
  profile?: Profile;
  score?: number;
  success: boolean;
  token?: string;
  triggers?: string[];
}
