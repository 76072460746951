import { Injectable } from '@angular/core';
import { ChatService } from './chat.service';
import { ChatStorageService } from './chat-storage.service';
import { skip } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LanguagePipe, LoadingController } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class ChatJoinService {
  constructor(
    private chatService: ChatService,
    private chatStorageService: ChatStorageService,
    private router: Router,
    private languagePipe: LanguagePipe,
    private loadingController: LoadingController
  ) {}

  async createPrivateChat(userId: number) {
    await this.startLoading();
    this.chatService.createPrivateChat(userId);
  }

  async joinMeetingChat(meetingId: number) {
    await this.startLoading();
    this.chatService.joinMeetingChat(meetingId);
  }

  async joinSessionChat(sessionId: number) {
    await this.startLoading();
    this.chatService.joinSessionChat(sessionId);
  }

  private async startLoading() {
    const alert = await this.loadingController.create({});
    await alert.present();
    const sub = this.chatStorageService.chatsChange
      .pipe(skip(1))
      .subscribe(() => {
        this.loadingController.dismiss();
        this.chatStorageService.chats.forEach(chat => {
          if (chat.chatInfo.navigateToThisChat) {
            this.router.navigate([
              this.languagePipe.transform(`/chat/${chat.chatInfo.id}`)
            ]);
          }
        });
        sub.unsubscribe();
      });
  }
}
