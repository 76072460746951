<ng-container *ngIf="isVisible">
  <mat-form-field [class]="formSchema.attr?.class">
    <mat-label *ngIf="!isFromEntity">{{ formSchema.label }}</mat-label>

    <input matInput [formControl]="$any(formSchema.formControl)" [format]="24" [ngxMatTimepicker]="picker">
    <mat-icon matSuffix (click)="picker.open()">watch_later</mat-icon>
  </mat-form-field>

  <ngx-mat-timepicker #picker></ngx-mat-timepicker>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</ng-container>
