<div *ngIf="isVisible">
  <ng-container *ngIf="isFromEntity || forceLabelDisplay">
    <app-custom-label
      [formSchema]="formSchema"
      [globalForm]="globalForm"
      [forceDisplay]="forceLabelDisplay"
    ></app-custom-label>
  </ng-container>

  <ng-container *ngIf="formSchema.attr?.expansion">
    <mat-accordion
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      [cdkDropListDisabled]="!formSchema.attr.sortableBox"
    >
      <div cdkDrag *ngFor="let row of formSchema.children; let idx = index" [attr.class]="formSchema.attr.class">
        <mat-expansion-panel
          id="panel-{{ idx }}"
          [expanded]="step === idx"
          (opened)="setStep(idx)"
          hideToggle
        >
          <mat-expansion-panel-header collapsedHeight="72px">
            <mat-panel-title>
              <div *ngIf="formSchema.attr.sortableBox" class="move-button">
                <button mat-icon-button cdkDragHandle (click)="stop($event)">
                  <mat-icon>open_with</mat-icon>
                </button>
              </div>
              {{ row.children[0].formControl.value }}
            </mat-panel-title>

            <mat-panel-description>
              <div class="ion-text-right description-div">
                <div *ngIf="formSchema.options.allow_delete && !formSchema.attr.hideDeleteButton" class="right">
                  <button mat-mini-fab (click)="remove(row)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <app-group
            *ngIf="step === idx"
            [group]="row"
            [globalForm]="globalForm"
            [ngClass]="[row.attr?.class ? row.attr.class : '', row.attr?.hidden ? 'hide' : '']"
          ></app-group>

          <mat-action-row>
            <button type="button" mat-flat-button color="warn" (click)="prevStep()">
              {{ 'messages.previous' | translate }}
            </button>
            <button type="button" mat-flat-button color="primary" (click)="nextStep()">
              {{ 'messages.next' | translate }}
            </button>
          </mat-action-row>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </ng-container>

  <div *ngIf="formSchema.attr?.asTable" class="simple">
    <div
      *ngIf="formSchema.children && formSchema.children[0]"
      [ngClass]="{
        'with-delete': formSchema.options.allow_delete && !formSchema.attr.hideDeleteButton,
        'table-header': true
      }"
    >
      <div *ngFor="let row of formSchema.children[0].children">{{ row.label }}</div>
    </div>

    <div *ngFor="let row of formSchema.children" [attr.class]="formSchema.attr.class">
      <div
        [ngClass]="{
          'with-delete': formSchema.options.allow_delete && !formSchema.attr.hideDeleteButton,
          'as-table': true
        }"
      >
        <app-group
          [group]="row"
          [globalForm]="globalForm"
          [ngClass]="[row.attr.class ? row.attr.class : '', row.attr.hidden ? 'hide' : '']"
        ></app-group>
      </div>
      <div
        *ngIf="formSchema.options.allow_delete && !formSchema.attr.hideDeleteButton"
        class="delete"
      >
        <button type="button" mat-icon-button color="negative" (click)="remove(row)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="formSchema.attr.withContainers" class="simple">
    <div *ngFor="let instance of formSchema.children" style="display: flex; flex-direction: column;">
      <app-group
        *ngFor="let container of instance.children"
        [group]="container"
        [globalForm]="globalForm"
        class="d-flex width-full"
      ></app-group>

      <ng-container *ngIf="formSchema.options.allow_delete && !formSchema.attr.hideDeleteButton">
        <button type="button" mat-icon-button color="negative" style="flex: 1" (click)="remove(instance)">
          <mat-icon>delete</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="!formSchema.attr || (!formSchema.attr.withContainers && !formSchema.attr.expansion && !formSchema.attr.asTable)"
    [ngClass]="formSchema.attr?.outerClass"
    class="simple"
  >
    <div *ngFor="let row of formSchema.children" class="collection-element-wrapper">
      <app-group
        [group]="row"
        [globalForm]="globalForm"
        [ngClass]="[row.attr?.class ?? '', row.attr?.hidden ? 'hide' : '', 'collection-group']"
      ></app-group>
      <ng-container *ngIf="formSchema.options.allow_delete && !formSchema.attr.hideDeleteButton">
        <button type="button" mat-icon-button color="negative" (click)="remove(row)">
          <mat-icon>delete</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="formSchema.options.allow_add && !formSchema.attr.hideAddButton">
    <button type="button" mat-mini-fab class="m-10px" (click)="add()">
      <mat-icon>add</mat-icon>
    </button>
  </ng-container>
</div>
