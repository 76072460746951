<ng-container *ngIf="control.errors && control.touched">
  <span *ngFor="let k of Object.keys(control.errors)" class="error">
    <ng-container *ngIf="isType(control.errors[k], 'string')">
      {{ control.errors[k] }}
    </ng-container>
    <ng-container *ngIf="!isType(control.errors[k], 'string')">
      {{ getTranslationKey(k, control.errors[k]) }}
    </ng-container>
  </span>
</ng-container>
