import {Component, Injectable, OnInit} from '@angular/core';
import {FormSubmitService} from "@frontend/form";
import {FileUploadProgressController} from "@frontend/core";

@Component({
  selector: 'lib-file-upload-progress',
  templateUrl: './file-upload-progress.component.html',
  styleUrls: ['./file-upload-progress.component.css']
})

@Injectable({
  providedIn: 'root'
})

export class FileUploadProgressComponent implements OnInit {
  percentDone: number;
  total: string;
  eta: string;

  constructor(
    protected formSubmitService: FormSubmitService,
    protected fileUploadProgressController: FileUploadProgressController
  ) {
    this.percentDone = 0;
    this.total = '';
    this.eta = '';
  }

  ngOnInit(): void {
    this.formSubmitService.progressUpload.subscribe(data => {
      this.percentDone = data.percentDone;
      this.eta = data.eta;
      this.total = data.total;
    });
  }

  close() {
    this.fileUploadProgressController.dismiss().then();
  }
}
