<div *ngIf="isVisible" [ngClass]="['d-flex', 'justify-content-center', formSchema.attr.innerComponentClass ?? '']">
  <button
    mat-flat-button
    [id]="formSchema.key"
    [type]="formSchema.attr.type ?? 'submit'"
    [ngClass]="[formSchema.attr.class ?? 'mat-blue']"
  >
    <mat-icon *ngIf="!formSchema.attr.noIcon">
      {{ formSchema.attr.icon ?? 'save' }}
    </mat-icon>
    <span *ngIf="formSchema.label" [innerHTML]="formSchema.label | safeHtml"></span>
  </button>
</div>
