import {LinkList} from "../../link-list/link-list";
import {MenuListSection} from "../../../module/menu/interface/menu-list-item";

export const ParticipantTopMenu: MenuListSection[] = [
  {
    menuListItems: [
      {
        link: LinkList.participant.topMenu,
      },
      {
        link: LinkList.participant.meetingList,
        content: 'Event',
        activatingPaths: [
          LinkList.participant.info,
          LinkList.participant.meetingPreview,
          LinkList.participant.meetingPreviewInfo,
          LinkList.participant.meetingPreviewAgenda,
          LinkList.participant.meetingPreviewSpeakers,
          LinkList.participant.meetingRegister,
          LinkList.participant.meeting,
          LinkList.participant.questionnaire.list,
          LinkList.participant.test.list,
          LinkList.participant.agenda,
          LinkList.participant.agendaSession,
          LinkList.participant.speaker.info,
          LinkList.participant.speaker.list,
          LinkList.participant.file.list,
          LinkList.participant.myFile.list,
          LinkList.participant.meetingVideo.list,
          LinkList.participant.participant.info,
          LinkList.participant.participant.list,
          LinkList.participant.gallery,
          LinkList.participant.meetingMessage.list,
          LinkList.participant.videoStream
        ]
      },
      {
        link: LinkList.participant.trainingList,
        content: 'Training',
        activatingPaths: [
          LinkList.participant.training,
          LinkList.participant.participantTraining.info,
          LinkList.participant.participantTraining.meetingPreview,
          LinkList.participant.participantTraining.meetingPreviewInfo,
          LinkList.participant.participantTraining.meetingPreviewAgenda,
          LinkList.participant.participantTraining.meetingPreviewSpeakers,
          LinkList.participant.participantTraining.meetingRegister,
          LinkList.participant.participantTraining.questionnaire.list,
          LinkList.participant.participantTraining.test.list,
          LinkList.participant.participantTraining.agenda,
          LinkList.participant.participantTraining.agendaSession,
          LinkList.participant.participantTraining.speaker.info,
          LinkList.participant.participantTraining.speaker.list,
          LinkList.participant.participantTraining.file.list,
          LinkList.participant.participantTraining.myFile.list,
          LinkList.participant.participantTraining.meetingVideo.list,
          LinkList.participant.participantTraining.participant.info,
          LinkList.participant.participantTraining.participant.list,
          LinkList.participant.participantTraining.gallery,
          LinkList.participant.participantTraining.meetingMessage.list,
          LinkList.participant.participantTraining.videoStream
        ]
      },
      {
        link: LinkList.participant.campaignList,
        content: 'Campaign',
        activatingPaths: [
          LinkList.participant.campaign,
          LinkList.participant.participantCampaign.info,
          LinkList.participant.participantCampaign.meetingPreview,
          LinkList.participant.participantCampaign.meetingPreviewInfo,
          LinkList.participant.participantCampaign.meetingPreviewAgenda,
          LinkList.participant.participantCampaign.meetingPreviewSpeakers,
          LinkList.participant.participantCampaign.meetingRegister,
          LinkList.participant.participantCampaign.meeting,
          LinkList.participant.participantCampaign.questionnaire.list,
          LinkList.participant.participantCampaign.test.list,
          LinkList.participant.participantCampaign.agenda,
          LinkList.participant.participantCampaign.speaker.info,
          LinkList.participant.participantCampaign.speaker.list,
          LinkList.participant.participantCampaign.file.list,
          LinkList.participant.participantCampaign.myFile.list,
          LinkList.participant.participantCampaign.meetingVideo.list,
          LinkList.participant.participantCampaign.participant.info,
          LinkList.participant.participantCampaign.participant.list,
          LinkList.participant.participantCampaign.gallery,
          LinkList.participant.participantCampaign.meetingMessage.list,
          LinkList.participant.participantCampaign.videoStream
        ]
      }
    ]
  }
]


