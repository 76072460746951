import { Injectable } from '@angular/core';
import { AlertConfig } from '../interface/alert-config';
import { AlertControllerInterface } from '../interface/alert-controller.interface';

@Injectable({
  providedIn: 'root'
})
export class AlertController {
  public alertController: AlertControllerInterface;

  async create(config: AlertConfig) {
    return this.alertController.create(config);
  }
}
