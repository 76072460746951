import { Component, OnInit } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-submit-with-example',
  templateUrl: './submit-with-example.component.html',
  styleUrls: ['./submit-with-example.component.scss'],
  host: {'class': 'form-input'},
})
export class SubmitWithExampleComponent extends InputAbstract implements OnInit {
  fileType = "";
  fileName = "";
  fileBase64 = "";

  ngOnInit(): void {
    super.ngOnInit();

    const file = this.formSchema.options.value;
    if (typeof file.content !== "undefined") {
      this.fileName = file.name;
      this.fileType = file.contentType;
      this.fileBase64 = file.content;
    }
  }

  download(): void {
    if (this.fileBase64.length > 0) {
      const link = document.createElement("a");
      link.download = this.fileName;
      link.href = `data:${this.fileType};base64,${this.fileBase64}`;
      link.click();
    }
  }
}
