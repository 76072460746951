import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-response-message',
  templateUrl: './response-message.component.html',
  styleUrls: ['./response-message.component.scss']
})
export class ResponseMessageComponent {
  @Input() form: FormGroup;
  Object = Object;

  isType(val, type) {
    return typeof val === type;
  }
}
