import { Mode } from '../enum/mode';
import { BasicSystemInfo } from './basic-system-info';
import { BasicDataMeeting } from './basic-data-meeting';
import { BasicDataCompany } from './basic-data-company';
import { UserFilter } from './user-filter';

export interface User {
  actions?: string[];
  avatar?: string;
  changedMode?: Mode;
  companies?: BasicDataCompany[];
  companyName?: string;
  deviceId?: string;
  displayForm?: number;
  email: string;
  exp: number;
  firstName: string;
  iat: number;
  id: number;
  instance: BasicSystemInfo['instance'];
  language: string;
  lastName: string;
  login: string;
  loginAsList?: {id: number, name: string}[];
  meetingTypes: number[];
  meetings?: BasicDataMeeting[];
  mode?: Mode;
  roles: string[];
  seed?: string;
  selectedCompany?: number;
  selectedMeeting?: number;
  sessionLength: number,
  substituteUser?: number,
  token: string;
  unreadMsgCount?: number;
}
