import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ControlBuilder, FormSchema } from '@frontend/form';

@Injectable({
  providedIn: 'root'
})
export class CustomOptions {
  static IS_CUSTOM = true;

  constructor(
    protected validatedField: FormSchema,
    protected formGroup: FormGroup,
    protected payload: any,
    protected globalSchema: FormSchema,
    protected controlBuilder: ControlBuilder,
  ) {
    this.init();
  }

  protected init(): void {};

  protected findControlField(fieldSymbol: string): FormSchema|null {
    // for forms without containers
    let controlField = this.globalSchema.map.get(fieldSymbol);

    if (!controlField) {
      // for fields in the same container
      this.validatedField.parent.children.forEach(field => {
        if (field.key === fieldSymbol) {
          controlField = field;
        }
      });
    }

    if (!controlField) {
      // for fields in different containers (on composite field or form level)
      this.validatedField.parent.parent.children.forEach(container => {
        container.children?.forEach(field => {
          if (field.key === fieldSymbol) {
            controlField = field;
          }
        });
      });
    }

    if (!controlField) {
      // for user built forms
      controlField = this.iterateFormWithContainers(fieldSymbol, this.globalSchema.children);
    }

    if (!controlField && this.globalSchema.map.get('formFieldContainers')) {
      // for user built forms embedded in internal messages
      controlField = this.iterateFormWithContainers(fieldSymbol, this.globalSchema.map.get('formFieldContainers').children);
    }

    return controlField;
  }

  private iterateFormWithContainers(fieldSymbol: string, children: FormSchema[]): FormSchema|null {
    let controlField = null;

    children.forEach(element => {
      element.children?.forEach(field => {
        if (field.key === fieldSymbol) {
          controlField = field;
        }

        field.children?.forEach(componentContainer => {
          componentContainer.children?.forEach(innerContainer => {
            innerContainer.children?.forEach(innerField => {
              if (innerField.key === fieldSymbol) {
                controlField = innerField;
              }
            });
          });
        });
      });
    });

    return controlField;
  }
}
