export interface Moodle {
  enabled: boolean,
  url?: string,
  username?: string,
  key?: string,
  delay?: number,
}

export interface MoodleUserCourse {
  id: number,
  name: string,
  shortName: string,
  niceLink: string,
  progress?: number,
  score?: number,
  completed?: boolean,
  startDate?: Date,
  endDate?: Date,
  lastAccess?: Date,
  hasCertificate: boolean,
  badge?: Badge,
}

export interface Badge {
  recipientsDetails: {
    name: string
  },
  issuerDetails: {
    name: string,
    contact: string
  },
  badgeDetails: {
    name: string,
    image: string,
    course: string,
    version: string,
    language: string,
    description: string,
  },
  badgeExpiry: {
    issue: Date,
    expire: Date,
  },
  hasCertificate: boolean
}
