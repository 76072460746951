import { EventEmitter, Input, Output, Directive, OnInit, HostBinding } from '@angular/core';
import { FormSchema } from './form-schema';

@Directive()
export class InputAbstract implements OnInit {
  @Input() formSchema: FormSchema;
  @Input() globalForm: FormSchema;
  @Output() focusEvent = new EventEmitter();
  @HostBinding('class.is-visible') get isVisibleClass() {
    return this.isVisible;
  }

  readMoreIsOn: boolean;
  isFromEntity: boolean
  isVisible: boolean;
  isRequired: boolean;

  ngOnInit(): void {
    this.isFromEntity = this.globalForm.isFormFromEntity;
    this.setVisibility();
    this.globalForm.pageChanged$?.subscribe(() => this.setVisibility());
    this.globalForm.formGroup?.valueChanges.subscribe(() => this.setVisibility());
    this.isRequired = this.isFieldRequired(this.formSchema);
  }

  isValid(): boolean {
    return true;
  }

  toggleReadMore(): void {
    this.readMoreIsOn = !this.readMoreIsOn;
  }

  protected setVisibility(): void {
    this.isVisible = !this.globalForm.currentPage
      || this.formSchema.page === this.globalForm.currentPage && !this.formSchema.attr?.hidden;
  }

  private isFieldRequired(formSchema: FormSchema): boolean {
    let required = false;

    formSchema.constraints?.some(elem => {
      const constraint = elem.name.split('\\').pop();
      if (['NotNull', 'NotBlank'].includes(constraint)) {
        required = true;

        return true;
      }
    });

    return required;
  }
}
