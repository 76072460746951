<div *ngIf="isVisible" class="business-hours-single-day">
  <div class="label-content" [innerHTML]="labelText"></div>
  <app-input-time
    class="me-3"
    [formSchema]="formSchema.map.get('timeFrom')"
    [globalForm]="globalForm"
    [displayIcon]="false"
  ></app-input-time>
  <div class="business-hours-separator"> - </div>
  <app-input-time class="ms-3"
    [formSchema]="formSchema.map.get('timeTo')"
    [globalForm]="globalForm"
    [displayIcon]="false"
  ></app-input-time>
</div>
