import { LinkList } from '../../link-list/link-list';
import {MenuListSection} from "../../../module/menu/interface/menu-list-item";

export const MobileBeforeLoginMenu: MenuListSection[] = [
  {
    menuListItems: [{
      link: LinkList.mobile.login,

      activatingPaths: [LinkList.mobile.login]
    }]
  }
];
