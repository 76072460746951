import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import {
  CoreModule,
  AlertController as AlertControllerCore,
  LoadingController as LoadingControllerCore,
  FileUploadProgressController as FileUploadProgressControllerCore,
  ModalController as ModalControllerCore,
  ToastController as ToastControllerCore
} from '@frontend/core';
import { AlertController } from './gui-service/alert-controller/alert-controller.service';
import { AlertComponent } from './gui-service/alert-controller/alert/alert.component';
import { InfiniteScrollComponent } from './gui-service/infinite-scroll/infinite-scroll.component';
import { LoadingController } from './gui-service/loading-controller/loading-controller.service';
import { LoadingComponent } from './gui-service/loading-controller/loading/loading.component';
import { ModalController } from './gui-service/modal-controller/modal-controller.service';
import { SwipeToRefreshComponent } from './gui-service/swipe-to-refresh/swipe-to-refresh.component';
import { ToastController } from './gui-service/toast-controller/toast-controller.service';
import { ToastComponent } from './gui-service/toast-controller/toast/toast.component';
import { FileUploadProgressComponent } from './gui-service/file-upload-progress-controller/file-upload-progress/file-upload-progress.component';
import { FileUploadProgressController } from './gui-service/file-upload-progress-controller/file-upload-progress-controller.service';

@NgModule({
  declarations: [
    AlertComponent,
    InfiniteScrollComponent,
    LoadingComponent,
    FileUploadProgressComponent,
    SwipeToRefreshComponent,
    ToastComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    TranslateModule,
    CoreModule,
  ],
  providers: [
    { provide: AlertControllerCore, useClass: AlertController },
    { provide: LoadingControllerCore, useClass: LoadingController },
    { provide: FileUploadProgressControllerCore, useClass: FileUploadProgressController },
    { provide: ModalControllerCore, useClass: ModalController },
    { provide: ToastControllerCore, useClass: ToastController },
  ],
  exports: [
    SwipeToRefreshComponent,
    InfiniteScrollComponent,
    FileUploadProgressComponent,
  ],
})
export class MaterialWrapperModule {}
