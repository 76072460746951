import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserStorageService } from '@frontend/services';
import { CachedMeeting } from '../interface/cached-meeting';
import { PathService } from '../../../layout/service/path.service';
import { InjectableInMenuList } from '../../../layout/module/menu/interface/injectable-in-menu-list';
import { MenuListItem } from '../../../layout/module/menu/interface/menu-list-item';
import { MeetingCachingListService } from '../service/meeting-caching-list.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-menu-list-meetings',
  templateUrl: './menu-list-meetings.component.html',
  styleUrls: ['./menu-list-meetings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuListMeetingsComponent implements OnDestroy, OnInit, InjectableInMenuList {
  @Input() cachedMeetingList: CachedMeeting[] = new Array<CachedMeeting>();
  display: boolean;
  protected subscriptions: Array<Subscription> = [];

  constructor(
    protected meetingCachingListService: MeetingCachingListService,
    protected pathService: PathService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected userStorageService: UserStorageService,
  ) {}

  ngOnInit(): void {
    this.subscriptions = [];
    if (this.userStorageService.user.roles?.length > 0) {
      this.display = true;
    }

    this.userStorageService.userActionsChange.subscribe(() => {
      this.display = true;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  protected getNavigationEndSubscription(meetingTypeId: number): Subscription {
    return this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.refreshMeetingMenu(meetingTypeId));
  }

  protected refreshMeetingMenu(meetingTypeId: number): void {
    const cachedMeetingList = this.meetingCachingListService.list[meetingTypeId];

    cachedMeetingList.forEach((cachedMeeting) => {
      const links = [];

      cachedMeeting.menuListItems?.forEach((meetingMenuListItem: MenuListItem) => {
        links.push(meetingMenuListItem.link);
        meetingMenuListItem.activatingPaths?.forEach(activatingPath => links.push(activatingPath));
      });

      cachedMeeting.active = this.pathService.isCurrentLink(links, { 'meetingId': cachedMeeting.id });
      cachedMeeting.expanded = cachedMeeting.active;
    });

    this.cachedMeetingList = [...cachedMeetingList];

    this.cd.detectChanges();
  }
}
