import { Injectable } from '@angular/core';
import { FormSchema } from '@frontend/form';

@Injectable({
  providedIn: 'root'
})
export class RequiredFieldsService {
  isFieldRequired(formSchema: FormSchema): boolean {
    let required = false;

    if (formSchema.constraints?.length > 0) {
      formSchema.constraints.some(elem => {
        const constraint = elem.name.split('\\').pop();
        if (['NotNull', 'NotBlank'].includes(constraint)) {
          required = true;

          return true;
        }
      });
    }

    return required;
  }
}
