import { Injectable, OnDestroy } from '@angular/core';
import { ChatStorageService } from './chat-storage.service';
import { Subscription } from 'rxjs';
import { LocalNotificationService } from '../../base-components/notification/local-notification.service';
import { LocalNotificationType } from '../../base-components/notification/const/local-notification.type';
import { UserStorageService } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class ChatLocalNotificationService implements OnDestroy {
  private messageSubscription: Subscription;

  constructor(
    private chatStorageService: ChatStorageService,
    private localNotificationService: LocalNotificationService,
    private userStorageService: UserStorageService
  ) {}

  init() {
    this.messageSubscription = this.chatStorageService.newMessageBus.subscribe(
      message => {
        if (
          message.chatId != this.chatStorageService.currentChatId &&
          message.authorId != this.userStorageService.user.id
        ) {
          this.showNotification(message);
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  showNotification(message: { notificationTitle?: string; text; chatId }) {
    this.localNotificationService.add({
      title: message.notificationTitle,
      body: message.text,
      date: null,
      extra: {
        type: LocalNotificationType.chatMessage,
        chatId: message.chatId
      }
    }).then();
  }
}
