import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { AbstractInputDateComponent, MY_FORMATS } from '../abstract-input-date/abstract-input-date.component';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

@Component({
  selector: 'app-input-date-time',
  templateUrl: './input-date-time.component.html',
  styleUrls: ['./input-date-time.component.scss'],
  providers: [{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}],
  host: {'class': 'form-input'},
})
export class InputDateTimeComponent extends AbstractInputDateComponent implements OnInit {
  date: FormControl;
  time: FormControl;

  ngOnInit(): void {
    super.ngOnInit();

    this.initFormControl();

    this.formSchema.formControl.valueChanges.subscribe(value => {
      if (value) {
        this.setDate(value)
      } else {
        this.resetDateTime();
      }
    });

    // merge(this.date.valueChanges, this.time.valueChanges)
    //   .pipe(debounceTime(200))
    //   .subscribe(() => this.onDateChange(false));

    this.initEndDateAutofill();
  }

  private initFormControl(): void {
    this.date = new FormControl();
    this.time = new FormControl();

    if (this.formSchema.value) {
      this.setDate(this.formSchema.value);
    }
  }

  private setDate(value: string): void {
    const date = moment.utc(value);

    this.date.setValue(date, {emitEvent: false});

    const minutes = ('0' + date.minutes()).slice(-2);
    const hours = ('0' + date.hours()).slice(-2);
    this.time.setValue(`${hours}:${minutes}`, {emitEvent: false});
  }

  private onDateChange(external: boolean): void {
    let dateTime: Moment = null;

    if (this.date.value !== null) {
      dateTime = this.date.value;
      if (this.time.value) {
        const timePart = this.time.value.split(':');
        dateTime.hours(timePart[0]);
        dateTime.minutes(timePart[1]);
      }
    }

    this.formSchema.formControl.setValue(dateTime, {emitEvent: !external});
  }

  private resetDateTime(): void {
    this.date.setValue(null, {emitEvent: false});
    this.time.setValue(null, {emitEvent: false});
  }
}
