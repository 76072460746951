import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  host: {'class': 'form-input'},
})
export class InputComponent extends InputAbstract implements AfterViewInit {
  @Input() addPlaceholder = true;
  @Input() addClass = '';
  @Input() autofocus = false;
  @ViewChild('input') input: ElementRef;

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => this.input.nativeElement.focus(), 1);
    }
  }
}
