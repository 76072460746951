import { Component, Input } from '@angular/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-label-page-break',
  templateUrl: './label-page-break.component.html',
  styleUrls: ['./label-page-break.component.scss'],
  host: {'class': 'form-input'},
})
export class LabelPageBreakComponent extends InputAbstract {
  @Input() page: number;
  @Input() fieldTypeSymbol: string;

  protected setVisibility(): void {
    this.isVisible = true;
  }
}
