import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalConfig, ModalControllerInterface, ModalSizeConfig } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class ModalController implements ModalControllerInterface {
  private defaultWidth = '1024px';

  constructor(public dialog: MatDialog) {}

  async create(config: ModalConfig) {
    return {
      present: async (): Promise<any> => {
        const classes = this.findSizeClass(config.sizeConfig);
        const matDialogConfig: MatDialogConfig = {
          data: config,
          panelClass: classes,
          width:
            config.sizeConfig !== undefined &&
            config.sizeConfig.sizeOnDesktop !== undefined
              ? this.getWidth(config.sizeConfig)
              : this.defaultWidth,
          disableClose: config.backdropDismiss === false
        };

        const dialogRef = this.dialog.open(config.component, matDialogConfig);

        if (config.componentProps) {
          const props = Object.keys(config.componentProps);

          props.forEach(
            value =>
              (dialogRef.componentInstance[value] =
                config.componentProps[value])
          );
        }

        return dialogRef;
      }
    };
  }

  changeModalSizeConfig(
    sizeConfig: ModalSizeConfig,
    dialogRef: MatDialogRef<any>
  ) {
    dialogRef.removePanelClass([
      'very-small',
      'small',
      'normal',
      'large',
      'very-large',
      'full-on-mobile'
    ]);
    dialogRef.addPanelClass(this.findSizeClass(sizeConfig));
  }

  private findSizeClass(config: ModalSizeConfig) {
    const arrayOfClass = ['modal'];

    if (
      config === undefined ||
      config.fullOnMobile === undefined ||
      config.fullOnMobile
    ) {
      arrayOfClass.push('full-on-mobile');
    }

    if (config !== undefined && config.sizeOnDesktop !== undefined) {
      switch (config.sizeOnDesktop) {
        case 'verySmall':
          arrayOfClass.push('very-small');
          break;
        case 'small':
          arrayOfClass.push('small');
          break;
        case 'normal':
          arrayOfClass.push('normal');
          break;
        case 'large':
          arrayOfClass.push('large');
          break;
        case 'veryLarge':
          arrayOfClass.push('very-large');
          break;
      }
    } else {
      arrayOfClass.push('normal');
    }

    return arrayOfClass;
  }

  private getWidth(config: ModalSizeConfig) {
    switch (config.sizeOnDesktop) {
      case 'verySmall':
        return '480px';
      case 'small':
        return '640px';
      case 'normal':
        return '1024px';
      case 'large':
        return '1440px';
      case 'veryLarge':
        return '95vw';
    }
  }
}
