import { Injectable } from '@angular/core';
import { Chat } from '../message/chat';
import { BehaviorSubject, Subject } from 'rxjs';
import { ChatMessages, Chats, SendNewChatMessage } from '../message/message';
import { skip } from 'rxjs/operators';
import { EnvService, UserStorageService } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class ChatStorageService {
  chats = new Map<string, Chat>();

  chatsChange: BehaviorSubject<void> = new BehaviorSubject(null);

  newMessageBus: Subject<SendNewChatMessage> = new Subject<SendNewChatMessage>();

  messagesLoadedBus: Subject<string> = new Subject<string>();

  currentChatId = null;

  constructor(
    private userStorageService: UserStorageService,
    private envService: EnvService
  ) {}

  readMessage(message: SendNewChatMessage) {
    const chatInfo = this.getChat(message.chatId).chatInfo;
    if (chatInfo) {
      message.isRead = true;
      chatInfo.newMessagesCount--;
    }
  }

  getChat(chatId): Chat {
    if (!this.chats.has(chatId)) {
      this.chats.set(chatId, {
        messages: new Map(),
        currentLoadedPage: 0
      });
    }
    return this.chats.get(chatId);
  }

  getChatMessages(chatId: string): Map<string, SendNewChatMessage> {
    return this.getChat(chatId).messages;
  }

  restart() {
    this.chats = new Map();
  }

  addNewMessage(message: SendNewChatMessage): boolean {
    const chat = this.getChat(message.chatId);
    if (chat.chatInfo && !this.getChatMessages(message.chatId).has(message.id)) {
      chat.chatInfo.count++;
      if (message.authorId != this.userStorageService.user.id) {
        chat.chatInfo.newMessagesCount++;
      }

      if (chat.currentLoadedPage > 0) {
        message.date = new Date(message.date);
        this.getChatMessages(message.chatId).set(message.id, message);
      }

      if (message.authorAvatar) {
        message.authorAvatar = this.envService.getBaseUrl(message.authorAvatar);
      }

      this.newMessageBus.next(message);

      this.chatsChange.next();

      return true;
    } else {
      this.tryShowNotificationAfterLoad(message);
    }
    return false;
  }

  setChats(data: Chats) {
    data.chats.forEach(value => {
      const localStoredChat = this.chats.get(value.id);
      if (localStoredChat === undefined) {
        this.chats.set(value.id, {
          messages: new Map(),
          chatInfo: value,
          currentLoadedPage: null
        });
      } else {
        localStoredChat.chatInfo = value;
      }
    });

    this.chatsChange.next();
  }

  addMessagesToChat(data: ChatMessages) {
    const map = this.getChatMessages(data.chatId);
    data.chatMessages.forEach(message => {
      message.date = new Date(message.date);
      message.authorAvatar = message.authorAvatar
        ? this.envService.getBaseUrl('/' + message.authorAvatar)
        : null;
      map.set(message.id, message);
    });
    this.messagesLoadedBus.next(data.chatId);
  }

  private tryShowNotificationAfterLoad(message: SendNewChatMessage) {
    const sub = this.chatsChange.pipe(skip(1)).subscribe(() => sub.unsubscribe());
  }
}
