<div *ngIf="isVisible">
  <div
    [ngClass]="{
      'ng-invalid': formSchema.formControl.invalid,
      'ng-touched': formSchema.formControl.touched,
      'ng-dirty': formSchema.formControl.dirty,
      'ng-valid': formSchema.formControl.valid
    }"
  >
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <star-rating-control
      [disabled]="formSchema.disabled || false"
      starType="icon"
      size="large"
      staticColor="ok"
      [formControl]="$any(formSchema.formControl)"
    ></star-rating-control>
  </div>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</div>
