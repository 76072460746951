import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { EnvService, HttpService } from '@frontend/core';
import { InputAbstract } from '../../../interface/input-abstract';

@Component({
  selector: 'app-input-dynamic-choice',
  templateUrl: './input-dynamic-choice.component.html',
  host: {'class': 'form-input'},
})
export class InputDynamicChoiceComponent extends InputAbstract
  implements OnInit {
  loading = false;
  input$ = new Subject<string>();

  data$: Observable<any>;
  public items: any[];

  constructor(
    private http: HttpService,
    private envService: EnvService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.formSchema.parentEventEmitter$) {
      this.startListeningToParent();
    } else {
      this.startListening();
    }
  }

  startListeningToParent(): void {
    this.items = this.formSchema.choices;

    this.data$ = this.formSchema.parentEventEmitter$.pipe(
      debounceTime(50),
      tap(() => {
        this.loading = true;
        this.formSchema.formControl.setValue(null);
      }),
      switchMap(parentId => {
        if (parentId != null) {
          return this.load('when', parentId);
        }

        this.loading = false;

        return of([]);
      })
    );

    this.data$.subscribe(choices => {
      this.items = choices;
      this.formSchema.items$.next(choices);
    });
  }

  startListening(): void {
    this.data$ = this.input$.pipe(
      debounceTime(50),
      distinctUntilChanged(),
      tap(() => this.loading = true),
      switchMap(queryString => this.load('q', queryString))
    );

    this.data$.subscribe(choices => {
      this.items = choices;
      this.formSchema.formControl.setValue(null);
    });
  }

  private load(queryType: string, term: string): Observable<any> {
    return this.http
      .get(
        this.envService.getApiUrl(`${this.formSchema.options.dataUrl}`),
        {params: new HttpParams().set(queryType, term)}
      )
      .pipe(
        catchError(() => of([])), // empty list on error
        tap(() => (this.loading = false))
      );
  }
}
