import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { AlertController, ModalController } from '@frontend/core';
import { TranslateService } from '@ngx-translate/core';
import { ScoreService } from './score.service';
import { BehaviorSubject, Subject } from "rxjs";
import { FormSchema } from "../interface/form-schema";
import { WebsiteFormType } from "../enum/website-form-type";
import { SuccessRegistrationHandlerService } from "@frontend/core";
import { Router } from "@angular/router";
import { FormSubmissionResponseData } from "../interface/form-submission-response-data";

interface Error {
  status: number,
  error: {
    name: string,
    message: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class FormFillService {
  websiteFormType: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  passwordChange: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private scoreService: ScoreService,
    private alertController: AlertController,
    private translate: TranslateService,
    private modalController: ModalController,
    private successRegistrationHandlerService: SuccessRegistrationHandlerService,
    private router: Router,
  ) {}

  async displayConfirm(): Promise<boolean> {
    return new Promise(async resolve => {
      const alert = await this.alertController.create({
        header: this.translate.instant('messages.alert'),
        message: this.translate.instant('messages.timePollConfirmExit'),
        buttons: [{
          text: this.translate.instant('messages.cancel'),
          handler: () => resolve(false)
        }, {
          text: this.translate.instant('messages.ok'),
          handler: () => resolve(true)
        }]
      });

      await alert.present();
    });
  }

  async displayNotActive(message: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant('messages.alert'),
      message: this.translate.instant(message) ?? '',
      buttons: [{
        text: this.translate.instant('messages.ok'),
        handler: () => console.log('confirmed')
      }]
    });

    await alert.present();
  }

  async displayResult(
    data: FormSubmissionResponseData,
    buttonClickHandler: () => void,
    responseMessage = null,
    responseHeader = null,
    failureMessage = null,
    failureHeader = null
  ) {
    let message: string;
    let header: string;

    if (data.maxPoint > 0) {

      const passed = this.scoreService.isPassed(data.points, data.maxPoint, data.passLimit);
      header  = (passed ? responseHeader : failureHeader) ?? this.translate.instant('messages.testResult');
      message = (passed ? responseMessage : failureMessage) ?? this.translate.instant('messages.questionnaireSuccessFill');

      message = this.scoreService.generateScoreView(
        data.points,
        data.maxPoint,
        data.passLimit,
        message
      );
    } else {
      message = responseMessage
        ? responseMessage
        : this.translate.instant(data.message ?? 'messages.questionnaireSuccessFill');
      header = responseHeader ?? this.translate.instant('messages.questionnaire');
    }

    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [{
        text: this.translate.instant('messages.ok'),
        handler: buttonClickHandler
      }]
    });

    await alert.present();
  }

  stringFormType(type: number) {
    switch (type) {
      case 0:
        return 'standard';
      case 1:
        return 'registration';
      case 2:
        return 'contact';
      case 3:
        return 'login';
    }
    return 'standard';
  }

  async success(
    data: FormSubmissionResponseData,
    formSchema: FormSchema,
    formType: number,
    successMessage: string
  ): Promise<void> {
    if (data.paymentUrl) {
      this.document.location.href = data.paymentUrl;

      return;
    } else if (formSchema.redirectLink) {
      if (data.forcedMessageDisplay) {
        await this.displayResult(<any>data, () => {});
      }
      await this.router.navigate([`${this.translate.currentLang}/${formSchema.redirectLink}`]);

      return
    }

    if (formType === WebsiteFormType.registration && !data.forcedMessageDisplay) {
      await this.successRegistrationHandlerService.handle(successMessage);
    } else {
      await this.displayResult(<any>data, () => {});
    }
  }

  async errorHandler(error: Error, modalComponent: any, formSchema: FormSchema) {
    switch (error.status) {
      case HttpStatusCode.Conflict:
        if (error?.error?.name === 'AccountExistsException') {
          const modal = await this.modalController.create({
            component: modalComponent,
            componentProps: {
              input: formSchema,
              message: error.error.message
            },
            backdropDismiss: false
          });
          await modal.present();
        }
        break;
      default:
        console.error(error);
        break;
    }
  }
}
