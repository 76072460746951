<div *ngIf="isVisible">
  <mat-form-field>
    <app-custom-label [formSchema]="formSchema" [globalForm]="globalForm"></app-custom-label>

    <mat-label *ngIf="!isFromEntity">{{ formSchema.label }}</mat-label>

    <input
      matInput
      placeholder="{{ formSchema.label }}"
      (focus)="focusEvent.emit($event)"
      [type]="typePassword ? 'password' : 'text'"
      [formControl]="$any(formSchema.formControl)"
      [readonly]="formSchema.attr?.readonly ?? false"
    >
    <button
      mat-icon-button
      matSuffix
      type="button"
      (click)="typePassword = !typePassword"
    >
      <mat-icon>{{ typePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </mat-form-field>

  <app-error-messages
    [control]="formSchema.formControl"
    [errorMessage]="formSchema.attr?.errorMessage ?? null"
  ></app-error-messages>
</div>
