import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PostcodeValidator {
  static validator(): ValidatorFn {
    return (control: AbstractControl) => {
      const postcode = control.value;
      if (postcode === null) {
        return;
      }

      const pattern = new RegExp('^[0-9]{2}-[0-9]{3}$');

      if (!pattern.test(postcode)) {
        return {invalidPostcode: true};
      }
    };
  }
}
